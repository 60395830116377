import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import ReactLoading from 'react-loading';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
} from '@material-ui/core/';

import Cart from '~/components/CartPlan';
import Header from '~/components/Header';
import OrangeBanner from '~/components/OrangeBanner';
import BackButton from '~/components/BackButton';
import OrangeInput from '~/components/OrangeInput';

import api from '~/services/api';
import history from '~/services/history';
import crediccards from '~/assets/Cards.png';

import { cleanCart, updateDiscount } from '~/store/modules/cart/actions';

import estados from './estados/estados';
import * as S from './styles';

const PaymentPlan = () => {
  const cart = useSelector((state) => state.cart);
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showDialogCupom, setShowDialogCupom] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCupom, setLoadingCupom] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [msgDialogCupom, setMsgDialogCupom] = useState('');
  const [titleDialogCupom, setTitleDialogCupom] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [salesMan, setSalesMan] = useState('');
  const [couponExist, setCouponExist] = useState(false);

  // DADOS DA EMPRESA
  const [companyName, setCompanyName] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [cnpj, setCnpj] = useState('');

  // DADOS DO ESTABELECIMENTO
  const [nameValet, setNameValet] = useState('');
  const [address, setAddress] = useState('');
  const [cep, setCep] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  // DADOS DO GERENTE
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');

  // DADOS DE PAGAMENTO
  const [cardNumber, setCardNumber] = useState('');
  const [validDate, setValidDate] = useState('');
  const [cardName, setCardName] = useState('');
  const [cvc, setCvc] = useState('');

  const dispatch = useDispatch();

  let disabled = true;
  let disabledCupom = true;

  if (
    companyName !== '' &&
    razaoSocial !== '' &&
    cnpj !== '' &&
    nameValet !== '' &&
    address !== '' &&
    cep !== '' &&
    city !== '' &&
    name !== '' &&
    phone !== '' &&
    cpf !== '' &&
    email !== '' &&
    acceptTerms
  ) {
    disabled = false;
  }

  if (coupon !== '' && coupon.length > 5) {
    disabledCupom = false;
  }

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  async function handleSubmitCupom() {
    setLoadingCupom(true);

    try {
      const response = await api.post('/validateCoupon', {
        coupon,
      });

      setLoadingCupom(false);
      dispatch(updateDiscount(response.data.percentage, response.data.value));
      setShowDialogCupom(true);
      setTitleDialogCupom('Oba! Cupom ativo com sucesso!');
      setMsgDialogCupom(
        'Olha seu carrinho e veja quanto R$ está economizando!'
      );
      setCouponExist(true);
    } catch (err) {
      setLoadingCupom(false);
      setShowDialogCupom(true);
      setTitleDialogCupom('Ops! Cupom inválido');
      setMsgDialogCupom('Veja novamente o cupom inserido e tente novamente!');
    }
  }

  async function handleSubmit() {
    setLoading(true);
    setShowError(false);
    setMsgError(null);
    try {
      const response = await api.post('/payment', {
        // DADOS DA EMPRESA
        companyName,
        razaoSocial,
        cnpj,

        // DADOS DO ESTABELECIMENTO
        nameValet,
        address,
        cep,
        state,
        city,

        // DADOS DO GERENTE
        name,
        phone,
        cpf,
        email,

        // DADOS DE PAGAMENTO
        cardNumber,
        validDate,
        cardName,
        cvc,

        // OPÇÔES DE PLANO
        item1: cart.step1,
        item2: cart.step2,
        item3: cart.step3,

        // SERVIÇOS ADICIONAIS
        adicionais: cart.step4,

        // QTD ESTACIONAMENTOS
        qtdEstacionamentos: cart.totalValets,

        // VALOR DA COMPRAS
        amountPaid: Number(cart.totalCartValue),

        // VENDEDOR
        vendedor: salesMan,

        // CUPOM UTILIZADO NA COMPRA
        coupon,
      });

      if (response.data.message === 'success') {
        dispatch(cleanCart());
        history.push('/PagamentoEfetuado');
      }
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      setMsgError(err.response.data.error);
      setShowError(true);
    }
  }

  const inputProps = {
    color: '#ff4e00',
    border: 2,
    width: 400,
  };

  return (
    <>
      {/* header menu */}
      <Header
        type="forYourCompany"
        active="forYourCompany"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />

      {isSmallScreen ? (
        <>
          <S.DivHirePlanMobile>
            <h1>
              Para contratar um plano acesse esse link em um notebook ou
              Computador! <br />
              <br />
            </h1>
            <br />
            <br />
          </S.DivHirePlanMobile>
        </>
      ) : (
        <>
          {/* orange title start */}
          <OrangeBanner title="FINALIZAÇÃO E PAGAMENTO" />
          {/* orange title end */}

          {/* form subscribe and payment start */}
          <S.DivPayment>
            <S.DivGroupInfos>
              <BackButton link="/ContrateUmPlano" />

              <S.HeaderPaymentData>
                Preencha com seus dados:
              </S.HeaderPaymentData>
              <S.CompanyData>Dados da empresa</S.CompanyData>

              <S.LabelInputs>Nome fantasia da sua empresa</S.LabelInputs>
              <OrangeInput
                inputProps={inputProps}
                required
                fullWidth={true}
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
                className="outlined"
                placeholder="Valeti"
                variant="outlined"
              />

              <S.LabelInputs>Razão social</S.LabelInputs>
              <OrangeInput
                required
                value={razaoSocial}
                fullWidth={true}
                onChange={(event) => setRazaoSocial(event.target.value)}
                className="outlined"
                placeholder="Valeti App Sistemas S.A"
                variant="outlined"
              />

              <S.LabelInputs>CNPJ</S.LabelInputs>
              <InputMask
                mask="99.999.999/9999-99"
                onChange={(event) => setCnpj(event.target.value)}
                value={cnpj}
                name="cnpj"
                required
              >
                {() => (
                  <OrangeInput
                    required
                    fullWidth={true}
                    variant="outlined"
                    placeholder="00.000.000/0000-00"
                    name="cnpj"
                    type="text"
                  />
                )}
              </InputMask>
            </S.DivGroupInfos>

            <S.DivGroupInfos>
              <S.CompanyData>
                Dados do seu estacionamento ou Valet
              </S.CompanyData>

              <S.LabelInputs>Nome do estacionamento ou valet</S.LabelInputs>
              <OrangeInput
                required
                value={nameValet}
                onChange={(event) => setNameValet(event.target.value)}
                className="outlined"
                placeholder="Valeti App Sistemas S.A"
                variant="outlined"
                fullWidth={true}
              />

              <S.LabelInputs>Endereço completo</S.LabelInputs>
              <OrangeInput
                required
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                className="outlined"
                placeholder="Rua dos Valets, 999, Bairro da Valeti"
                variant="outlined"
                fullWidth={true}
              />

              <S.LabelInputs>CEP</S.LabelInputs>
              <InputMask
                mask="99999-999"
                maskChar=""
                onChange={(event) => setCep(event.target.value)}
                value={cep}
                name="cep"
                required
              >
                {() => (
                  <OrangeInput
                    required
                    variant="outlined"
                    placeholder="00000-000"
                    name="cep"
                    type="text"
                    fullWidth={true}
                  />
                )}
              </InputMask>

              <S.LabelInputs>Selecione o Estado</S.LabelInputs>
              <FormControl variant="outlined" className="stateselectPayment">
                <Select
                  className="stateselectPayment"
                  native
                  value={state}
                  onChange={(event) => setState(event.target.value)}
                  label="Selecione a quantidade de pontos"
                >
                  {estados.map((option) => (
                    <option key={option.value} value={option.value}>
                      {' '}
                      {option.label}{' '}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <S.LabelInputs>Cidade</S.LabelInputs>
              <OrangeInput
                required
                value={city}
                onChange={(event) => setCity(event.target.value)}
                placeholder="Ex: São Paulo"
                className="outlined"
                variant="outlined"
                fullWidth={true}
              />
              <br />
            </S.DivGroupInfos>

            <S.DivGroupInfos>
              <S.CompanyData>
                Informações do usuário (Administrador do Estabelecimento)
              </S.CompanyData>
              <h1>Por favor, preencha todos os dados</h1>

              <S.LabelInputs>Nome completo</S.LabelInputs>
              <OrangeInput
                value={name}
                onChange={(event) => setName(event.target.value)}
                className="outlined"
                placeholder="João Silva"
                variant="outlined"
                fullWidth={true}
              />

              <S.LabelInputs>CPF</S.LabelInputs>
              <InputMask
                mask="999.999.999-99"
                maskChar=""
                onChange={(event) => setCpf(event.target.value)}
                value={cpf}
                name="cpf"
                required
              >
                {() => (
                  <OrangeInput
                    required
                    variant="outlined"
                    placeholder="000.000.000-00"
                    name="cpf"
                    type="text"
                    fullWidth={true}
                  />
                )}
              </InputMask>

              <S.LabelInputs>E-mail</S.LabelInputs>
              <OrangeInput
                required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="seuemail@dominio.com"
                className="stateselectPayment"
                variant="outlined"
                fullWidth={true}
              />

              <S.LabelInputs>Celular</S.LabelInputs>
              <InputMask
                mask="(99) 9 9999-9999"
                maskChar=""
                onChange={(event) => setPhone(event.target.value)}
                value={phone}
                name="phone"
                required
              >
                {() => (
                  <OrangeInput
                    required
                    variant="outlined"
                    placeholder="(99) 9 9999-9999"
                    name="phone"
                    type="text"
                  />
                )}
              </InputMask>
            </S.DivGroupInfos>

            <S.DivGroupInfos>
              <S.CompanyData>
                Dados para pagamento (Cartão de crédito)
              </S.CompanyData>

              <S.LabelInputs>Número do Cartão</S.LabelInputs>
              <InputMask
                mask="9999 9999 9999 9999"
                maskChar=""
                onChange={(event) => setCardNumber(event.target.value)}
                value={cardNumber}
                name="cardNumber"
                required
              >
                {() => (
                  <OrangeInput
                    required
                    variant="outlined"
                    placeholder="4444 5555 6666 7777"
                    name="cardNumber"
                    type="text"
                    className="fieldcardNumber"
                    fullWidth={true}
                  />
                )}
              </InputMask>

              <S.LabelInputs>Nome Impresso no Cartão</S.LabelInputs>
              <OrangeInput
                value={cardName}
                onChange={(event) => setCardName(event.target.value)}
                className="namecreditcardpaymento"
                placeholder="Valeti App Sistemas S.A"
                variant="outlined"
                fullWidth={true}
              />

              <S.LabelInputs>Validade</S.LabelInputs>
              <InputMask
                mask="99/9999"
                maskChar=""
                onChange={(event) => setValidDate(event.target.value)}
                value={validDate}
                name="validDate"
                required
              >
                {() => (
                  <OrangeInput
                    required
                    variant="outlined"
                    placeholder="04/2025"
                    name="validDate"
                    type="text"
                    className="validcardnumberPayment"
                  />
                )}
              </InputMask>

              <S.LabelInputs>CVV</S.LabelInputs>
              <InputMask
                mask="999"
                maskChar=""
                onChange={(event) => setCvc(event.target.value)}
                value={cvc}
                name="cvc"
                required
              >
                {() => (
                  <OrangeInput
                    required
                    variant="outlined"
                    placeholder="000"
                    name="cvc"
                    type="text"
                    className="cvcsizecard"
                  />
                )}
              </InputMask>
              <br />
              <br />
              <img src={crediccards} alt="cartoes" />
            </S.DivGroupInfos>

            <S.DivGroupInfos>
              <S.CompanyData>Informações Adicionais</S.CompanyData>

              <S.LabelInputs>Cupom de Desconto</S.LabelInputs>
              <S.InputCupom
                value={coupon}
                onChange={(event) =>
                  setCoupon(event.target.value.toUpperCase())
                }
                placeholder="Insira seu cupom"
              />
              <br />
              {loadingCupom ? (
                <ReactLoading
                  type="spin"
                  color="#FF4D00"
                  height={40}
                  width={40}
                />
              ) : (
                <>
                  {!couponExist && (
                    <S.ButtonCupom
                      onClick={handleSubmitCupom}
                      disabled={disabledCupom}
                    >
                      Validar Cupom
                    </S.ButtonCupom>
                  )}
                </>
              )}

              <S.LabelInputs>Representante</S.LabelInputs>
              <S.InputCupom
                value={salesMan}
                onChange={(event) => setSalesMan(event.target.value)}
                placeholder="Digite o nome do vendedor!"
              />
            </S.DivGroupInfos>

            {loading ? (
              <S.ErrorDiv>
                <ReactLoading
                  type="spin"
                  color="#FF4D00"
                  height={40}
                  width={40}
                />
              </S.ErrorDiv>
            ) : (
              <>
                <S.CheckboxDiv>
                  <input
                    type="checkbox"
                    defaultChecked={acceptTerms}
                    onClick={() => setAcceptTerms(!acceptTerms)}
                  />
                  <p>Aceito os termos e contrato Valeti</p>

                  <S.ButtonBuy onClick={handleSubmit} disabled={disabled}>
                    COMPRAR
                  </S.ButtonBuy>
                </S.CheckboxDiv>
              </>
            )}
          </S.DivPayment>
          {/* form subscribe and payment end */}

          <Dialog
            open={showError}
            onClose={() => setShowError(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Erro ao realizar compra!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {msgError}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowError(false)}
                color="primary"
                autoFocus
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          {/* show dialog cupom info */}
          <Dialog
            open={showDialogCupom}
            onClose={() => setShowDialogCupom(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {titleDialogCupom}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {msgDialogCupom}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowDialogCupom(false)}
                color="primary"
                autoFocus
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          {/* cart start */}
          <Cart page={2} />
          {/* cart end */}
        </>
      )}
    </>
  );
};

export default PaymentPlan;
