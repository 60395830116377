import 'antd/dist/antd.css';
import React from 'react';
import { Carousel } from 'antd';
import ImagemDois from '~/assets/imagemdois.png';
import ImagemOne from '~/assets/imagemum.png';
import { Image } from './styles';

const Carrossel = () => (
  <>
    <Carousel className="carrosselPosition" autoplay>
      <>
        <Image src={ImagemOne} alt="Carrossel Para Sua Empresa 1" />
      </>
      <>
        <Image src={ImagemDois} alt="Carrossel Para Sua Empresa 1" />
      </>
    </Carousel>
  </>
);

export default Carrossel;
