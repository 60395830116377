export function addReservation(infos) {
  return {
    type: '@reservation/ADD_RESERVATION',
    infos,
  };
}

export function addValetToReservation(valet) {
  return {
    type: '@reservation/ADD_VALET_TO_RESERVATION',
    valet,
  };
}
