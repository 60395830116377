import React, { useState, useEffect } from 'react';

import Header from '~/components/Header';
import CarrosselDesktop from '~/components/Carrossel/Carrossel';
import CarrosselMobile from '~/components/Carrossel/CarrosselMobile';
import CarrosselForFun from '~/components/Carrossel/CarrosselForFun';
import InputAndButtonToReservar from '~/components/InputSearchAddressToReservar';
import InputSearch from '~/components/InputSearch';
import ParkNearby from '~/components/ParkNearby';
import KnowOrangeApp from '~/components/KnowOrangeApp';
import BeaPartner from '~/components/BeaPartner';
import Footer from '~/components/Footer';

import DownloadApple from '~/assets/Apple.png';
import DownloadAndroid from '~/assets/Android.png';

import * as S from './styles';

const ForYou = () => {
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  return (
    <>
      {/* header menu */}
      <Header
        type="forYou"
        active="forYou"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />

      {isSmallScreen ? (
        <>
          {/* carrossel start */}
          <CarrosselMobile />
          <S.TitleCarrosselMobile>
            Reserve sua vaga agora, baixando nosso App!
          </S.TitleCarrosselMobile>
          {platform === 'iPhone' && (
            <a
              href="https://apps.apple.com/br/app/valeti/id1069081218"
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.DownloadImage src={DownloadApple} alt="iOS" />
            </a>
          )}
          {platform === 'Android' && (
            <a
              href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.DownloadImage src={DownloadAndroid} alt="Android" />
            </a>
          )}
          {/* carrossel end */}

          {/* for fun start */}
          <S.DivForFun>
            <div>
              <S.HeaderForFun> PARA SE DIVERTIR </S.HeaderForFun>
              <S.SubHeaderForFun>
                Recomendações de vagas em eventos, benefícios, descontos e
                promoções.
              </S.SubHeaderForFun>
              <br />
              <S.OtherSubHeaderForfun>
                CONFIRA E GARANTA SUA VAGA
              </S.OtherSubHeaderForfun>
            </div>
          </S.DivForFun>
          <CarrosselForFun isSmallScreen={isSmallScreen} />
          {/* for fun end */}

          {/* park nearby start */}
          <ParkNearby isSmallScreen={isSmallScreen} />
          {/* park nearby end */}

          {/* know app valeti start */}
          <KnowOrangeApp isSmallScreen={isSmallScreen} />
          {/* know app valeti end */}

          {/* be a partner start */}
          <BeaPartner />
          {/* be a partner end */}

          {/* footer start */}
          <Footer width={700} />
          {/* footer end */}
        </>
      ) : (
        <>
          {/* carrossel start */}
          <CarrosselDesktop />
          <S.DivCarrosselPhrases>
            <S.TitleCarrossel>Reserve sua vaga agora!</S.TitleCarrossel>
            <S.SubtitleCarrossel>
              Mais de 800 estacionamentos e valets
            </S.SubtitleCarrossel>
            <InputAndButtonToReservar />
          </S.DivCarrosselPhrases>
          {/* carrossel end */}

          {/* for fun start */}
          <S.DivForFun>
            <div>
              <S.HeaderForFun> PARA SE DIVERTIR </S.HeaderForFun>
              <S.SubHeaderForFun>
                Recomendações de vagas em eventos, benefícios, descontos e
                promoções.
              </S.SubHeaderForFun>
              <br />
              <S.OtherSubHeaderForfun>
                CONFIRA E GARANTA SUA VAGA
              </S.OtherSubHeaderForfun>
            </div>
          </S.DivForFun>
          <CarrosselForFun isSmallScreen={isSmallScreen} />
          {/* for fun end */}

          {/* address search start */}
          <S.DivAddressSearch>
            <S.HeaderAddressSearch>
              {' '}
              PESQUISE POR ENDEREÇO:{' '}
            </S.HeaderAddressSearch>
          </S.DivAddressSearch>
          <InputSearch />
          {/* address search end */}

          {/* park nearby start */}
          <ParkNearby isSmallScreen={isSmallScreen} />
          {/* park nearby end */}

          {/* know app valeti start */}
          <KnowOrangeApp isSmallScreen={isSmallScreen} />
          {/* know app valeti end */}

          {/* be a partner start */}
          <BeaPartner />
          {/* be a partner end */}

          {/* footer start */}
          <Footer isSmallScreen={isSmallScreen} />
          {/* footer end */}
        </>
      )}
    </>
  );
};

export default ForYou;
