import produce from 'immer';

const INITIAL_STATE = {
  step1: {
    id: null,
    description: '',
    price: 0,
  },
  step2: {
    id: null,
    description: '',
    price: 0,
  },
  step3: {
    id: null,
    description: '',
    percentage: 0,
  },
  step4: {
    id: [],
    price: 0,
  },
  coupon: {
    value: 0,
    percentage: 0,
  },
  totalValets: 1,
  discountPercentage: 0,
  discountValue: 0,
  totalCartValue: 0,
  totalDiscountValue: 0,
};

export default function cart(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@cart/ADD_SUCCESS':
      return produce(state, (draft) => {
        const { id, step, price, percentage, description } = action.product;

        switch (step) {
          case 1:
            draft.step1.id = id;
            draft.step1.description = description;
            draft.step1.price = Number(price);
            break;
          case 2:
            draft.step2.id = id;
            draft.step2.description = description;
            draft.step2.price = Number(price);
            break;
          case 3:
            draft.step3.id = id;
            draft.step3.description = description;
            draft.step3.percentage = Number(percentage);
            break;
          case 4:
            draft.step4.id.push(id);
            draft.step4.price += price;
            break;
          default:
            break;
        }

        const value =
          draft.step1.price +
          draft.step2.price * draft.totalValets +
          draft.step4.price;

        let totalValue = 0;
        let totalDiscount = 0;

        if (draft.step3.percentage > 0) {
          const discount = (value * draft.step3.percentage) / 100;
          totalValue = Number(value) - Number(discount);
        } else {
          totalValue = Number(value);
        }

        if (draft.coupon.value > 0) {
          totalDiscount = draft.coupon.value;
          totalValue = Number(totalValue) - Number(totalDiscount);
        }

        if (draft.coupon.percentage > 0) {
          const discount = (totalValue * draft.coupon.percentage) / 100;
          totalDiscount = discount;
          totalValue = Number(totalValue) - Number(totalDiscount);
        }

        draft.totalCartValue = totalValue;
        draft.totalDiscountValue = totalDiscount;
      });

    case '@cart/QTD_SUCCESS':
      return produce(state, (draft) => {
        draft.totalValets = action.product;

        const value =
          draft.step1.price +
          draft.step2.price * draft.totalValets +
          draft.step4.price;

        let totalValue = 0;
        let totalDiscount = 0;

        if (draft.step3.percentage > 0) {
          const discount = (value * draft.step3.percentage) / 100;
          totalValue = Number(value) - Number(discount);
        } else {
          totalValue = Number(value);
        }

        if (draft.coupon.value > 0) {
          totalDiscount = draft.coupon.value;
          totalValue = Number(totalValue) - Number(totalDiscount);
        }

        if (draft.coupon.percentage > 0) {
          const discount = (totalValue * draft.coupon.percentage) / 100;
          totalDiscount = discount;
          totalValue = Number(totalValue) - Number(totalDiscount);
        }

        draft.totalCartValue = totalValue;
        draft.totalDiscountValue = totalDiscount;
      });
    case '@cart/REMOVE_ADITIONAL_SUCCESS':
      return produce(state, (draft) => {
        const { id, price } = action.product;

        const filterStep4 = draft.step4.id.indexOf(id);

        if (filterStep4 !== -1) {
          draft.step4.id.splice(filterStep4, 1);
          draft.step4.price -= price;
        }

        const value =
          draft.step1.price +
          draft.step2.price * draft.totalValets +
          draft.step4.price;

        let totalValue = 0;
        let totalDiscount = 0;

        if (draft.step3.percentage > 0) {
          const discount = (value * draft.step3.percentage) / 100;
          totalValue = Number(value) - Number(discount);
        } else {
          totalValue = Number(value);
        }

        if (draft.coupon.value > 0) {
          totalDiscount = draft.coupon.value;
          totalValue = Number(totalValue) - Number(totalDiscount);
        }

        if (draft.coupon.percentage > 0) {
          const discount = (totalValue * draft.coupon.percentage) / 100;
          totalDiscount = discount;
          totalValue = Number(totalValue) - Number(totalDiscount);
        }

        if (totalValue < 0) {
          totalValue = 0;
        }
        draft.totalCartValue = totalValue;
        draft.totalDiscountValue = totalDiscount;
      });
    case '@cart/CLEAN_CART':
      return produce(state, (draft) => {
        draft.cart = [
          {
            step1: {
              id: null,
              description: '',
              price: 0,
            },
            step2: {
              id: null,
              description: '',
              price: 0,
            },
            step3: {
              id: null,
              description: '',
              percentage: 0,
            },
            step4: {
              id: [],
              price: 0,
            },
            totalValets: 1,
            discountPercentage: 0,
            discountValue: 0,
            totalCartValue: 0,
            totalDiscountValue: 0,
          },
        ];
      });
    case '@cart/UPDATE_DISCOUNT':
      return produce(state, (draft) => {
        draft.coupon.percentage = action.percentage;
        draft.coupon.value = action.value;

        const value =
          draft.step1.price +
          draft.step2.price * draft.totalValets +
          draft.step4.price;

        let totalValue = 0;
        let totalDiscount = 0;

        if (draft.step3.percentage > 0) {
          const discount = (value * draft.step3.percentage) / 100;
          totalValue = Number(value) - Number(discount);
        } else {
          totalValue = Number(value);
        }

        if (draft.coupon.value > 0) {
          totalDiscount = draft.coupon.value;
          totalValue = Number(totalValue) - Number(totalDiscount);
        }

        if (draft.coupon.percentage > 0) {
          const discount = (totalValue * draft.coupon.percentage) / 100;
          totalDiscount = discount;
          totalValue = Number(totalValue) - Number(totalDiscount);
        }

        if (totalValue < 0) {
          totalValue = 0;
        }
        draft.totalCartValue = totalValue;
        draft.totalDiscountValue = totalDiscount;
      });
    default:
      return state;
  }
}
