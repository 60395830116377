import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import ReactLoading from 'react-loading';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from '@material-ui/core';

import {
  addToCartRequest,
  qtdRequest,
  removeAditionalInCartRequest,
} from '~/store/modules/cart/actions';
import DialogDetails from '~/components/DialogKnowMore';
import api from '~/services/api';
import history from '~/services/history';

import {
  DivPlansToHire,
  TitleStep,
  TextCheckbox,
  TextOne,
  TextTwo,
  TextThree,
  TextFour,
  TextFive,
  TextSix,
  TextDescription,
  TextInsideCard,
  SubtextInsideCard,
  TextInsideService,
} from './styles';
import './styles.css';

export default function PlansToHire() {
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [expanded1, setExpanded1] = useState(true);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [optionsStep1, setOptionsStep1] = useState([]);
  const [optionsStep2, setOptionsStep2] = useState([]);
  const [optionsStep3, setOptionsStep3] = useState([]);
  const [optionsStep4, setOptionsStep4] = useState([]);
  const [valueStep1, setValueStep1] = useState(null);
  const [valueStep2, setValueStep2] = useState(null);
  const [valueStep3, setValueStep3] = useState(null);
  const [checked6, setChecked6] = useState(false);
  const [checked7, setChecked7] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [business, setBusiness] = useState('');
  const [qtdValet, setqtdValet] = useState(null);
  const [qtd, setQtd] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadOptions() {
      setLoading(true);
      const response = await api.get('itens');

      const arrayStep1 = [];
      const arrayStep2 = [];
      const arrayStep3 = [];
      const arrayStep4 = [];

      // eslint-disable-next-line
      response.data.map((option) => {
        switch (option.step) {
          case 1:
            arrayStep1.push(option);
            break;
          case 2:
            arrayStep2.push(option);
            break;
          case 3:
            arrayStep3.push(option);
            break;
          case 4:
            arrayStep4.push(option);
            break;

          default:
            break;
        }
      });

      setOptionsStep1(arrayStep1);
      setOptionsStep2(arrayStep2);
      setOptionsStep3(arrayStep3);
      setOptionsStep4(arrayStep4);
      setLoading(false);
    }
    loadOptions();
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;

    switch (name) {
      case 'step1':
        setValueStep1(Number(event.target.value));
        if (Number(event.target.value) === 8) {
          setModalVisible(true);
        } else {
          setModalVisible(false);
          dispatch(addToCartRequest(Number(event.target.value)));
          setExpanded2(true);
        }
        break;
      case 'step2':
        if (Number(event.target.value) === 13) {
          setModalVisible(true);
        } else {
          setModalVisible(false);
          setValueStep2(Number(event.target.value));
          dispatch(addToCartRequest(Number(event.target.value)));
          setExpanded3(true);
        }
        break;
      case 'step3':
        setValueStep3(Number(event.target.value));
        dispatch(addToCartRequest(Number(event.target.value)));
        setExpanded4(true);
        break;
      default:
        break;
    }
  };

  const handleChangeChecked = (event) => {
    switch (Number(event.target.value)) {
      case 6:
        setChecked6(!checked6);
        !checked6
          ? dispatch(addToCartRequest(Number(event.target.value)))
          : dispatch(removeAditionalInCartRequest(Number(event.target.value)));
        break;
      case 7:
        setChecked7(!checked7);
        !checked7
          ? dispatch(addToCartRequest(Number(event.target.value)))
          : dispatch(removeAditionalInCartRequest(Number(event.target.value)));
        break;
      default:
        break;
    }
  };

  const handleChangeSelected = (event) => {
    setQtd(Number(event.target.value));
    dispatch(qtdRequest(Number(event.target.value)));
  };

  const handleClose = () => {
    setModalVisible(false);
    setValueStep1(null);
  };

  async function handleSubmit() {
    setLoadingForm(true);
    try {
      const response = await api.post('/sendMailContatoPos', {
        name,
        email,
        phone,
        business,
        qtdValet,
      });

      if (response.data.message === 'success') {
        history.push('/ContrateUmPlano/ContatoEfetuado');
      }
    } catch (err) {
      setLoadingForm(false);
      console.log(err);
    }
  }

  let disabled = true;

  if (
    phone !== '' &&
    name !== '' &&
    email !== '' &&
    business !== '' &&
    qtdValet !== null &&
    qtd === 0
  ) {
    disabled = false;
  }

  return (
    <DivPlansToHire>
      <Dialog
        open={modalVisible}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Entre em contato</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deixe aqui seu contato, e um de nossos vendedores irá te ligar!
          </DialogContentText>
          <TextField
            style={{ width: 320, marginBottom: 20 }}
            required
            id="outlined-basic"
            variant="outlined"
            placeholder="Seu nome"
            name="name"
            type="text"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
          <br />
          <TextField
            style={{ width: 320, marginBottom: 20 }}
            required
            id="outlined-basic"
            variant="outlined"
            placeholder="Seu e-mail"
            name="email"
            type="email"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
          <br />
          <InputMask
            style={{ width: 320, marginBottom: 20 }}
            mask="(99) 9 9999-9999"
            maskChar=""
            onChange={(event) => setPhone(event.target.value)}
            value={phone}
            name="phone"
          >
            {() => (
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                placeholder="(99) 9 9999-9999"
                name="phone"
                type="text"
              />
            )}
          </InputMask>
          <br />
          <br />
          <TextField
            style={{ width: 320, marginBottom: 20, height: 60 }}
            required
            id="outlined-basic"
            variant="outlined"
            placeholder="Fale sobre seu negócio"
            name="business"
            type="text"
            onChange={(event) => setBusiness(event.target.value)}
            value={business}
          />
          <br />
          <InputMask
            style={{ width: 320, marginBottom: 20 }}
            mask="99"
            maskChar=""
            onChange={(event) => setqtdValet(event.target.value)}
            value={qtdValet}
            name="qtdValet"
          >
            {() => (
              <TextField
                required
                id="outlined-basic"
                variant="outlined"
                placeholder="Quantos pontos você tem?"
                name="qtdValet"
                type="text"
              />
            )}
          </InputMask>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{ color: '#bbbs' }}>
            Cancelar
          </Button>
          {loadingForm ? (
            <ReactLoading type="spin" color="#FF4E00" height={20} width={20} />
          ) : (
            <Button
              onClick={handleSubmit}
              disabled={disabled}
              style={{ color: '#FF4E00' }}
            >
              Enviar contato
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <ExpansionPanel className="expansionOptions" expanded={expanded1}>
        <ExpansionPanelSummary
          onClick={() => setExpanded1(!expanded1)}
          expandIcon={<ExpandMoreIcon />}
        >
          <TitleStep>
            1. Com qual equipamento você quer usar o sistema?
          </TitleStep>
        </ExpansionPanelSummary>

        {loading ? (
          <ReactLoading type="spin" color="#FF4E00" height={40} width={40} />
        ) : (
          <>
            {optionsStep1.map((item) => (
              <>
                <ExpansionPanelDetails key={item.id}>
                  <RadioGroup
                    aria-label="step1"
                    name="step1"
                    value={valueStep1}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={item.id}
                      control={<Radio />}
                      label=""
                    />
                  </RadioGroup>
                  <TextCheckbox>{item.description}</TextCheckbox>
                  {item.id === 8 ? (
                    <TextOne>ENTRE EM CONTATO</TextOne>
                  ) : item.id === 12 ? (
                    <TextFour>R$ {item.price}</TextFour>
                  ) : item.id === 11 ? (
                    <TextThree>R$ {item.price}</TextThree>
                  ) : item.id === 10 ? (
                    <TextTwo>
                      ACESSE{' '}
                      <a
                        style={{ color: '#FF4E00' }}
                        href="https://valeti.com/loja"
                      >
                        AQUI
                      </a>
                    </TextTwo>
                  ) : item.id === 9 ? (
                    <TextFive>SEM CUSTO</TextFive>
                  ) : (
                    <TextSix>R$ {item.price}</TextSix>
                  )}
                </ExpansionPanelDetails>
                <DialogDetails
                  title="Teste"
                  body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin cursus urna vitae felis semper pulvinar. Morbi vitae ante nec orci dapibus varius vel a risus. Donec luctus accumsan metus quis vulputate. Duis tincidunt dui nec massa accumsan, id posuere erat pellentesque. Vestibulum porta dui quis vestibulum ornare."
                />
                <br />
                <br />
                <br />
              </>
            ))}
          </>
        )}
      </ExpansionPanel>

      <ExpansionPanel className="expansionOptions" expanded={expanded2}>
        <ExpansionPanelSummary
          onClick={() => setExpanded2(!expanded2)}
          expandIcon={<ExpandMoreIcon />}
        >
          <TitleStep>2. Quantos estacionamentos/valets você possui?</TitleStep>
        </ExpansionPanelSummary>

        {loading ? (
          <ReactLoading type="spin" color="#FF4E00" height={40} width={40} />
        ) : (
          <ExpansionPanelDetails>
            <Grid
              container
              direction="row"
              justify="space-evenly"
              alignItems="baseline"
              spacing={10}
            >
              {optionsStep2.map((item) => (
                <Grid item xs key={item.id}>
                  <RadioGroup
                    aria-label="step2"
                    name="step2"
                    value={valueStep2}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={item.id}
                      control={<Radio />}
                      label=""
                    />
                  </RadioGroup>
                  <TextDescription>{item.description}</TextDescription>
                  {item.id === 13 ? (
                    <Card className="cardcheckupClassOne">
                      <TextInsideCard>
                        ENTRE EM
                        <br /> CONTATO
                      </TextInsideCard>
                    </Card>
                  ) : (
                    <>
                      {item.id === 1 && valueStep2 === 1 ? (
                        <FormControl variant="outlined" style={{ width: 214 }}>
                          <InputLabel>Selecione a quantidade</InputLabel>
                          <Select
                            native
                            value={qtd}
                            onChange={handleChangeSelected}
                            label="Selecione a quantidade de pontos"
                          >
                            <option key={99} value={0} selected>
                              Selecione a Quantidade{' '}
                            </option>
                            <option key={1} value={1}>
                              1 ponto{' '}
                            </option>
                            <option key={2} value={2}>
                              2 pontos{' '}
                            </option>
                          </Select>
                        </FormControl>
                      ) : item.id === 2 && valueStep2 === 2 ? (
                        <FormControl variant="outlined" style={{ width: 214 }}>
                          <InputLabel>Selecione a quantidade</InputLabel>
                          <Select
                            native
                            value={qtd}
                            onChange={handleChangeSelected}
                            label="Selecione a quantidade de pontos"
                          >
                            <option aria-label="None" value="0">
                              de 3 a 5 pontos
                            </option>
                            <option key={3} value={3} selected>
                              3 pontos{' '}
                            </option>
                            <option key={4} value={4}>
                              4 pontos{' '}
                            </option>
                            <option key={5} value={5}>
                              5 pontos{' '}
                            </option>
                          </Select>
                        </FormControl>
                      ) : item.id === 3 && valueStep2 === 3 ? (
                        <FormControl variant="outlined" style={{ width: 214 }}>
                          <InputLabel>Selecione a quantidade</InputLabel>
                          <Select
                            native
                            value={qtd}
                            onChange={handleChangeSelected}
                            label="Selecione a quantidade de pontos"
                          >
                            <option aria-label="None" value="0">
                              de 6 a 10 pontos
                            </option>
                            <option key={6} value={6} selected>
                              6 pontos{' '}
                            </option>
                            <option key={7} value={7}>
                              7 pontos{' '}
                            </option>
                            <option key={8} value={8}>
                              8 pontos{' '}
                            </option>
                            <option key={9} value={9}>
                              9 pontos{' '}
                            </option>
                            <option key={10} value={10}>
                              10 pontos{' '}
                            </option>
                          </Select>
                        </FormControl>
                      ) : (
                        <br />
                      )}
                      <Card className="cardcheckupClass">
                        <TextInsideCard>R$ {item.price}</TextInsideCard>
                        <SubtextInsideCard>por ponto/mês</SubtextInsideCard>
                      </Card>
                    </>
                  )}
                  <DialogDetails
                    title="Teste"
                    body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin cursus urna vitae felis semper pulvinar. Morbi vitae ante nec orci dapibus varius vel a risus. Donec luctus accumsan metus quis vulputate. Duis tincidunt dui nec massa accumsan, id posuere erat pellentesque. Vestibulum porta dui quis vestibulum ornare."
                  />
                </Grid>
              ))}
            </Grid>
          </ExpansionPanelDetails>
        )}
      </ExpansionPanel>

      <ExpansionPanel className="expansionOptions" expanded={expanded3}>
        <ExpansionPanelSummary
          onClick={() => setExpanded3(!expanded3)}
          expandIcon={<ExpandMoreIcon />}
        >
          <TitleStep>3. Planos Promocionais</TitleStep>
        </ExpansionPanelSummary>

        {loading ? (
          <ReactLoading type="spin" color="#FF4E00" height={40} width={40} />
        ) : (
          <>
            {optionsStep3.map((item) => (
              <>
                <ExpansionPanelDetails key={item.id}>
                  <RadioGroup
                    aria-label="step3"
                    name="step3"
                    value={valueStep3}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={item.id}
                      control={<Radio />}
                      label=""
                    />
                  </RadioGroup>
                  <TextCheckbox>
                    {item.id === 4 ? (
                      <>
                        <span style={{ color: '#FF4E00' }}>
                          Receba Pagamentos pelo App - Ative:{' '}
                        </span>
                        <br />
                      </>
                    ) : (
                      <>
                        <span style={{ color: '#FF4E00' }}>
                          Receba Pagamentos pelo App e por Placa, Ative:
                        </span>
                        <br />
                      </>
                    )}
                    {item.description} <br />({item.percentage}% desconto na
                    mensalidade ao ativar os meios de pagamento Valeti)
                  </TextCheckbox>
                </ExpansionPanelDetails>
                <DialogDetails
                  title="Teste"
                  body="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin cursus urna vitae felis semper pulvinar. Morbi vitae ante nec orci dapibus varius vel a risus. Donec luctus accumsan metus quis vulputate. Duis tincidunt dui nec massa accumsan, id posuere erat pellentesque. Vestibulum porta dui quis vestibulum ornare."
                />
              </>
            ))}
          </>
        )}
      </ExpansionPanel>

      <ExpansionPanel className="expansionOptions" expanded={expanded4}>
        <ExpansionPanelSummary
          onClick={() => setExpanded4(!expanded4)}
          expandIcon={<ExpandMoreIcon />}
        >
          <TitleStep>4. Serviços adicionais</TitleStep>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails>
          <Grid
            container
            direction="row"
            justify="space-evenly"
            alignItems="baseline"
            spacing={10}
          >
            {loading ? (
              <ReactLoading
                type="spin"
                color="#FF4E00"
                height={40}
                width={40}
              />
            ) : (
              <>
                {optionsStep4.map((item) => (
                  <Grid item xs key={item.id}>
                    {item.id === 6 ? (
                      <Checkbox
                        checked={checked6}
                        onChange={handleChangeChecked}
                        value={item.id}
                      />
                    ) : (
                      <Checkbox
                        checked={checked7}
                        onChange={handleChangeChecked}
                        value={item.id}
                      />
                    )}
                    <TextDescription>
                      {item.description}
                      {item.id === 6 && <small> (+ taxas por emissão)</small>}
                    </TextDescription>
                    <TextInsideService>
                      R$ {item.price} / mensais / por CNPJ
                    </TextInsideService>
                    {item.id === 6 ? (
                      <DialogDetails
                        title="RPS-NFE-e"
                        body="Taxa Mensal de habilitação e manutenção do serviço de RPS/NFS-e, mais o custo unitário de R$ 0,05 centavos por NFS-e emitida. O valor mensal de R$ 20,00 é por CNPJ habilitado. O Valor de R$ 0,05 centavos é por ticket/RPS/NFS-e emitido. Cada ticket gera apenas um RPS e a emissão da NFS-e ocorre automáticamente, o valor cobrado por ticket é unitário e apenas 1 única vez por ticket para todo processo de emissão online e automática."
                      />
                    ) : (
                      <DialogDetails title="Teste" body="Pendente." />
                    )}
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </DivPlansToHire>
  );
}
