import styled from 'styled-components';

export const Image = styled.img`
  height: 1000px;
  width: 100%;

  @media (min-width: 2400px) {
    width: 70%;
    margin-left: 15%;
  }
`;
