import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import Adress from '~/assets/Adress.png';
import Contato from '~/assets/Contato.png';
import OrangeInput from '../OrangeInput';
import Logo from '~/assets/LogoFooter.png';
import Facebook from '~/assets/Facebook.png';
import Instagram from '~/assets/Instagram.png';
import Youtube from '~/assets/Youtube.png';

import api from '~/services/api';

import {
  TitleLinks,
  ImageFooterOne,
  ImageFooterTwo,
  ImageFooterThree,
  LineFooter,
  TitleLinksPrincipal,
  SocialIcons,
  SocialText,
} from './styles';
import './styles.css';

const Footer = ({ isSmallScreen }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [clientEnabled, setClientEnabled] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [business, setBusiness] = useState('');
  const [nameValet, setNameValet] = useState('');

  const handleClose = () => {
    setModalVisible(false);
  };

  const handleChangeChecked = () => {
    setClientEnabled(!clientEnabled);
    if (clientEnabled === false) {
      setNameValet('');
    }
  };

  async function handleSubmit() {
    setLoadingForm(true);
    try {
      const response = await api.post('/sendMailSuporte', {
        name,
        email,
        phone,
        business,
        nameValet,
      });

      if (response.data.message === 'success') {
        setShowSuccessMessage(true);
      }
    } catch (err) {
      setLoadingForm(false);
      console.log(err);
    }
  }

  let disabled = true;

  if (phone !== '' && name !== '' && email !== '' && business !== '') {
    disabled = false;
  }

  return (
    <div className="footerBody">
      <Grid container direction="row" spacing={2}>
        <Grid className="linksClassBodyFooter" item sm md lg xs>
          <TitleLinksPrincipal>LINKS</TitleLinksPrincipal>
          <Link to="/">
            <TitleLinks>Home</TitleLinks>
          </Link>
          <a
            href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TitleLinks>Baixe o Valeti</TitleLinks>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.valetimanobrista&hl=pt_BR"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TitleLinks>Baixe o Manobrista</TitleLinks>
          </a>
          <a
            href="https://valeti.com/privacidade.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TitleLinks>Políticas de privacidade</TitleLinks>
          </a>
          <a
            href="https://valeti.com/termos.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TitleLinks>Termos e Condições</TitleLinks>
          </a>
        </Grid>

        <Grid item sm md lg xs>
          <div className="cardBodyFooterParaVoce">
            <p className="headerNewsletterParaVoce">
              Dúvida ou sugestão? Entre em contato!
            </p>
            <Grid
              container
              direction="row"
              style={{ justifyContent: 'center' }}
            >
              <Grid>
                <Button
                  className="buttonNewsletterParaVoce"
                  variant="contained"
                  color="primary"
                  onClick={() => setModalVisible(true)}
                >
                  ENTRAR EM CONTATO
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        className="gridAdress"
        spacing={3}
      >
        <Grid item sm md lg xs>
          <ImageFooterOne src={Contato} alt="Image One" />
        </Grid>
        <Grid item sm md lg xs>
          <ImageFooterTwo src={Adress} alt="Image Two" />
        </Grid>
        <Grid item sm md lg xs>
          <ImageFooterThree src={Logo} alt="Image Three" />
        </Grid>
      </Grid>

      {!isSmallScreen && <LineFooter />}

      <Grid
        className="socialBodyClass"
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item sm md lg xs>
          <SocialText>
            Fique por dendro das novidades nas nossas redes sociais
          </SocialText>
        </Grid>

        <Grid item sm md lg xs>
          <a
            href="https://www.facebook.com/Valetiapp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcons src={Facebook} about="Facebook Valeti" />
          </a>
          <a
            href="https://www.instagram.com/valetiapp/?hl=pt-br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcons src={Instagram} about="Instagram Valeti" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCXHmqDLsZvQyHFJ0QHxxvTw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcons src={Youtube} about="YouTube Valeti" />
          </a>
        </Grid>
      </Grid>
      <Dialog
        open={modalVisible}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Entre em contato</DialogTitle>

        {!showSuccessMessage ? (
          <>
            <DialogContent>
              <DialogContentText>
                Descreva seu problema, entraremos em contato com você!
              </DialogContentText>
              <OrangeInput
                style={{ width: 320, marginBottom: 20 }}
                required
                variant="outlined"
                placeholder="Seu nome"
                name="name"
                type="text"
                onChange={(event) => setName(event.target.value)}
                value={name}
              />
              <br />
              <OrangeInput
                style={{ width: 320, marginBottom: 20 }}
                required
                variant="outlined"
                placeholder="Seu e-mail"
                name="email"
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
              />
              <br />
              <InputMask
                style={{ width: 320, marginBottom: 20 }}
                mask="(99) 9 9999-9999"
                maskChar=""
                onChange={(event) => setPhone(event.target.value)}
                value={phone}
                name="phone"
              >
                {() => (
                  <OrangeInput
                    required
                    variant="outlined"
                    placeholder="(99) 9 9999-9999"
                    name="phone"
                    type="text"
                  />
                )}
              </InputMask>
              <br />
              <br />
              <DialogContentText>Já é cliente?</DialogContentText>
              <Checkbox
                checked={clientEnabled}
                onChange={handleChangeChecked}
                value={1}
              />
              <br />
              {clientEnabled && (
                <OrangeInput
                  style={{ width: 320, marginBottom: 20, height: 60 }}
                  required
                  value={nameValet}
                  type="text"
                  onChange={(event) => setNameValet(event.target.value)}
                  label="Nome do estabelecimento"
                  variant="outlined"
                  className="textfieldDialog"
                />
              )}
              <br />
              <OrangeInput
                style={{ width: 320, marginBottom: 20, height: 60 }}
                required
                variant="outlined"
                placeholder="Fale sobre seu problema"
                name="business"
                type="text"
                onChange={(event) => setBusiness(event.target.value)}
                value={business}
              />
              <br />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} style={{ color: '#bbbs' }}>
                Cancelar
              </Button>
              {loadingForm ? (
                <ReactLoading
                  type="spin"
                  color="#FF4E00"
                  height={20}
                  width={20}
                />
              ) : (
                <Button
                  onClick={handleSubmit}
                  disabled={disabled}
                  style={{ color: '#FF4E00' }}
                >
                  Abrir Suporte
                </Button>
              )}
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                Suporte aberto com sucesso! Fique de olho em seu e-mail ou seu
                telefone!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} style={{ color: '#FF4E00' }}>
                Fechar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default Footer;
