import styled from 'styled-components';
import device from '~/util/device';

export const DivFooter = styled.div`
  height: 800px;
  max-width: 380px;
  position: relative;
  display: grid;
`;

export const DivBenefits = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 0;
  }

  @media (min-width: 2400px) {
    width: 70%;
    margin-left: 15%;
  }
`;

export const Header = styled.h1`
  color: #293f4a;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.38px;
  line-height: 64px;
  text-align: center;

  @media ${device.laptopL} {
    color: #293f4a;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.38px;
    line-height: 64px;
    text-align: center;
  }

  @media ${device.mobileL} {
    color: #293f4a;
    font-size: 40px;
    font-weight: 700;
    letter-spacing: -0.38px;
    line-height: 64px;
    text-align: center;
  }
`;

export const SubHeader = styled.h2`
  color: #222;
  font-size: 20px;
  letter-spacing: 2px;
  line-height: 32px;
  text-align: center;

  @media ${device.mobileL} {
    margin-bottom: 25%;
  }
`;

export const IconDescriptionOne = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 500px;
  margin-top: 5%;

  @media ${device.mobileL} {
    margin-left: 15%;
    margin-right: 15%;
  }

  @media ${device.laptopL} {
    h2 {
      font-size: 24px;
      font-weight: bolder;
    }

    h3 {
      font-size: 16px;
      margin-top: 13%;
    }

    h4 {
      font-size: 16px;
      margin-top: 20%;
    }

    h5 {
      font-size: 16px;
      margin-top: 5%;
    }

    h6 {
      font-size: 16px;
      margin-top: 15%;
    }
  }

  @media (min-width: 1920px) {
    h2 {
      font-size: 24px;
      font-weight: bolder;
    }

    h3 {
      font-size: 16px;
      margin-top: 13%;
    }

    h4 {
      font-size: 16px;
      margin-top: 50%;
    }

    h5 {
      font-size: 16px;
      margin-top: 15%;
    }

    h6 {
      font-size: 16px;
      margin-top: 15%;
    }
  }

  @media (min-width: 2400px) {
    width: 580px;

    h2 {
      font-size: 24px;
      font-weight: bolder;
    }

    h3 {
      font-size: 16px;
      margin-top: 13%;
    }

    h4 {
      font-size: 16px;
      margin-top: 20%;
    }

    h5 {
      font-size: 16px;
      margin-top: 15%;
    }

    h6 {
      font-size: 16px;
      margin-top: 15%;
    }
  }
`;

export const IconDescriptionTwo = styled.p`
  color: #222;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    margin-left: 15%;
    margin-right: 15%;
  }

  h2 {
    font-size: 24px;
    font-weight: bolder;
  }
`;

export const IconDescriptionThree = styled.p`
  color: #222;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    margin-left: 15%;
    margin-right: 15%;
  }

  h2 {
    font-size: 24px;
    font-weight: bolder;
  }
`;

export const IconDescriptionFour = styled.p`
  color: #222;
  font-size: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${device.mobileL} {
    margin-left: 15%;
    margin-right: 15%;
  }

  h2 {
    font-size: 24px;
    font-weight: bolder;
  }
`;

export const BenefitsIcon = styled.img`
  margin-top: 15%;
  width: 35%;

  @media ${device.mobileL} {
    margin-left: 25%;
  }
`;

export const BenefitsIcon2 = styled.img`
  margin-top: 4%;
  width: 35%;

  @media ${device.laptopL} {
    margin-top: 2%;
  }

  @media ${device.mobileL} {
    margin-left: 25%;
  }
`;

export const DivContrateBeneficios = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 65px;

  @media (min-width: 2400px) {
    width: 70%;
    margin-left: 15%;
    margin-top: 5%;
  }
`;

export const ButtonContrate = styled.button`
  color: white;
  font-size: 24px;
  border-radius: 10px;
  background-color: #222;
  box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.25);
  width: 280px;
  height: 65px;
  border: none;

  :hover {
    color: #ff4d00;
  }
`;

export const TitleCarrossel = styled.h1`
  color: white;
  font-weight: 700;
  font-size: 20px;
  letter-spacing: 6.25px;
  line-height: 20px;
  line-height: 72px;
  position: relative;
  bottom: 680px;
  left: 950px;
  width: 25%;
  height: 0px;

  @media only screen and (max-width: 1600px) {
    color: white;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 6.25px;
    line-height: 20px;
    line-height: 72px;
    position: relative;
    bottom: 580px;
    left: 730px;
    width: 25%;
    height: 0px;
  }

  @media only screen and (max-width: 990px) {
    color: white;
    font-size: 28px;
    line-height: 32px;
    position: relative;
    bottom: 320px;
    left: 10px;
    width: 45%;
    height: 0px;
  }
`;

export const SubtitleCarrossel = styled.h2`
  height: 0px;
  width: 45%;
  color: #fff;
  font-size: 56px;
  font-weight: 700;
  letter-spacing: -0.54px;
  line-height: 64px;
  position: relative;
  bottom: 630px;
  left: 948px;

  @media only screen and (max-width: 1600px) {
    height: 0px;
    width: 45%;
    color: #fff;
    font-size: 46px;
    font-weight: 700;
    letter-spacing: -0.54px;
    line-height: 64px;
    position: relative;
    bottom: 530px;
    left: 728px;
  }

  @media only screen and (max-width: 990px) {
    height: 0px;
    width: 45%;
    color: #fff;
    font-size: 18px;
    letter-spacing: 3.25px;
    line-height: 20px;
    position: relative;
    bottom: 260px;
    left: 10px;
  }
`;

export const ButtonCarrosselOne = styled.button`
  position: relative;
  bottom: 420px;
  left: 955px;
  width: 12%;
  height: 56px;
  background-color: white;
  color: #ff4b00;
  border: none;
  border-radius: 10px;

  :hover {
    background-color: #ff4b00;
    color: #fff;
  }

  @media only screen and (max-width: 1600px) {
    position: relative;
    bottom: 320px;
    left: 730px;
    width: 16%;
    height: 56px;
    background-color: white;
    color: #ff4b00;
  }

  @media only screen and (max-width: 990px) {
    position: relative;
    font-size: 12px;
    bottom: 200px;
    left: 15px;
    width: 20%;
    height: 56px;
    background-color: #ff4b00;
    color: #ffffff;
  }

  @media only screen and (max-width: 680px) {
    position: relative;
    font-size: 12px;
    bottom: 200px;
    left: 15px;
    width: 20%;
    height: 56px;
    background-color: #ff4b00;
    color: #ffffff;
  }
`;

export const ButtonCarrosselTwo = styled.button`
  position: relative;
  bottom: 420px;
  left: 995px;
  width: 12%;
  height: 56px;
  background-color: #ff4b00;
  color: #ffffff;
  border: none;
  border-radius: 10px;

  :hover {
    color: #ff4b00;
    background-color: #fff;
  }

  @media only screen and (max-width: 1600px) {
    position: relative;
    bottom: 320px;
    left: 760px;
    width: 12%;
    height: 56px;
    background-color: #ff4b00;
    color: #ffffff;
  }
`;

export const TitleCarrosselMobile = styled.h1`
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  line-height: 52px;
  position: relative;
  bottom: 40%;
  margin-left: 1%;
`;

export const ImgPrintScreen = styled.img`
  max-width: 100%;
  margin-top: 80px;

  @media (min-width: 2400px) {
    width: 70%;
    margin-left: 15%;
  }
`;

export const DivAbout = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f0f0f0;
  padding-left: 4%;

  @media only screen and (max-width: 1480px) {
    height: 1250px;
  }

  @media ${device.mobileL} {
    max-width: 380px;
    height: 2900px;
  }

  @media (min-width: 2400px) {
    width: 70%;
    margin-left: 15%;
  }
`;

export const DivFormContact = styled.div`
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #f0f0f0;
  padding-top: 4%;
  padding-left: 4%;
  padding-bottom: 4%;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1480px) {
    height: 950px;
  }

  @media ${device.mobileL} {
    max-width: 380px;
    padding-left: 0%;
    padding-top: 0%;
  }

  @media (min-width: 2400px) {
    width: 70%;
    margin-left: 15%;
  }
`;

export const DivAboutApp = styled.div`
  position: relative;
  margin-left: 100px;
  margin-top: 80px;
`;

export const DivContentAbout = styled.div`
  position: relative;
  margin-left: 80px !important;
  top: 40px;
`;

export const DivContentAboutTwo = styled.div`
  position: relative;
  top: 65px;

  @media only screen and (max-width: 1480px) {
    position: relative;
    top: 65px;
    margin-left: 7% !important;
  }
`;

export const HeaderAbout = styled.h1`
  color: #222;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.38px;
  line-height: 64px;

  @media ${device.mobileL} {
    font-size: 34px;
    margin-top: 10%;
  }
`;

export const SubheaderAbout = styled.h2`
  color: #222;
  font-size: 22px;
  line-height: 30px;
  text-align: justify;

  @media ${device.mobileL} {
    width: 320px;
    line-height: 30px;
    margin-bottom: 5%;
  }
`;

export const ImgParking = styled.img`
  width: 520px;

  @media ${device.mobileL} {
    width: 280px;
  }
`;

export const DescriptionAbout = styled.p`
  color: #222;
  font-size: 16px;
  line-height: 20px;
  width: 520px;
  margin-left: 5%;

  @media ${device.mobileL} {
    width: 320px;
  }
`;

export const DescriptionAboutTwo = styled.p`
  color: #222;
  font-size: 16px;
  line-height: 20px;
  width: 420px;
`;

export const DescriptionTwoTime = styled.p`
  color: #222;
  font-size: 16px;
  line-height: 20px;
  width: 420px;
`;

export const DescriptionTwoTimes = styled.p`
  width: 420px;
  @media only screen and (max-width: 1480px) {
    position: relative;
    margin-left: 70%;
    bottom: 580px;
    width: 25%;
  }
`;

export const ImgTicket = styled.img`
  margin-right: 20%;

  @media only screen and (max-width: 1480px) {
    position: relative;
    margin-left: 10%;
    bottom: 250px;
  }

  @media (min-width: 2400px) {
    position: relative;
    margin-right: 40%;
  }
`;

export const ImgTicketTwo = styled.img`
  margin-right: 20%;

  @media only screen and (max-width: 1480px) {
    position: relative;
    margin-left: 70%;
    bottom: 550px;
  }

  @media (min-width: 2400px) {
    position: relative;
    margin-right: 40%;
  }
`;

export const ImgPlatform = styled.img`
  margin-right: 20%;

  @media only screen and (max-width: 1480px) {
    position: relative;
    margin-right: 60%;
  }

  @media (min-width: 2400px) {
    position: relative;
    margin-right: 40%;
  }
`;

export const ButtonAbout = styled.button`
  width: 183px;
  height: 73px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 4px solid #ff4d00;
  text-align: center;
  font-size: 18px;
  color: #fff;
  background-color: #ff4d00;
  margin-bottom: 2%;
  margin-right: 20%;

  :hover {
    background-color: white;
    color: #000;
  }

  @media only screen and (max-width: 1480px) {
    position: relative;
    bottom: 500px;
  }

  @media only screen and (max-width: 700px) {
    bottom: 10px;
    margin-left: 12%;
  }

  @media (min-width: 2400px) {
    position: relative;
    margin-right: 40%;
  }
`;

export const DescriptionEticket = styled.p`
  color: #222;
  font-size: 16px;
  line-height: 20px;
  width: 420px;

  @media only screen and (max-width: 1480px) {
    position: relative;
    margin-left: 35%;
    bottom: 240px;
  }
`;

export const FormContact = styled.form`
  background-color: #ffffff;
  width: 40%;
  margin-left: 28%;
  border-radius: 5px;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1480px) {
    width: 50%;
    margin-left: 23%;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-left: 0%;
  }
`;

export const HeaderForm = styled.h1`
  color: #ff4d00;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.38px;
  margin-bottom: 0;
`;

export const SubTitleForm1 = styled.h3`
  color: #ff4d00;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.38px;
  line-height: 64px;

  @media only screen and (max-width: 600px) {
    margin-left: 3%;
    margin-right: 3%;
    line-height: 32px;
  }
`;

export const ButtonSubmit = styled.button`
  width: 183px;
  height: 73px;
  font-size: 24px;
  background-color: #ff4d00;
  color: white;
  border: none;
  border-radius: 10px;
  margin-bottom: 5%;

  :disabled {
    background-color: #ddd;
    color: #ff4d00;
  }
`;

export const Input = styled.input`
  width: 400px;
  height: 56px;
  z-index: 0;
  color: #ff4b00;
  border: 1px solid #ff4b00;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${device.laptop} {
    width: 320px;
    left: 28px;
    position: relative;
    justify-content: center;
    font-size: 18px;
    max-width: 380px;
  }

  @media ${device.mobileL} {
    width: 320px;
    left: 2px;
    position: relative;
    justify-content: center;
    font-size: 18px;
    max-width: 380px;
  }
  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
  }

  :-ms-input-placeholder {
    text-align: center;
  }
`;
