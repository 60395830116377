import styled from 'styled-components';

const DescriptionButton = styled.p`
  color: #ff4d00;
  font-size: 22px;
  line-height: 60px;
  text-decoration: underline;
  margin-left: 5%;

  :hover {
    text-decoration: none;
  }
`;

export default DescriptionButton;
