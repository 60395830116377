import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import RoomIcon from '@material-ui/icons/Room';

import { apiGoogle } from '~/services/api';
import history from '~/services/history';

import {
  ButtonOrange,
  InputSearch,
  ButtonLocal,
  DivAddressSearch,
} from './styles';

export const ComponentInputAndButtons = () => {
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);
  let autoComplete;
  let disabled = true;

  if (query !== '') {
    disabled = false;
  }

  useEffect(() => {
    handleScriptLoad(setQuery, autoCompleteRef);
    // eslint-disable-next-line
  }, []);

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ['address'], componentRestrictions: { country: 'br' } }
    );
    autoComplete.setFields(['formatted_address', 'geometry']);
    autoComplete.addListener('place_changed', () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
  }

  // searches for new locations
  const updateCoordinates = async (e) => {
    e.preventDefault();

    const encodedAddress = encodeURI(query);
    try {
      const response = await apiGoogle.post(
        `geocode/json?address=${encodedAddress}&key=AIzaSyCkxUmg8jY83rCujpf2giYlqgn3Mok7NlI`
      );

      const lat = response.data.results[0].geometry.location.lat;
      const lng = response.data.results[0].geometry.location.lng;

      history.push(`/ReservaDeVaga?lat=${lat}&lng=${lng}`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DivAddressSearch>
      <InputSearch
        required
        label="Digite um endereço"
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Digite um endereço"
        value={query}
      />
      <ButtonOrange onClick={updateCoordinates} disabled={disabled}>
        PESQUISAR ENDEREÇO
      </ButtonOrange>
      <Link to="/ReservaDeVaga">
        <ButtonLocal className="buttonsearchTwo">
          <RoomIcon />
          PESQUISAR POR PERTO
        </ButtonLocal>
      </Link>
    </DivAddressSearch>
  );
};

export default ComponentInputAndButtons;
