import styled from 'styled-components';
import device from '~/util/device';

export const DivConnectClient = styled.div`
  background-color: #222;
  height: 300px;
  position: relative;
  bottom: 95px;
  flex: 1;

  @media ${device.laptopL} {
    background-color: #222;
    height: 250px;
    position: relative;
    bottom: 99px;
  }

  @media ${device.mobileL} {
    background-color: #222;
    height: 520px;
    position: relative;
  }

  @media (min-width: 2400px) {
    width: 70%;
    margin-left: 15%;
  }
`;

export const Image = styled.img`
  width: 170px;
  position: relative;
  margin-top: 60px;

  @media ${device.laptopL} {
    width: 120px;
  }

  @media ${device.mobileL} {
    width: 100px;
    margin-left: 10%;
  }
`;

export const Header = styled.h2`
  color: #fff;
  font-size: 22px;
  width: 220px;
  margin-left: 10%;
`;

export const Description = styled.p`
  color: #fff;
  font-size: 14px;
  width: 220px;
  margin-left: 10%;
`;
