import styled from 'styled-components';
import device from '~/util/device';

export const DivMobile = styled.div`
  @media ${device.mobileL} {
    background-color: #fff;
    width: 100%auto;
    height: 100%auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 6%;

    h1 {
      color: #293f4a;
    }

    img {
      width: 150px;
      height: 70px;
    }

    a {
      color: #293f4a;
      text-decoration: underline;
    }
  }
`;

export const DivLogin = styled.div`
  width: 100%;
  background-color: #fafafa;
  padding-bottom: 5%;
`;

export const HeaderAlreadyExist = styled.h1`
  color: #222;
  font-size: 32px;
  line-height: 60px;
  position: relative;
  margin-top: 2%;
  margin-left: 3%;
`;

export const SubHeaderAlreadyExist = styled.p`
  position: relative;
  margin-top: 2%;
  margin-left: 3%;
  opacity: 0.6;
`;

export const DivCardTwo = styled.div`
  width: 65%;
  height: 550px;
  position: relative;
  margin-top: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-left: 5%;
  background-color: #fff;

  @media ${device.laptopL} {
    width: 85%;
    height: 510px;
    position: relative;
    margin-top: 1%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
    margin-left: 5%;
  }
`;

export const DivCard = styled.div`
  width: 65%;
  height: 550px;
  position: relative;
  margin-top: 1%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-left: 10%;
  background-color: #fff;

  @media ${device.laptopL} {
    width: 85%;
    height: 510px;
    position: relative;
    margin-top: 1%;
    margin-left: 10%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
  }
`;

export const ButtonForgotPwd = styled.button`
  margin-top: 1%;
  margin-left: 4%;
  width: 50%;
  background-color: transparent;
  border: none;

  :hover {
    color: #ff4b00;
  }
`;

export const TextButtonForgotPwd = styled.p`
  font-size: 14px;
  color: #222;
  text-decoration: underline;

  :hover {
    color: #ff4b00;
    cursor: pointer;
  }
`;

export const ButtonLogin = styled.button`
  width: 91%;
  height: 60px;
  position: relative;
  margin-left: 3.5%;
  margin-top: 5%;
  font-size: 22px;
  color: white;
  background-color: #ff4b00;
  border: none;
  border-radius: 10px;

  :hover {
    color: #ff4b00;
    background-color: white;
  }

  :disabled {
    color: #ff4b00;
    background-color: #ccc;
  }
`;

export const ButtonSignUp = styled.button`
  width: 91%;
  height: 60px;
  margin-left: 3.5%;
  margin-top: 20.5%;
  font-size: 22px;
  color: white;
  background-color: #ff4b00;
  border: none;
  border-radius: 10px;

  :hover {
    color: #ff4b00;
    background-color: white;
    border: 1px solid #ff4b00;
  }

  @media ${device.laptopL} {
    width: 91%;
    height: 60px;
    position: relative;
    margin-left: 3.5%;
    margin-top: 18%;
    font-size: 22px;
    color: white;
    background-color: #ff4b00;
  }
`;
