import styled from 'styled-components';
import device from '~/util/device';

export const DivFooter = styled.div`
  height: 700px;
  justify-content: center;
  position: relative;
  display: grid;

  @media ${device.mobileL} {
    height: 800px;
    max-width: 380px;
    position: relative;
    display: grid;
  }
`;

export const TitleLinksPrincipal = styled.h2`
  color: #000;
  font-size: 16px;
  font-weight: 900;

  @media only screen and (max-width: 1480px) {
    color: #000;
    font-size: 15px;
    line-height: 22px;
  }

  @media ${device.mobileL} {
    left: auto;
  }
`;

export const TitleLinks = styled.h2`
  color: #000;
  font-size: 15px;
  line-height: 22px;

  :hover {
    color: #ff4b00;
  }

  @media only screen and (max-width: 1480px) {
    color: #000;
    font-size: 15px;
    line-height: 22px;
  }

  @media ${device.mobileL} {
    left: auto;
  }
`;

export const ImageFooterOne = styled.img`
  position: relative;
  right: 200px;

  @media only screen and (max-width: 1480px) {
    position: relative;
    right: 13% !important;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 90px;
    position: relative;
    left: 0px;
  }

  @media only screen and (max-width: 550px) {
    margin-top: 90px;
    position: relative;
    left: 0px;
    margin-left: 8%;
    max-width: 320px;
  }
`;

export const ImageFooterTwo = styled.img`
  position: relative;
  left: 140px;

  @media only screen and (max-width: 1480px) {
    position: relative;
    right: 13% !important;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 90px;
    position: relative;
    left: 0px;
  }

  @media only screen and (max-width: 550px) {
    margin-top: 10px;
    position: relative;
    left: 0px;
    margin-left: 8%;
    max-width: 320px;
  }
`;

export const ImageFooterThree = styled.img`
  margin-left: 8%;
  left: 0px;
  position: relative;

  @media only screen and (max-width: 1480px) {
    position: relative;
    right: 13% !important;
  }

  @media only screen and (max-width: 700px) {
    margin-top: 90px;
    position: relative;
    left: 0px;
  }

  @media only screen and (max-width: 550px) {
    margin-top: 30px;
    position: relative;
    left: 0px;
    margin-left: 8%;
    max-width: 320px;
  }
`;

export const LineFooter = styled.hr`
  height: 0px;
  color: rgba(0, 0, 0, 0.247);
  border: solid 1px;
  position: relative;
  bottom: 60px;

  @media only screen and (max-width: 700px) {
    height: 0px;
    color: rgba(0, 0, 0, 0.247);
    border: solid 1px;
    position: relative;
    justify-content: center;
    bottom: 60px;
  }
`;

export const SocialText = styled.p`
  opacity: 0.5;
  color: #6f6b6b;
  font-size: 16px;
  line-height: 20px;

  @media only screen and (max-width: 700px) {
    opacity: 0.5;
    color: #6f6b6b;
    font-size: 14px;
    line-height: 20px;
  }
`;

export const SocialIcons = styled.img`
  margin-left: 10px;

  :hover {
    color: #000;
  }
`;
