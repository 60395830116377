import 'antd/dist/antd.css';
import React from 'react';
import { Carousel } from 'antd';
import Image1 from '~/assets/imagemdois.png';
import Image2 from '~/assets/imagemum.png';
import { Image } from './styles';

const CarrosselMobile = () => (
  <>
    <Carousel className="carrosselPosition" autoplay>
      <>
        <Image src={Image1} alt="Carrossel 1" />
      </>
      <>
        <Image src={Image2} alt="Carrossel 2" />
      </>
    </Carousel>
  </>
);

export default CarrosselMobile;
