import styled from 'styled-components';

export const Button = styled.button`
  background-color: #fff;
  border: none;
  font-size: 12px;

  :hover {
    color: #ff4b00;
  }
`;
