import styled from 'styled-components';
import device from '~/util/device';

export const DivKnowOrangeApp = styled.div`
  height: 420px;
  background-color: #ff4b00;
  position: relative;
  justify-content: center;

  @media ${device.laptopL} {
    height: 420px;
    display: grid;
  }

  @media ${device.mobileL} {
    height: 620px;
    display: grid;
  }

  @media (min-width: 2400px) {
    width: 70%;
    margin-left: 15%;
  }
`;

export const DivknowBodyMobile = styled.div`
  height: 760px;
  background-color: #ff4b00;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const TitleDesktop = styled.h1`
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.21px;
  line-height: 44px;
  position: relative;
  margin-top: 50px;

  @media only screen and (max-width: 1520px) {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.21px;
    line-height: 44px;
    position: relative;
    margin-top: 60px;
    right: 30%;
  }

  @media only screen and (max-width: 700px) {
    color: #fff;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -0.21px;
    line-height: 44px;
    position: relative;
    margin-top: 60px;
  }
`;

export const Title = styled.h1`
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: -0.21px;
  line-height: 44px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Subtitle = styled.p`
  color: #fff;
  font-size: 22px;
  line-height: 32px;
  text-align: center;
`;

export const SubtitleDesktop = styled.p`
  color: #fff;
  font-size: 22px;
  line-height: 32px;
  margin-top: 50px;

  @media only screen and (max-width: 1520px) {
    color: #fff;
    font-size: 22px;
    line-height: 32px;
    margin-top: 10px;
    position: relative;
    right: 30%;
  }

  @media only screen and (max-width: 990px) {
    color: #fff;
    font-size: 22px;
    line-height: 32px;
    margin-top: 70px;
    width: 200px;
    margin-right: 20px;
    visibility: hidden;
    display: none;
  }
`;

export const ImageMockup = styled.img`
  text-align: center;
  width: 380px;
`;

export const Description = styled.h1`
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 44px;
  text-align: center;
`;

export const ImageDownloadApp = styled.img`
  margin-left: 30%;
  width: 150px;
  height: 70px;
`;

export const ImageMockupDesktop = styled.img`
  position: relative;
  right: 140px;
  bottom: 30px;
  width: 700px;

  @media only screen and (max-width: 1520px) {
    position: relative;
    bottom: 30px;
    right: 180px;
    width: 700px;
  }

  @media only screen and (max-width: 990px) {
    width: 140px;
    margin-top: 80px;
    margin-left: 20px;
  }
`;

export const DescriptionTitle = styled.h1`
  color: #fff;
  width: 85%;
  font-size: 32px;
  font-weight: 500;
  line-height: 44px;
  margin-top: 50px;
  margin-left: 60px;

  @media only screen and (max-width: 1480px) {
    color: #fff;
    width: 95%;
    font-size: 28px;
    font-weight: 500;
    line-height: 40px;
    margin-top: 60px;
    margin-left: 120px;
  }

  @media only screen and (max-width: 990px) {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    line-height: 34px;
    margin-top: 260px;
    margin-left: 25px;
    position: relative;
    right: 215px;
    width: 280px;
    text-align: center;
  }
`;

export const DivDownloadsBadges = styled.div`
  margin-left: 50px;

  @media only screen and (max-width: 1480px) {
    margin-left: 110px;
    overflow: hidden;
    float: left;
  }

  @media only screen and (max-width: 990px) {
    margin-left: 0px;
    position: relative;
    right: 145px;
  }
`;
