import styled from 'styled-components';

export const DivBanner = styled.div`
  height: 210px;
  background-color: #ff4b00;
`;

export const BannerTitle = styled.h1`
  font-size: 24px;
  color: white;
  position: relative;
  text-align: center;
  top: 145px;
  font-weight: 700;
`;
