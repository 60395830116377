import React from 'react';

import { BannerTitle, DivBanner } from './styles';

const OrangeBanner = ({ title }) => (
  <DivBanner>
    <BannerTitle>{title}</BannerTitle>
  </DivBanner>
);

export default OrangeBanner;
