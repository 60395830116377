import produce from 'immer';
import { toast } from 'react-toastify';

const INITIAL_STATE = {
  id: null,
  valet: null,
  dateIn: null,
  dateOut: null,
  entry: null,
  exit: null,
  distance: 0,
  address: null,
  lat: 0,
  lng: 0,
  fixedPrice: 0,
};

export default function reservation(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@reservation/ADD_VALET_TO_RESERVATION':
      return produce(state, (draft) => {
        console.log(action.valet);
        if (action.valet) {
          const { valet } = action;

          draft.id = valet.id;
          draft.valet = valet.name;
          draft.lat = valet.latitude;
          draft.lng = valet.longitude;
          draft.distance = valet.distance;
          draft.addresss = valet.address;
          draft.fixedPrice = valet.fixedPrice;

          // show toast in success case
          toast.success(
            'Estabelecimento confirmado, prossiga com sua reserva!'
          );
        }
      });
    default:
      return state;
  }
}
