import 'date-fns';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams } from 'hookrouter';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import { ExpansionPanelDetails } from '@material-ui/core/';
import { GoogleMap, Marker } from '@react-google-maps/api';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DirectionsIcon from '@material-ui/icons/Directions';

import Android from '~/assets/Android.png';
import Apple from '~/assets/Apple.png';
import markerIcon from '~/assets/explore/marker-orange.png';
import Header from '~/components/Header';
import Footer from '~/components/Footer';
import { apiGoogle, apiLaranja } from '~/services/api';
import { addValetToReservation } from '~/store/modules/reservation/actions';

import * as S from './styles';

const Reservation = () => {
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const reservation = useSelector((state) => state.reservation);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);
  const [loadingFavoritesList, setLoadingFavoritesList] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({});
  const [nearByMarkers, setNearByMarkers] = useState([]);
  const [queryParams] = useQueryParams();
  let autoComplete;

  const mapStyles = {
    width: '100%',
    height: '100%',
  };

  useEffect(() => {
    setLoadingFavoritesList(true);
    handleScriptLoad(setQuery, autoCompleteRef);
    async function searchNearBy() {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          const currentPosition = {
            lat: Number(queryParams.lat) || position.coords.latitude,
            lng: Number(queryParams.lng) || position.coords.longitude,
          };

          setCurrentPosition(currentPosition);

          try {
            const responseMarkers = await apiLaranja.get(
              `/valet/getNear?lat=${currentPosition.lat}&long=${currentPosition.lng}&dist=200&refLat=${currentPosition.lat}&refLong=${currentPosition.lng}&limit=10`
            );

            setNearByMarkers(responseMarkers.data);
            setLoadingFavoritesList(false);
          } catch (err) {
            setLoadingFavoritesList(false);
          }
        },
        function (error) {
          console.log('Teste 4. Error code: ' + error.code);
        }
      );
    }

    searchNearBy();
    // eslint-disable-next-line
  }, []);

  function handelValetToReservation(item) {
    dispatch(
      addValetToReservation({
        id: item.id,
        name: item.name,
        latitude: item.latitude,
        longitude: item.longitude,
        distance: item.distance,
        fixedPrice: item.fixedPrice,
        address: item.address,
      })
    );
  }

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ['address'], componentRestrictions: { country: 'br' } }
    );
    autoComplete.setFields(['formatted_address', 'geometry']);
    autoComplete.addListener('place_changed', () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
  }

  const updateCoordinates = async (e) => {
    e.preventDefault();

    const encodedAddress = encodeURI(query);
    try {
      const response = await apiGoogle.post(
        `geocode/json?address=${encodedAddress}&key=AIzaSyCkxUmg8jY83rCujpf2giYlqgn3Mok7NlI`
      );

      const currentPosition = {
        lat: response.data.results[0].geometry.location.lat,
        lng: response.data.results[0].geometry.location.lng,
      };

      setCurrentPosition(currentPosition);
    } catch (err) {
      console.log(err);
    }
  };

  let disabledSearch = true;

  if (query !== '') {
    disabledSearch = false;
  }

  return (
    <>
      {/* header menu */}
      <Header
        type="forYou"
        active="reservation"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />
      {isSmallScreen ? (
        <>
          {/* div mobile start */}
          <S.DivReservationMobile>
            <h1>
              Para reservar vaga, seja rápida ou agendada, baixe agora o app
              Valeti!
            </h1>
            <a
              href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Android} alt="Android" />
            </a>
            <br />
            <a
              href="https://apps.apple.com/br/app/valeti/id1069081218"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Apple} alt="iOS" />
            </a>
            <br />
          </S.DivReservationMobile>
          {/* div mobile end */}
        </>
      ) : (
        <S.DivReservation>
          <S.LeftDivBlack>
            <S.InputSearchAddress
              ref={autoCompleteRef}
              onChange={(event) => setQuery(event.target.value)}
              placeholder="Digite um endereço"
              value={query}
            />
            <br />

            <S.ButtonSearchAddress
              disabled={disabledSearch}
              variant="contained"
              onClick={updateCoordinates}
            >
              BUSCAR ENDEREÇO
            </S.ButtonSearchAddress>

            <S.NameValet>
              {reservation.id !== null ? (
                <>
                  {' '}
                  {reservation.valet}{' '}
                  <DirectionsCarIcon className="parkingIcon" />
                </>
              ) : (
                <>Selecione um Estabelecimento</>
              )}
            </S.NameValet>

            {reservation.id && (
              <>
                <S.DivDetailReservation>
                  <ExpansionPanelDetails>
                    <S.DetailReservation>
                      {reservation.address}
                      <br />
                      <DirectionsIcon className="resultsIcon" />{' '}
                      {Math.trunc(reservation.distance)} km <br />
                      {reservation.fixedPrice > 0 && (
                        <>
                          <AttachMoneyIcon className="resultsIcon" /> R${' '}
                          {reservation.fixedPrice} <br />
                        </>
                      )}
                    </S.DetailReservation>
                  </ExpansionPanelDetails>
                </S.DivDetailReservation>
                {user.profile ? (
                  <Link to="/ReservaDeVaga/SelecionarCarro">
                    <S.ButtonReserveNow> RESERVE AGORA</S.ButtonReserveNow>
                  </Link>
                ) : (
                  <Link to="/Login">
                    <S.ButtonReserveNow> RESERVE AGORA</S.ButtonReserveNow>
                  </Link>
                )}
              </>
            )}
          </S.LeftDivBlack>

          <S.DivMap>
            {loadingFavoritesList ? (
              <ReactLoading
                type="spin"
                color="#FF4D00"
                height={40}
                width={40}
              />
            ) : (
              <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={16}
                center={currentPosition}
              >
                <Marker position={currentPosition} />
                {nearByMarkers.map((item) => {
                  return (
                    <Marker
                      key={item.name}
                      position={{
                        lat: item.latitude,
                        lng: item.longitude,
                      }}
                      icon={markerIcon}
                      onClick={() => handelValetToReservation(item)}
                    />
                  );
                })}
              </GoogleMap>
            )}
          </S.DivMap>
        </S.DivReservation>
      )}

      {/* footer start */}
      <Footer isSmallScreen={isSmallScreen} />
      {/* footer end */}
    </>
  );
};

export default Reservation;
