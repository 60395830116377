import axios from 'axios';

require('dotenv').config();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_WEB,
});

const apiLaranja = axios.create({
  baseURL: process.env.REACT_APP_API_MOTORISTA,
});

const apiLogin = axios.create({
  baseURL: process.env.REACT_APP_API_LOGIN,
});

const apiGoogle = axios.create({
  baseURL: 'https://maps.googleapis.com/maps/api/',
});

export { apiLaranja, apiLogin, apiGoogle };
export default api;
