import styled from 'styled-components';
import device from '~/util/device';

export const DivHirePlanMobile = styled.div`
  @media ${device.mobileL} {
    background-color: #fff;
    width: 100%auto;
    height: 100%auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 6%;

    h1 {
      color: #293f4a;
    }

    img {
      width: 150px;
      height: 70px;
    }

    a {
      color: #293f4a;
      text-decoration: underline;
    }
  }
`;

export const DivPaymentSuccess = styled.div`
  width: 80%;
  float: left;
`;

export const HeaderPaymentSuccess = styled.h1`
  position: relative;
  margin-top: 5%;
  margin-left: 5%;
  color: #222;
  font-size: 44px;
  line-height: 60px;
`;

export const TextPaymentSuccess = styled.p`
  color: #222;
  font-size: 24px;
  line-height: 33px;
  position: relative;
  margin-left: 5%;
`;

export const ButtonBackToHome = styled.button`
  height: 72px;
  width: 362px;
  background-color: #ff4b00;
  margin-top: 5%;
  position: relative;
  margin-left: 5%;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 20px;
  text-align: center;
  border: none;
  border-radius: 10px;

  :hover {
    background-color: #fff;
    color: #ff4b00;
    border: 1px solid #ff4b00;
  }
`;
