import styled from 'styled-components';
import device from '~/util/device';

export const DivCarrosselPhrases = styled.div`
  position: relative;

  @media ${device.desktopL} {
    bottom: 40%;
  }

  @media ${device.laptopL} {
    bottom: 80%;
  }

  @media (min-width: 2400px) {
    bottom: 600px;
  }
`;

export const TitleCarrossel = styled.h1`
  color: #fff;
  font-weight: 700;
  font-size: 64px;
  width: 45%;
  left: 50%;
  position: relative;
`;

export const TitleCarrosselMobile = styled.h1`
  color: #fff;
  font-weight: 700;
  font-size: 32px;
  line-height: 52px;
  bottom: 30%;
  margin-left: 1%;
`;

export const SubtitleCarrossel = styled.h2`
  width: 45%;
  color: #fff;
  font-size: 20px;
  letter-spacing: 1.75px;
  position: relative;
  left: 50%;
`;

export const DownloadImage = styled.img`
  width: 150px;
  height: 50px;
  position: relative;
  left: 50%;
`;

export const DivForFun = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeaderForFun = styled.h1`
  color: #293f4a;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.38px;
  line-height: 64px;
  text-align: center;
  overflow: auto;
  clear: both;

  @media ${device.laptop} {
    color: #293f4a;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.38px;
    line-height: 64px;
    text-align: center;
    overflow: auto;
    clear: both;
  }
`;

export const SubHeaderForFun = styled.h2`
  color: #222;
  font-size: 20px;
  line-height: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${device.laptop} {
    color: #222;
    font-size: 18px;
    line-height: 40px;
    text-align: center;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

export const OtherSubHeaderForfun = styled.h1`
  color: #000;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
`;

export const DivAddressSearch = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5%;
`;

export const HeaderAddressSearch = styled.h1`
  color: #293f4a;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.38px;
  line-height: 32px;
  text-align: center;
  overflow: auto;
  clear: both;

  @media ${device.laptop} {
    color: #293f4a;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.38px;
    line-height: 64px;
    text-align: center;
    overflow: auto;
    clear: both;
    margin-top: 220px;
    max-width: 385px;
  }
`;
