import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import {
  Button,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core/';
import { GoogleMap, InfoWindow, Marker } from '@react-google-maps/api';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DirectionsIcon from '@material-ui/icons/Directions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { apiLaranja } from '~/services/api';
import history from '~/services/history';
import markerIcon from '~/assets/explore/marker-orange.png';

import './styles.css';

export const ParkNearby = ({ isSmallScreen }) => {
  const [loadingFavoritesList, setLoadingFavoritesList] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({});
  const [currentPositionWindow, setCurrentPositionWindow] = useState({});
  const [infoSelectedValet, setInfoSelectedValet] = useState({});
  const [nearBy, setNearBy] = useState([]);
  const [nearByMarkers, setNearByMarkers] = useState([]);
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  useEffect(() => {
    setLoadingFavoritesList(true);
    async function searchNearBy() {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentPosition(currentPosition);

          const response = await apiLaranja.get(
            `/valet/getNear?lat=${position.coords.latitude}&long=${position.coords.longitude}&dist=200&refLat=${position.coords.latitude}&refLong=${position.coords.longitude}&limit=4`
          );
          setNearBy(response.data);

          const responseMarkers = await apiLaranja.get(
            `/valet/getNear?lat=${position.coords.latitude}&long=${position.coords.longitude}&dist=200&refLat=${position.coords.latitude}&refLong=${position.coords.longitude}&limit=25`
          );
          setNearByMarkers(responseMarkers.data);
          setLoadingFavoritesList(false);
        },
        function (error) {
          console.log('Error occurred. Error code: ' + error.code);
        }
      );
    }
    searchNearBy();
  }, []);

  const divStyle = {
    background: `white`,
    padding: 15,
  };

  const mapStyles = {
    width: '800px',
    height: '650px',
    overflow: 'auto',
    clear: 'both',
    position: 'relative',
    display: 'flex',
    float: 'right',
    top: '140px',
    right: '140px',
  };

  const handleShowInfoValet = (item) => {
    setCurrentPositionWindow({
      lat: item.latitude,
      lng: item.longitude,
    });
    setShowInfoWindow(true);
    setInfoSelectedValet(item);
  };

  const handleHistoryToMap = () => {
    history.push(
      `/ReservaDeVaga?lat=${infoSelectedValet.latitude}&lng=${infoSelectedValet.longitude}`
    );
  };

  return (
    <div className="parkingBody">
      <br />
      <p className="parkingHeader">ESTACIONE POR PERTO</p>

      {!isSmallScreen && (
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={18}
          center={currentPosition}
        >
          <Marker position={currentPosition} />
          {showInfoWindow && (
            <InfoWindow position={currentPositionWindow}>
              <div style={divStyle}>
                <h1>{infoSelectedValet.name}</h1>
                <button
                  onClick={() => handleHistoryToMap()}
                  type="button"
                  className="buttonInfoMap"
                >
                  RESERVAR
                </button>
              </div>
            </InfoWindow>
          )}
          {nearByMarkers.map((item) => {
            return (
              <Marker
                key={item.name}
                position={{
                  lat: item.latitude,
                  lng: item.longitude,
                }}
                icon={markerIcon}
                onClick={() => handleShowInfoValet(item)}
              />
            );
          })}
        </GoogleMap>
      )}

      <div className="painelResults">
        {loadingFavoritesList ? (
          <ReactLoading type="spin" color="#FF4D00" height={40} width={40} />
        ) : (
          <>
            {nearBy.map((item) => (
              <>
                <ExpansionPanel className="painelBody">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="iconExpand" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="resultsBody">
                      {' '}
                      {item.name} <DirectionsCarIcon className="parkingIcon" />
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="textResults">
                      {item.address}
                      <br />
                      {/*<AvTimerIcon className='resultsIcon'/> {item.time} minutos <br/>*/}
                      {/*<LocalOfferIcon className='resultsIcon' /> {item.localOffer} <br />*/}
                      <DirectionsIcon className="resultsIcon" />{' '}
                      {Math.trunc(item.distance)} km <br />
                      {item.fixedPrice > 0 && (
                        <>
                          <AttachMoneyIcon className="resultsIcon" /> R${' '}
                          {item.fixedPrice} <br />
                        </>
                      )}
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </>
            ))}
            <Link to="/ReservaDeVaga">
              <Button
                className="buttonResults"
                variant="contained"
                color="secondary"
              >
                LOCALIZAR POR ENDEREÇO
              </Button>
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default ParkNearby;
