export function signInRequest(token, user) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: {
      token,
      user,
    },
  };
}

/**
 * Action to trigger facebook sign in/up.
 */
export function signInFacebookRequest() {
  return {
    type: '@auth/SIGN_IN_FACEBOOK_REQUEST',
  };
}

/**
 * Action to execute when sign in is complete, no matter what happened.
 */
export function signInComplete() {
  return {
    type: '@auth/SIGN_IN_COMPLETE',
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function setToken(token) {
  return {
    type: '@auth/SET_TOKEN',
    payload: { token },
  };
}

export function setTokenSuccess(token) {
  return {
    type: '@auth/SET_TOKEN_SUCCESS',
    payload: { token },
  };
}

export function signUpRequest(user) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: user,
  };
}

export function signInFailure(error) {
  return {
    type: '@auth/SIGN_IN_FAILURE',
    payload: { error },
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function cleanAuthErrors() {
  return {
    type: '@auth/CLEAN_ERRORS',
  };
}

/**
 * Action to trigger request to update a user's phone.
 *
 * @param {string} userId the user's id
 * @param {string} phone the user's phone
 */
export function updateUserPhoneRequest(userId, phone) {
  return {
    type: '@auth/UPDATE_USER_PHONE_REQUEST',
    payload: { userId, phone },
  };
}

/**
 * Action to trigger a refresh of the user's auth token.
 */
export function refreshTokenRequest(callback) {
  return {
    type: '@auth/REFRESH_TOKEN_REQUEST',
    payload: { callback },
    meta: {
      retry: true,
    },
  };
}

/**
 * Action to request a password reset.
 *
 * @param {string} email the user's email
 */
export function resetPasswordRequest(email) {
  return {
    type: '@user/RESET_PASSWORD_REQUEST',
    payload: { email },
  };
}
