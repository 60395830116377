import styled from 'styled-components';
import device from '~/util/device';

export const DivReservationMobile = styled.div`
  @media ${device.mobileL} {
    background-color: #fff;
    width: 100%auto;
    height: 100%auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 6%;

    h1 {
      color: #293f4a;
    }

    img {
      width: 150px;
      height: 70px;
    }
  }
`;

export const DivChooseCar = styled.div`
  width: 80%;
  background-color: white;
  float: left;
  @media ${device.laptopL} {
    width: 70%;
  }
`;

export const DivInfos = styled.div`
  position: relative;
  margin-top: 6%;
  margin-left: 6%;
`;

export const HeaderName = styled.h1`
  color: #222;
  font-size: 44px;
  line-height: 60px;
`;

export const TextTwo = styled.p`
  color: #222;
  font-size: 24px;
  line-height: 33px;
`;

export const TextThree = styled.p`
  color: #222;
  font-size: 24px;
  line-height: 33px;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const TextRadioButton = styled.p`
  color: #000;
  font-size: 20px;
  line-height: 27px;
  position: relative;
  top: 10px;

  :hover {
    color: #ff4b00;
    font-size: 20px;
    line-height: 27px;
    position: relative;
    top: 10px;
  }
`;

export const ButtonConfirm = styled.button`
  height: 72px;
  width: 362px;
  border-radius: 5px;
  background-color: #ff4d00;
  color: white;
  font-size: 20px;
  position: relative;
  margin-top: 3%;
  border: none;
  border-radius: 10px;

  :hover {
    background-color: white;
    color: #ff4d00;
    border: 1px solid #ff4d00;
  }

  :disabled {
    background-color: #bbb;
    color: #ff4d00;
  }
`;
