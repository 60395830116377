import styled from 'styled-components';
import device from '~/util/device';

export const BurguerButton = styled.button`
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
  color: #ff4d00;

  :active {
    transform: scale(1.2);
  }
`;

export const Container = styled.div`
  background: #fff;
`;

export const Logo = styled.img`
  height: auto;

  @media ${device.mobileL} {
    margin-right: 50%;
  }

  :hover {
    cursor: pointer;
  }
`;

export const OrangeTitle = styled.h3`
  color: #ff4d00;
  font-weight: bolder;
  font-size: 20px;
  margin-left: 25%;
  margin-right: 10%;
  vertical-align: middle;
  width: 200px;
  transition: 80ms;
  margin-top: 35px;

  :hover {
    transform: scale(1.1);
    font-weight: bold;
  }
`;

export const BlackTitle = styled.h3`
  color: #000;
  font-size: 14px;
  line-height: 20px;
  transition: 80ms;
  width: 100%;
  margin-top: 35px;
  margin-right: 15px;

  :hover {
    transform: scale(1.1);
    font-weight: bold;
  }
`;

export const BlackTitleCompany = styled.h3`
  color: #000;
  font-size: 14px;
  line-height: 20px;
  transition: 80ms;
  width: 100%;
  margin-top: 35px;
  margin-right: 15px;

  :hover {
    transform: scale(1.1);
    font-weight: bold;
    color: #ff4d00;
  }
`;

export const NavMobile = styled.div`
  height: 100vw;
  width: 100vw;
  background: #fff;
  z-index: 2;
  position: fixed;
  margin-top: 25%;
  border-top: 2px solid #ff4d00;
  border-bottom: 2px solid #ff4d00;
`;

export const UsernameLogged = styled.p`
  color: #000;
  font-size: 18px;
  margin-left: 10%;
  vertical-align: middle;
`;

export const OrangeTitleMobile = styled.h3`
  color: #ff4d00;
  font-weight: bolder;
  font-size: 20px;
  vertical-align: middle;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 10%;

  :hover {
    transform: scale(1.1);
    font-weight: bold;
  }
`;

export const BlackTitleMobile = styled.h3`
  color: #000;
  font-size: 18px;
  line-height: 20px;
  vertical-align: middle;
  transition: 80ms;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 10%;

  :hover {
    transform: scale(1.1);
    font-weight: bold;
  }
`;

export const DivCloseMenu = styled.div`
  color: #ff4d00;
  margin-top: 25%;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  position: relative;
  margin-left: 15%;
  height: 36px;
  width: 150px;
  background-color: #ff4b00;
  color: #ffffff;
  border-radius: 10px;
  border: none;
  margin-top: 5px;

  :hover {
    color: #ff4b00;
    background-color: #ffffff;
    border: 1px solid #ff4b00;
  }
`;

export const ButtonCancel = styled.button`
  position: relative;
  margin-left: 5%;
  height: 36px;
  width: 120px;
  background-color: #d65940;
  color: #ffffff;
  border-radius: 10px;
  border: none;

  :hover {
    color: #d65940;
    background-color: #ffffff;
    border: 1px solid #d65940;
  }
`;

export const ButtonSubmit = styled.button`
  position: relative;
  height: 36px;
  width: 100%;
  background-color: #ff4b00;
  color: #ffffff;
  border-radius: 10px;
  border: none;

  :hover {
    color: #ff4b00;
    background-color: #ffffff;
    border: 1px solid #ff4b00;
  }
`;
