import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ChooseReservationCar from '~/pages/ChooseReservationCar';
import ForYou from '~/pages/ForYou';
import ForYourCompany from '~/pages/ForYourCompany';
import HirePlan from '~/pages/HirePlan';
import LoginPage from '~/pages/Login';
import PaymentPlan from '~/pages/PaymentPlan';
import PaymentPlanSuccess from '~/pages/PaymentPlanSuccess';
import Reservation from '~/pages/Reservation';
import ReservationSuccess from '~/pages/ReservationSuccess';
import SignUp from '~/pages/SignUp';
import SignUpCheck from '~/pages/SignUpCheck';
import Trial from '~/pages/Trial';
import TrialSuccess from '~/pages/TrialSuccess';

export default function Routes() {
  return (
    <Switch>
      {/* Páginas de apresentação dos serviços */}
      <Route path="/" exact component={ForYou} />
      <Route path="/ParaSuaEmpresa" exact component={ForYourCompany} />
      {/* Páginas de apresentação dos serviços */}

      {/* Páginas de reserva de vaga */}
      <Route path="/ReservaDeVaga" exact component={Reservation} />
      <Route path="/Login" exact component={LoginPage} />
      <Route path="/ReservaDeVaga/NovoUsuario" exact component={SignUp} />
      <Route
        path="/ReservaDeVaga/ConfirmarCadastro"
        exact
        component={SignUpCheck}
      />
      <Route
        path="/ReservaDeVaga/SelecionarCarro"
        exact
        component={ChooseReservationCar}
      />
      <Route
        path="/ReservaDeVaga/Sucesso"
        exact
        component={ReservationSuccess}
      />
      {/* Páginas de reserva de vaga */}

      {/* Páginas contratar serviço valet B2B */}
      <Route path="/ContrateUmPlano" exact component={HirePlan} />
      <Route path="/Pagamento" exact component={PaymentPlan} />
      <Route path="/PagamentoEfetuado" exact component={PaymentPlanSuccess} />
      {/* Páginas contratar serviço valet B2B */}

      {/* Página 30 dias gratuitos */}
      <Route path="/Trial" exact component={Trial} />
      <Route path="/Trial/sucesso" exact component={TrialSuccess} />
      {/* Página 30 dias gratuitos */}
    </Switch>
  );
}
