import styled from 'styled-components';
import device from '~/util/device';

export const DivHirePlanMobile = styled.div`
  @media ${device.mobileL} {
    background-color: #fff;
    width: 100%auto;
    height: 100%auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 6%;

    h1 {
      color: #293f4a;
    }

    img {
      width: 150px;
      height: 70px;
    }

    a {
      color: #293f4a;
      text-decoration: underline;
    }
  }
`;


export const Input = styled.input`
  width: 400px;
  height: 56px;
  z-index: 0;
  color: #ff4b00;
  border: 1px solid #ff4b00;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${device.mobileL} {
    width: 320px;
    left: 2px;
    position: relative;
    justify-content: center;
    font-size: 18px;
    max-width: 380px;
  }
  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
  }

  :-ms-input-placeholder {
    text-align: center;
  }
`;

export const ButtonSubmit = styled.button`
  width: 183px;
  height: 53px;
  font-size: 24px;
  background-color: #ff4d00;
  color: white;
  border: none;
  border-radius: 10px;
  margin-top: 5%;

  :disabled {
    background-color: #ddd;
    color: #ff4d00;
  }
`;
