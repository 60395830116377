import styled from 'styled-components';
import device from '~/util/device';

export const DivAddressSearch = styled.div`
  display: flex;
  justify-content: center;
`;

export const InputSearch = styled.input`
  width: 400px;
  height: 56px;
  z-index: 0;
  color: #ff4b00;
  border: 1px solid #ff4b00;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${device.laptop} {
    width: 320px;
    left: 28px;
    position: relative;
    justify-content: center;
    font-size: 18px;
    max-width: 380px;
  }
  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
  }

  :-ms-input-placeholder {
    text-align: center;
  }
`;

export const ButtonOrange = styled.button`
  height: 56px;
  width: 285px;
  background-color: white;
  color: #ff4b00;
  margin-left: 30px;
  border-color: #ff4b00;
  font-size: 16px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin-right: 2%;
  border: none;

  :disabled {
    background-color: #bbb;
    color: #ff4b00;
  }

  :hover {
    color: white;
    background-color: #ff4b00;
  }

  @media ${device.laptop} {
    position: relative;
    left: 30px;
    justify-content: center;
    max-width: 380px;
    height: 56px;
    width: 220px;
    background-color: white;
    color: #ff4b00;
    margin-left: 50px;
    border-color: #ff4b00;
    font-size: 14px;
  }
`;

export const ButtonLocal = styled.button`
  height: 56px;
  width: 285px;
  background-color: #ff4b00;
  color: white;
  margin-left: 0px;
  font-size: 16px;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  border: none;

  :hover {
    color: #ff4b00;
    background-color: white;
    border: 1px solid #ff4b00;
  }

  @media ${device.laptop} {
    position: relative;
    justify-content: center;
    max-width: 380px;
    left: 90px;
    height: 56px;
    width: 220px;
    background-color: #ff4b00;
    color: white;
    margin-left: 0px;
    border-color: #ff4b00;
    font-size: 14px;
  }
`;
