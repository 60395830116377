import styled from 'styled-components';

export const DivPlansToHire = styled.div`
  float: left;
  clear: both;
  width: 80%;
  max-width: 80%;
  position: relative;
`;

export const TitleStep = styled.h1`
  color: #222;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
`;

export const TextCheckbox = styled.p`
  color: #000;
  font-size: 20px;
  line-height: 27px;
  position: relative;
  top: 10px;

  background-color: ${(props) => props.background};

  :hover {
    color: #ff4b00;
  }
`;

export const TextOne = styled.p`
  left: 30%;
  position: relative;
  top: 10px;
  color: #222;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;

  @media only screen and (max-width: 1520px) {
    left: 10%;
    position: relative;
    top: 10px;
    color: #222;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
  }
`;

export const TextTwo = styled.p`
  left: 67%;
  position: relative;
  top: 10px;
  color: #222;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;

  @media only screen and (max-width: 1520px) {
    left: 60%;
    position: relative;
    top: 10px;
    color: #222;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
  }
`;

export const TextThree = styled.p`
  left: 75%;
  position: relative;
  top: 10px;
  color: #222;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;

  @media only screen and (max-width: 1520px) {
    left: 70%;
    position: relative;
    top: 10px;
    color: #222;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
  }
`;

export const TextFour = styled.p`
  left: 55%;
  position: relative;
  top: 10px;
  color: #222;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;

  @media only screen and (max-width: 1520px) {
    left: 43%;
    position: relative;
    top: 10px;
    color: #222;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
  }
`;

export const TextFive = styled.p`
  left: 40%;
  position: relative;
  top: 10px;
  color: #222;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;

  @media only screen and (max-width: 1520px) {
    left: 24%;
    position: relative;
    top: 10px;
    color: #222;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
  }
`;

export const TextSix = styled.p`
  left: 510px;
  position: relative;
  top: 10px;
  color: #222;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  text-align: center;

  @media only screen and (max-width: 1520px) {
    left: 30%;
    position: relative;
    top: 10px;
    color: #222;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    text-align: center;
  }
`;

export const TextDescription = styled.p`
  color: #000;
  font-size: 20px;
  line-height: 27px;
  position: relative;
  margin-left: 35px;
  bottom: 35px;
`;

export const TextInsideCard = styled.p`
  font-size: 18px;
  position: relative;
  top: 12px;
  color: white;
  font-weight: bold;
  cursor: pointer;
`;

export const SubtextInsideCard = styled.p`
  position: relative;
  bottom: 10px;
  color: white;
`;

export const TextInsideService = styled.p`
  font-size: 32px;
  color: #222;
  font-weight: bolder;
  position: relative;
  bottom: 20px;
  left: 30px;
`;
