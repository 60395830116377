import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core/';

import CssTextField from '../OrangeInput';

import Adress from '~/assets/Adress.png';
import Contato from '~/assets/Contato.png';
import Logo from '~/assets/LogoFooter.png';
import Facebook from '~/assets/Facebook.png';
import Instagram from '~/assets/Instagram.png';
import Youtube from '~/assets/Youtube.png';

import {
  TitleLinks,
  ImageFooterOne,
  ImageFooterTwo,
  ImageFooterThree,
  LineFooter,
  TitleLinksPrincipal,
  SocialIcons,
  SocialText,
} from './styles';
import './styles.css';

import api from '~/services/api';

const Footer = ({ isSmallScreen }) => {
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [email, setEmail] = useState('');

  async function handleSubmit() {
    setLoading(true);
    setShowError(false);
    setMsgError(null);
    try {
      console.log('teste');
      const response = await api.post('/newsletter/sub', {
        email,
      });

      if (response.data.message === 'success') {
        setLoading(false);
        setShowSuccess(true);
        setShowError(false);
        setEmail('');
      }
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      setMsgError(err.response.data.error);
      setShowError(true);
      setShowSuccess(false);
    }
  }

  return (
    <div className="footerBody">
      <Grid container direction="row">
        <Grid className="linksClassBodyFooter" item sm md lg xs>
          <TitleLinksPrincipal>LINKS</TitleLinksPrincipal>
          <Link to="/">
            <TitleLinks>Home</TitleLinks>
          </Link>
          <a
            href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TitleLinks>Baixe o Valeti</TitleLinks>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.valetimanobrista&hl=pt_BR"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TitleLinks>Baixe o Manobrista</TitleLinks>
          </a>
          <a
            href="https://valeti.com/privacidade.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TitleLinks>Políticas de privacidade</TitleLinks>
          </a>
          <a
            href="https://valeti.com/termos.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TitleLinks>Termos e Condições</TitleLinks>
          </a>
        </Grid>

        <Grid item sm md lg xs>
          <div className="cardBodyFooter">
            <p className="headerNewsletter">
              Assine nossa newsletter e receba dicas de mobilidade
            </p>
            <Grid container direction="row" spacing={2}>
              <Grid item sm md lg xs>
                <CssTextField
                  className="textEmailClass"
                  label="Seu melhor e-mail"
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Grid>
              <Grid item sm md lg xs>
                {loading ? (
                  <ReactLoading
                    type="spin"
                    color="#FF4D00"
                    height={40}
                    width={40}
                  />
                ) : (
                  <Button
                    className="buttonNewsletter"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    QUERO ASSINAR
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        className="gridAdress"
        spacing={3}
        sm={1}
        md={12}
        lg={12}
        xs={12}
      >
        <Grid item sm md lg xs>
          <ImageFooterOne src={Contato} alt="Image One" />
        </Grid>
        <Grid item sm md lg xs>
          <ImageFooterTwo src={Adress} alt="Image Two" />
        </Grid>
        <Grid item sm md lg xs>
          <ImageFooterThree src={Logo} alt="Image Three" />
        </Grid>
      </Grid>

      {!isSmallScreen && <LineFooter />}

      <Grid
        className="socialBodyClass"
        container
        direction="row"
        alignItems="center"
        spacing={3}
        sm={12}
        md={12}
        lg={12}
        xs={12}
      >
        <Grid item sm md lg xs>
          <SocialText>
            Fique por dendro das novidades nas nossas redes sociais
          </SocialText>
        </Grid>

        <Grid item sm md lg xs>
          <a
            href="https://www.facebook.com/Valetiapp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcons src={Facebook} about="Facebook Valeti" />
          </a>
          <a
            href="https://www.instagram.com/valetiapp/?hl=pt-br"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcons src={Instagram} about="Instagram Valeti" />
          </a>
          <a
            href="https://www.youtube.com/channel/UCXHmqDLsZvQyHFJ0QHxxvTw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <SocialIcons src={Youtube} about="YouTube Valeti" />
          </a>
        </Grid>
      </Grid>
      <Dialog
        open={showError}
        onClose={() => setShowError(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Erro ao realizar inscrição!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msgError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowError(false)} color="primary" autoFocus>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showSuccess}
        onClose={() => setShowSuccess(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Inscrição Realizada!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Agora fique de olho em seu e-mail e receba as novidades da Valeti
            por lá!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowSuccess(false)}
            color="primary"
            autoFocus
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Footer;
