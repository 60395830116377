import { takeLatest, call, put, all, select } from 'redux-saga/effects';

import {
  signInSuccess,
  signInFailure,
  setTokenSuccess,
  signOut,
  signInComplete,
} from './actions';
import history from '../../../services/history';
import { apiLaranja, apiLogin } from '../../../services/api';

/**
 * Saga to handle successful login. Redirects the user accordingly.
 *
 * @param {object} param0 object containing action parameters and the payload
 */
function* signInSuccessSaga({ payload }) {
  const { user, token } = payload;
  if (user.verified) {
    apiLaranja.defaults.headers.Authorization = `Bearer ${token}`;
    yield put(signInComplete());
  } else {
    // If the user is not yet verified, redirect to code verification page
    history.push('/ReservaDeVaga/ConfirmarCadastro');
  }
}

function* signIn({ payload }) {
  try {
    const { token, user } = payload;

    yield put(signInSuccess(token, user));
  } catch (err) {
    yield put(signInFailure(err.response ? err.response.data.error : null));
  }
}

/**
 * Saga to handle facebook logins.

function* signInFacebook() {
  try {
    const fbResponse = yield call(LoginManager.logInWithPermissions, ['public_profile', 'email']);

    if (fbResponse.isCancelled) {
      yield put(signInFailure('Falha no login'));
    } else {
      const tokenResponse = yield call(AccessToken.getCurrentAccessToken);

      const access_token = tokenResponse.accessToken.toString();
      const userDataResponse = yield call(getFacebookUserInfo, access_token);

      const loginResponse = yield call(authApi.post, '/driver/orange/loginFacebook', {
        token: tokenResponse.accessToken.toString(),
        name: userDataResponse.name,
        email: userDataResponse.email,
      });

      const { token, user } = loginResponse.data;

      yield put(signInSuccess(token, user));
      analytics().logLogin({
        method: 'facebook.com',
      });
    }
  } catch (err) {
    yield put(signInFailure(err.response ? err.response.data.error : 'Falha no login'));
  }
}
 */
function* signUp({ payload }) {
  try {
    const user = {
      id: payload.id,
      phone: payload.phone,
      verified: payload.verified,
    };

    yield put(signInSuccess(null, user));
  } catch (err) {
    yield put(signInFailure(err.response ? err.response.data.error : null));
  }
}

function* setToken({ payload }) {
  if (!payload || !payload.token) return;

  const { token } = payload;
  const auth = yield select((state) => state.auth);

  apiLaranja.defaults.headers.Authorization = `Bearer ${token}`;

  if (!auth.token) {
    // If there was no previous token, this was a sign in, dispatch action to save the token in the store
    yield put(setTokenSuccess(token));
  }
}

/**
 * Saga to perform an update phone request.
 *
 * @param {any} param0 object containing the action payload
 */
function* updatePhoneRequest({ payload }) {
  try {
    const response = yield call(apiLogin.put, '/driver/phone', payload);

    const { user } = response.data;

    yield put(signInSuccess(null, user));
  } catch (err) {
    yield put(signInFailure(err.response ? err.response.data.error : null));
  }
}

/**
 * Saga to refresh the user auth token.
 */
function* refreshToken({ payload }) {
  try {
    const response = yield call(apiLogin.post, '/driver/orange/refresh');

    const { token, user } = response.data;

    yield put(signInSuccess(token, user));

    if (payload.callback) {
      payload.callback();
    }
  } catch (err) {
    yield put(signOut());
  }
}

/**
 * Saga to request a password reset.
 */
function* resetPassword({ payload }) {
  try {
    yield call(apiLogin.post, '/driver/resetPasswordRequest', {
      email: payload.email,
    });
  } catch (err) {
    console.log(err);
  }
}

export default all([
  takeLatest('@auth/SET_TOKEN', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/UPDATE_USER_PHONE_REQUEST', updatePhoneRequest),
  takeLatest('@auth/SIGN_IN_SUCCESS', signInSuccessSaga),
  takeLatest('@auth/REFRESH_TOKEN_REQUEST', refreshToken),
  takeLatest('@auth/RESET_PASSWORD_REQUEST', resetPassword),
]);
