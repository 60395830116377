import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';

import api from '~/services/api';

import Cart from '~/components/CartPlan';
import Header from '~/components/Header';
import PlansToHire from '~/components/PlansToHire';
import OrangeBanner from '~/components/OrangeBanner';

import { DivHirePlanMobile, Input, ButtonSubmit } from './styles';

const HirePlan = () => {
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  let disabled = true;

  if (email !== '' && email.length > 10) {
    disabled = false;
  }

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  async function handleSubmit() {
    setLoading(true);

    try {
      const response = await api.post('/sendContactMailLink', {
        email,
      });

      if (response.data.message === 'success') {
        toast.success('Mensagem enviada com sucesso!');
        setEmail('');
        setLoading(false);
      }
    } catch (err) {
      console.log(err.response);
      toast.warning('Mensagem enviada com sucesso!');
      setLoading(false);
    }
  }

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  return (
    <>
      {/* header menu */}
      <Header
        type="forYourCompany"
        active="forYourCompany"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />

      {isSmallScreen ? (
        <>
          <DivHirePlanMobile>
            <h1>
              Para contratar um plano acesse esse link em um Notebook ou Computador<br />
              <br />
            </h1>
            <br />
            <h3>Não consegue acessar no momento? Deixe seu email aqui, enviamos o link para você
              e assim acesse quando você quiser!</h3>

            <Input
              required
              value={email}
              type="email"
              onChange={(event) => setEmail(event.target.value)}
              placeholder="Digite seu e-mail"
            />

            {loading ? (
                <ReactLoading
                  type="spin"
                  color="#FF4D00"
                  height={40}
                  width={40}
                />
              ) : (
                <ButtonSubmit onClick={handleSubmit} disabled={disabled}>
                  ENVIAR
                </ButtonSubmit>
              )}
          </DivHirePlanMobile>
        </>
      ) : (
        <>
          {/* orange title start */}
          <OrangeBanner title="SELECIONE PARA ADICIONAR AO CARRINHO" />
          {/* orange title end */}

          {/* Plans start */}
          <PlansToHire />
          {/* Plans end */}

          {/* cart start */}
          <Cart page={1} />
          {/* cart end */}
        </>
      )}
    </>
  );
};

export default HirePlan;
