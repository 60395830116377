import { call, put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';

import {
  addToCartSuccess,
  removeAditionalInCartSuccess,
  qtdSuccess,
} from './actions';

function* addToCart(object) {
  // get infos in api by id
  const product = yield call(api.get, `/item/${object.id}`);

  // put in reducer infos of product
  yield put(addToCartSuccess(product.data));

  // show toast in success case
  toast.success('Produto adicionado com sucesso!');
}

function* qtdToCart(object) {
  // put in reducer infos of value
  yield put(qtdSuccess(object.value));

  // show toast in success case
  toast.success('Quantidade de Pontos configurado com sucesso!');
}

function* removeAditionalInCart(object) {
  // get infos in api by id
  const product = yield call(api.get, `/item/${object.id}`);

  // put in reducer infos of product
  yield put(removeAditionalInCartSuccess(product.data));

  // show toast in success case
  toast.warning('Produto removido com sucesso!');
}

export default all([
  takeLatest('@cart/ADD_REQUEST', addToCart),
  takeLatest('@cart/QTD_REQUEST', qtdToCart),
  takeLatest('@cart/REMOVE_ADITIONAL_CART_REQUEST', removeAditionalInCart),
]);
