export function addVehicleRequest(data) {
  return {
    type: '@user/ADD_VEHICLE_REQUEST',
    payload: { data },
  };
}
export function addVehicleSuccess(vehicle) {
  return {
    type: '@user/ADD_VEHICLE_SUCCESS',
    payload: { vehicle },
  };
}

export function updateVehicleRequest(data, callback) {
  return {
    type: '@user/UPDATE_VEHICLE_REQUEST',
    payload: { data, callback },
  };
}
export function updateVehicleSuccess(vehicle) {
  return {
    type: '@user/UPDATE_VEHICLE_SUCCESS',
    payload: { vehicle },
  };
}

export function getVehiclesRequest() {
  return {
    type: '@user/GET_VEHICLES_REQUEST',
  };
}

export function getVehiclesSuccess(vehicles) {
  return {
    type: '@user/GET_VEHICLES_SUCCESS',
    payload: { vehicles },
  };
}

export function addCardRequest(data, callback) {
  return {
    type: '@user/ADD_CARD_REQUEST',
    payload: { data, callback },
  };
}

/**
 * Action to request a card update.
 *
 * @param {object} data the card data to be updated
 */
export function updateCardRequest(data, callback) {
  return {
    type: '@user/UPDATE_CARD_REQUEST',
    payload: { data, callback },
  };
}

/**
 * Action to request the deletion of the provided card.
 *
 * @param {number} cardId the card id
 */
export function removeCardRequest(cardId, callback) {
  return {
    type: '@user/REMOVE_CARD_REQUEST',
    payload: { cardId, callback },
  };
}

/**
 * Action after a successfull card deletion.
 *
 * @param {number} cardId the card id
 */
export function removeCardSuccess(cardId) {
  return {
    type: '@user/REMOVE_CARD_SUCCESS',
    payload: { cardId },
  };
}

export function addCardSuccess(card) {
  return {
    type: '@user/ADD_CARD_SUCCESS',
    payload: { card },
  };
}

/**
 * Action to update the state when a card is successfully updated.
 *
 * @param {object} card the updated card
 */
export function updateCardSuccess(card) {
  return {
    type: '@user/UPDATE_CARD_SUCCESS',
    payload: { card },
  };
}

export function getCardsRequest() {
  return {
    type: '@user/GET_CARDS_REQUEST',
  };
}
export function getCardsSuccess(cards) {
  return {
    type: '@user/GET_CARDS_SUCCESS',
    payload: { cards },
  };
}

export function updateProfileRequest(data) {
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    payload: { data },
  };
}

export function updateProfileSuccess(profile) {
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
    payload: { profile },
  };
}

/**
 * Action to remove a vehicle from the user's list.
 * @param {number} id the vehicle's id
 * @param {func} callback function to call after completing the request
 */
export function removeVehicleRequest(id, callback) {
  return {
    type: '@user/REMOVE_VEHICLE_REQUEST',
    payload: {
      id,
      callback,
    },
  };
}

/**
 * Action after successfully removing a vehicle from a user's list.
 * @param {number} id the vehicle's id
 */
export function removeVehicleSuccess(id) {
  return {
    type: '@user/REMOVE_VEHICLE_SUCCESS',
    payload: {
      id,
    },
  };
}

/**
 * Action to make loading indicators stop.
 */
export function stopLoading() {
  return {
    type: '@user/STOP_LOADING',
  };
}

/**
 * Action to request the user active ticket from the backend.
 */
export function getActiveTicketRequest() {
  return {
    type: '@user/GET_ACTIVE_TICKET_REQUEST',
  };
}

/**
 * Action triggered after successfully getting a user active ticket.
 */
export function getActiveTicketSuccess(ticket) {
  return {
    type: '@user/GET_ACTIVE_TICKET_SUCCESS',
    payload: {
      ticket,
    },
  };
}

/**
 * Action to request a parking space reservation at the given valet for the given vehicle.
 *
 * @param {number} valetId the valet's id
 * @param {number} userVehicleId the user vehicle id
 * @param {function} callback on complete callback
 */
export function bookParkingSpace(valetId, userVehicleId, callback) {
  return {
    type: '@user/BOOK_TICKET_REQUEST',
    payload: {
      valetId,
      userVehicleId,
      callback,
    },
  };
}

/**
 * Action to trigger getting the user reservations.
 */
export function getReservationsRequest() {
  return {
    type: '@user/GET_RESERVATIONS_REQUEST',
  };
}

/**
 * Action to store the reservations received from the server.
 *
 * @param {Reservations[]} reservations the reservations found
 */
export function getReservationsSuccess(reservations) {
  return {
    type: '@user/GET_RESERVATIONS_SUCCESS',
    payload: { reservations },
  };
}

/**
 * Action to request a reservation cancellation.
 *
 * @param {number} reservationId the reservation id to be cancelled
 */
export function cancelReservationRequest(reservationId) {
  return {
    type: '@user/CANCEL_RESERVATION_REQUEST',
    payload: {
      reservationId,
    },
  };
}

/**
 * Action when a reservation is successfully cancelled.
 *
 * @param {number} reservationId the reservation that was cancelled
 */
export function cancelReservationSuccess(reservationId) {
  return {
    type: '@user/CANCEL_RESERVATION_SUCCESS',
    payload: {
      reservationId,
    },
  };
}
