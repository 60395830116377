import styled from 'styled-components';
import device from '~/util/device';

export const DivMobile = styled.div`
  @media ${device.mobileL} {
    background-color: #fff;
    width: 100%auto;
    height: 100%auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 6%;

    h1 {
      color: #293f4a;
    }

    img {
      width: 150px;
      height: 70px;
    }

    a {
      color: #293f4a;
      text-decoration: underline;
    }
  }
`;

export const DivSignUp = styled.div`
  width: 70%;
  float: left;

  @media only screen and (max-width: 1520px) {
    width: 70%;
    float: left;
    margin-bottom: 2%;
  }
`;

export const HeaderSignUp = styled.h1`
  margin-top: 2%;
  position: relative;
  margin-left: 6%;
  font-size: 38px;
  color: black;
`;

export const SubHeaderSignUp = styled.p`
  position: relative;
  margin-left: 6%;
  font-size: 26px;
  font-weight: 100;
  color: black;
`;

export const DivGroupInfos = styled.div`
  width: 80%;
  position: relative;
  margin-left: 5%;
  margin-top: 5%;
`;

export const LabelInputs = styled.p`
  color: #222;
  font-size: 16px;
  line-height: 20px;
  margin-top: 2%;
`;

export const ButtonSignUp = styled.button`
  background-color: #ff4b00;
  position: relative;
  margin-top: 5%;
  margin-left: 6%;
  height: 72px;
  width: 50%;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 20px;
  text-align: center;
  border: none;
  border-radius: 10px;

  :hover {
    color: #ff4b00;
    background-color: #fff;
    border: 1px solid #ff4b00;
  }

  :disabled {
    color: #ff4b00;
    background-color: #bbb;
  }
`;
