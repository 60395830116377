import React from 'react';
import Grid from '@material-ui/core/Grid';

import Conectar from '~/assets/connect.png';
import Market from '~/assets/market.png';
import Porcentagem from '~/assets/percentage.png';

import * as S from './styles';

const InfosConnectClient = () => {
  return (
    <S.DivConnectClient>
      <Grid
        container
        direction="row"
        justify="space-evenly"
        alignItems="center"
        spacing={12}
        sm={1}
        md={12}
        lg={12}
        xs={12}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          xs
          sm
          lg
        >
          <S.Header>Conectado com motoristas</S.Header>
          <S.Description>
            Através da nossa plataforma, você e quem precisa de uma vaga, estão
            conectados.{' '}
          </S.Description>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          xs
          sm
          lg
        >
          <S.Image src={Conectar} alt="Imagem conectar" />
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          xs
          sm
        >
          <S.Header>Marketplace de vagas</S.Header>
          <S.Description>
            Seja um parceiro Valeti e divulgue suas vagas para mais de 1 milhão
            de clientes por mês e aumente sua receita.{' '}
          </S.Description>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          xs
          sm
          lg
        >
          <S.Image src={Market} alt="market" />
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          xs
          sm
        >
          <S.Header>Promoções e benefícios</S.Header>
          <S.Description>
            Atraia mais clientes com promoções e benefícios. Clientes Visa Free
            Valet Restaurantes tem Valet Cortesia nos locais participantes.
            Conheça!{' '}
          </S.Description>
        </Grid>

        <Grid
          container
          direction="row"
          alignItems="center"
          xs
          sm
        >
          <S.Image src={Porcentagem} alt="porcentagem" />
        </Grid>
      </Grid>
    </S.DivConnectClient>
  );
};

export default InfosConnectClient;
