import 'antd/dist/antd.css';
import React from 'react';
import { Carousel } from 'antd';
import Image1 from '~/assets/imageOne.png';
import Image2 from '~/assets/imageTwo.png';
import { Image } from './styles';

const CarrosselDesktop = () => (
  <>
    <Carousel className="carrosselPosition" autoplay autoplaySpeed={15000}>
      <>
        <Image src={Image1} alt="Carrossel Para Você 1" />
      </>
      <>
        <Image src={Image2} alt="Carrossel Para Você 2" />
      </>
    </Carousel>
  </>
);

export default CarrosselDesktop;
