import React from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import ImageOne from '~/assets/ItemOne.png';
import ImageThree from '~/assets/ItemThree.png';
import ImageTwo from '~/assets/ItemTwo.png';
import { Image, DivCarousel } from './styles';
import './styles.css';

const CarrosselForFun = ({ isSmallScreen }) => {
  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <NavigateBeforeIcon className="arrowClass" />
      ) : (
        <NavigateNextIcon className="arrowClassTwo" />
      );
    return (
      <>
        {!isSmallScreen ? (
          <button
            style={{ background: 'transparent' }}
            onClick={onClick}
            disabled={isEdge}
            className="buttonArrow"
          >
            {pointer}
          </button>
        ) : null}
      </>
    );
  }

  return (
    <DivCarousel>
      <Carousel
        itemsToShow={isSmallScreen ? 1 : 3}
        style={{ color: '#ff4b00' }}
        infinite
        renderArrow={myArrow}
        renderPagination={({ pages, activePage, onClick }) => {
          return (
            <div style={{ flex: 1, flexDirection: 'column' }}>
              {pages.map(() => {
                return <> </>;
              })}
            </div>
          );
        }}
      >
        <Image src={ImageOne} alt="For fun Valeti" />

        <Image src={ImageTwo} alt="For fun Valeti" />

        <Image src={ImageThree} alt="For fun Valeti" />

        <Image src={ImageTwo} alt="For fun Valeti" />
      </Carousel>
    </DivCarousel>
  );
};

export default CarrosselForFun;
