import { TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#ff4e00',
    },
    '& .MuiInput-underline': {
      color: '#ff4e00',
      borderBottomColor: '#ff4e00',
    },
    '& .MuiInputBase-input': {
      color: '#ff4e00',
    },
    '& .MuiFilledInput-input': {
      color: '#ff4e00',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'grey',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey',
      },
      '&:hover fieldset': {
        borderColor: '#ff4e00',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#ff4e00',
      },
    },
  },
})(TextField);

export default CssTextField;