import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Header from '~/components/Header';
import OrangeBanner from '~/components/OrangeBanner';

import Android from '~/assets/Android.png';
import Apple from '~/assets/Apple.png';

import * as S from './styles';

const ReservationSuccess = () => {
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const user = useSelector((state) => state.user);

  const today = format(new Date(), 'dd/MM/yyyy');
  let hourExit = '';

  if (user.ticket) {
    hourExit = new Date(user.ticket.reservationExpiration);
    hourExit = format(new Date(hourExit), 'HH:mm');
  }

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  return (
    <>
      {/* header menu */}
      <Header
        type="forYou"
        active="forYou"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />

      {isSmallScreen ? (
        <>
          <S.DivMobile>
            <h1>
              Para reservar vaga, seja rápida ou agendada, baixe agora o app
              Valeti!
            </h1>
            <a
              href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Android} alt="Android" />
            </a>
            <br />
            <a
              href="https://apps.apple.com/br/app/valeti/id1069081218"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Apple} alt="iOS" />
            </a>
            <br />
          </S.DivMobile>
        </>
      ) : (
        <>
          {/* orange title start */}
          <OrangeBanner title="RESERVA EFETUADA COM SUCESSO!" />
          {/* orange title end */}

          <S.DivReservationSuccess>
            {' '}
            {/* Div da esquerda, informações de confirmação de cadastro */}
            <S.DivInfos>
              <S.HeaderText>Reserva Confirmada!</S.HeaderText>

              <S.SubHeader>
                Para visualizar melhor suas reservas, baixe agora o app Valeti!
                Lembrando, você utiliza o mesmo cadastro, não perde nenhuma
                informação e pode ter tudo no app ou no site Valeti!
              </S.SubHeader>

              <a
                style={{ marginRight: 35 }}
                href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Android} alt="Android" />
              </a>
              <a
                href="https://apps.apple.com/br/app/valeti/id1069081218"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Apple} alt="iOS" />
              </a>
              <br />

              <Link to="/">
                <S.ButtonConfirm>VOLTAR PARA A HOME</S.ButtonConfirm>
              </Link>
            </S.DivInfos>
          </S.DivReservationSuccess>
          <S.DivInfosReservation>
            {' '}
            {/* Caixa de texto lateral */}
            <S.DivContentReservation>
              {user.ticket ? (
                <>
                  <S.InfoTextOne>{user.ticket.valet.name}</S.InfoTextOne>

                  <S.InfoTextTwo>
                    Placa: {user.ticket.vehicle.plate}
                  </S.InfoTextTwo>

                  <S.InfoTextTwo>Data: {today}</S.InfoTextTwo>

                  <S.InfoTextTwo>Reserva expira às {hourExit}</S.InfoTextTwo>
                </>
              ) : (
                <S.InfoTextOne>Faça sua reserva!</S.InfoTextOne>
              )}
            </S.DivContentReservation>
          </S.DivInfosReservation>
        </>
      )}
    </>
  );
};

export default ReservationSuccess;
