import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  addCardSuccess,
  addVehicleSuccess,
  cancelReservationSuccess,
  getActiveTicketSuccess,
  getCardsSuccess,
  getReservationsSuccess,
  getVehiclesSuccess,
  removeCardSuccess,
  removeVehicleSuccess,
  stopLoading,
  updateCardSuccess,
  updateProfileSuccess,
  updateVehicleSuccess,
} from './actions';
import { apiLaranja, apiLogin } from '../../../services/api';

function* updateProfile({ payload }) {
  try {
    const response = yield call(apiLogin.put, '/driver', payload.data);
    yield put(updateProfileSuccess(response.data.user));
    toast.success('Perfil atualizado com sucesso!');
  } catch (err) {
    toast.error(err.response ? err.response.data.error : 'Erro inesperado');
  } finally {
    yield put(stopLoading());
  }
}

function* addVehicle({ payload }) {
  try {
    const response = yield call(apiLaranja.post, '/vehicle', payload.data);

    toast.success('Veículo adicionado com sucesso!');

    yield put(addVehicleSuccess(response.data));
  } catch (err) {
    toast.error('Erro ao adicionar o veículo!');
  } finally {
    yield put(stopLoading());
  }
}

function* updateVehicle({ payload }) {
  try {
    const response = yield call(
      apiLaranja.put,
      `/vehicle/${payload.data.id}`,
      payload.data
    );

    yield put(updateVehicleSuccess(response.data));

    toast.success('Veículo atualizado com sucesso!');
    if (payload.callback) {
      payload.callback();
    }
  } catch (err) {
    toast.error('Erro ao atualizar o veículo!');
  } finally {
    yield put(stopLoading());
  }
}

/**
 * Saga to remove a vehicle from the user list.
 *
 * @param {any} param0 object containing the aciton payload, which should include the vehicle id
 */
function* removeVehicle({ payload }) {
  try {
    yield call(apiLaranja.delete, `/vehicle/${payload.id}`);
    yield put(removeVehicleSuccess(payload.id));

    toast.success('Veículo removido com sucesso!');
    if (payload.callback) {
      payload.callback();
    }
  } catch (err) {
    toast.error('Erro ao remover o veículo!');
  } finally {
    yield put(stopLoading());
  }
}

function* addCard({ payload }) {
  try {
    const response = yield call(apiLaranja.post, '/card', payload.data);

    toast.success('Cartão adicionado com sucesso!');

    yield put(addCardSuccess(response.data));

    if (payload.callback) {
      payload.callback();
    }
  } catch (err) {
    toast.error('Erro ao adicionar o veículo!');
  } finally {
    yield put(stopLoading());
  }
}

/**
 * Saga to handle an update card request.
 *
 * @param {object} param0 object containing the action payload, which should include the card data
 */
function* updateCard({ payload }) {
  try {
    const response = yield call(
      apiLaranja.put,
      `/card/${payload.data.id}`,
      payload.data
    );

    toast.success('Cartão atualizado com sucesso!');

    yield put(updateCardSuccess(response.data));

    if (payload.callback) {
      payload.callback();
    }
  } catch (err) {
    toast.error('Erro ao atualizar o cartão!');
  } finally {
    yield put(stopLoading());
  }
}

/**
 * Request a card deletion.
 *
 * @param {object} param0 object containing the action payload, which should include the card id
 */
function* removeCard({ payload }) {
  try {
    yield call(apiLaranja.delete, `/card/${payload.cardId}`);

    toast.success('Cartão removido com sucesso!');

    yield put(removeCardSuccess(payload.cardId));

    if (payload.callback) {
      payload.callback();
    }
  } catch (err) {
    toast.error('Erro ao remover o cartão!');
  } finally {
    yield put(stopLoading());
  }
}

function* getCards() {
  try {
    const { data: cards } = yield call(apiLaranja.get, '/card');

    yield put(getCardsSuccess(cards));
  } catch (err) {
    toast.error('Falha ao buscar cartões!');
  }
}

function* getVehicles() {
  try {
    const { data: vehicles } = yield call(apiLaranja.get, '/vehicle');

    yield put(getVehiclesSuccess(vehicles));
  } catch (err) {
    toast.error('Falha ao buscar veículos!');
  }
}

/**
 * Saga to handle getting the user active ticket.
 */
function* getActiveTicket() {
  try {
    const { data: ticket } = yield call(apiLaranja.get, '/ticket/active');

    yield put(getActiveTicketSuccess(ticket));
  } catch (err) {
    toast.error(
      err.response ? err.response.data.error : 'Erro ao buscar ticket'
    );
  }
}

/**
 * Request a parking space reservation.
 */
function* bookParkingSpace({ payload }) {
  try {
    const { valetId, userVehicleId } = payload;

    const response = yield call(apiLaranja.post, '/ticket/book', {
      valetId,
      userVehicleId,
    });

    yield put(getActiveTicketSuccess(response.data));

    if (payload.callback) {
      payload.callback(true);
    }
  } catch (err) {
    toast.error(
      err.response ? err.response.data.error : 'Erro ao reservar vaga'
    );

    if (payload.callback) {
      payload.callback(false);
    }
  } finally {
    yield put(stopLoading());
  }
}

/**
 * Saga to get reservations from server.
 */
function* getReservations() {
  try {
    const { data: reservations } = yield call(apiLaranja.get, '/reservation');

    yield put(getReservationsSuccess(reservations));
  } catch (err) {
    toast.error(
      err.response ? err.response.data.error : 'Erro ao buscar reservas'
    );
  }
}

/**
 * Saga to cancel a reservation.
 */
function* cancelReservation({ payload }) {
  try {
    yield call(apiLaranja.delete, `/reservation/${payload.reservationId}`);

    yield put(cancelReservationSuccess(payload.reservationId));
  } catch (err) {
    toast.error(
      err.response ? err.response.data.error : 'Erro ao cancelar reserva'
    );
  }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest('@user/ADD_VEHICLE_REQUEST', addVehicle),
  takeLatest('@user/UPDATE_VEHICLE_REQUEST', updateVehicle),
  takeLatest('@user/REMOVE_VEHICLE_REQUEST', removeVehicle),
  takeLatest('@user/GET_VEHICLES_REQUEST', getVehicles),
  takeLatest('@user/GET_CARDS_REQUEST', getCards),
  takeLatest('@user/ADD_CARD_REQUEST', addCard),
  takeLatest('@user/UPDATE_CARD_REQUEST', updateCard),
  takeLatest('@user/REMOVE_CARD_REQUEST', removeCard),
  takeLatest('@user/GET_ACTIVE_TICKET_REQUEST', getActiveTicket),
  takeLatest('@user/BOOK_TICKET_REQUEST', bookParkingSpace),
  takeLatest('@user/GET_RESERVATIONS_REQUEST', getReservations),
  takeLatest('@user/CANCEL_RESERVATION_REQUEST', cancelReservation),
]);
