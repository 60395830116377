import React, { useState } from 'react';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { AES } from 'crypto-js';

import { CSSTransition } from 'react-transition-group';
import {
  AppBar,
  Toolbar,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import OrangeInput from '../OrangeInput';
import logo from '~/assets/logo.png';
import history from '~/services/history';

import {
  Button,
  ButtonSubmit,
  Container,
  Logo,
  OrangeTitle,
  BlackTitle,
  BurguerButton,
  UsernameLogged,
  NavMobile,
  OrangeTitleMobile,
  BlackTitleMobile,
  BlackTitleCompany,
  DivCloseMenu,
} from './styles';
import './styles.css';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    height: 120,
  },
  toolbar: {
    marginTop: 25,
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Header({ type, active, isSmallScreen, platform }) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const [modalVisible, setModalVisible] = useState(false);
  const [isNavVisible, setNavVisibility] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');

  async function handleSubmit() {
    setLoadingForm(true);
    try {
      const cpfClean = cpf.replace(/[^\d]+/g, '');

      const crypt = AES.encrypt(
        `${cpfClean}:${password}`,
        '@valeti2020'
      ).toString();

      window.open(`https://dev.valeti.com/portal/?login=${crypt}`);

      setLoadingForm(false);
      setModalVisible(false);
    } catch (err) {
      setLoadingForm(false);
      console.log(err);
    }
  }

  const handleClose = () => {
    setModalVisible(false);
  };

  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const redirectToHome = () => {
    history.push('/');
  };

  return (
    <Container>
      <AppBar
        className={classes.root}
        position={isSmallScreen ? 'sticky' : 'fixed'}
      >
        <Toolbar className={classes.toolbar} variant="dense">
          <Logo src={logo} alt="Valeti Logo" onClick={redirectToHome} />
          {!isSmallScreen ? (
            <>
              <Link to="/">
                <OrangeTitle>PARA VOCÊ</OrangeTitle>
              </Link>

              {type === 'forYou' ? (
                <>
                  {platform === 'Android' ? (
                    <>
                      <a href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR">
                        <BlackTitle>Reservar Vaga</BlackTitle>
                      </a>

                      <a href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR">
                        <BlackTitle>Valeti App</BlackTitle>
                      </a>
                    </>
                  ) : platform === 'iPhone' ? (
                    <>
                      <a href="https://apps.apple.com/br/app/valeti/id1069081218">
                        <BlackTitle>Reservar Vaga</BlackTitle>
                      </a>

                      <a href="https://apps.apple.com/br/app/valeti/id1069081218">
                        <BlackTitle>Valeti App</BlackTitle>
                      </a>
                    </>
                  ) : (
                    <>
                      <Link to="/ReservaDeVaga">
                        <BlackTitle>Reservar Vaga</BlackTitle>
                      </Link>
                      <Link to="/">
                        <BlackTitle>Eventos </BlackTitle>
                      </Link>
                      <Link to="/">
                        <BlackTitle>Mensalista </BlackTitle>
                      </Link>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Link to="/ContrateUmPlano">
                    <BlackTitleCompany>CONTRATE JÁ</BlackTitleCompany>
                  </Link>
                  <Link to="/Trial">
                    <BlackTitleCompany>TESTE GRATUITAMENTE</BlackTitleCompany>
                  </Link>
                </>
              )}

              <Link to="/ParaSuaEmpresa">
                <OrangeTitle>PARA SUA EMPRESA</OrangeTitle>
              </Link>

              {type === 'forYourCompany' && (
                <Button onClick={() => setModalVisible(true)}>Acessar</Button>
              )}

              {user.profile && (
                <>
                  <UsernameLogged>{user.profile.name}</UsernameLogged>
                  <PersonOutlineOutlinedIcon className="personIcon" />
                </>
              )}
            </>
          ) : (
            <>
              <BurguerButton onClick={toggleNav}>
                <MenuIcon />
              </BurguerButton>
            </>
          )}
        </Toolbar>
        {isSmallScreen && (
          <CSSTransition
            in={!isSmallScreen || isNavVisible}
            timeout={300}
            classNames="NavAnimation"
            unmountOnExit
          >
            <NavMobile>
              <Link to="/">
                <OrangeTitleMobile>PARA VOCÊ</OrangeTitleMobile>
              </Link>
              <Link to="/ReservaDeVaga">
                <BlackTitleMobile>Reserva de Vagas</BlackTitleMobile>
              </Link>
              {platform === 'Android' ? (
                <a href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR">
                  <BlackTitleMobile>Valeti App</BlackTitleMobile>
                </a>
              ) : (
                <a href="https://apps.apple.com/br/app/valeti/id1069081218">
                  <BlackTitleMobile>Valeti App</BlackTitleMobile>
                </a>
              )}

              <Link to="/ParaSuaEmpresa">
                <OrangeTitleMobile>PARA SUA EMPRESA</OrangeTitleMobile>
              </Link>
              {user.profile && (
                <>
                  <button className="buttonEnter">{user.profile.name}</button>
                  <PersonOutlineOutlinedIcon className="personIcon" />
                </>
              )}
              <DivCloseMenu onClick={toggleNav}>
                <CloseIcon />
              </DivCloseMenu>
            </NavMobile>
          </CSSTransition>
        )}
      </AppBar>

      <Dialog
        open={modalVisible}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Acesse o Painel de Controle
        </DialogTitle>
        <DialogContent>
          <br />
          <InputMask
            style={{ width: 320, marginBottom: 20 }}
            mask="999.999.999-99"
            maskChar=""
            onChange={(event) => setCpf(event.target.value)}
            value={cpf}
            name="cpf"
          >
            {() => (
              <OrangeInput
                style={{ width: 320, marginBottom: 20, height: 60 }}
                required
                variant="outlined"
                placeholder="Insira seu CPF"
                name="cpf"
                type="text"
              />
            )}
          </InputMask>
          <br />
          <br />
          <OrangeInput
            style={{ width: 320, marginBottom: 20, height: 60 }}
            required
            variant="outlined"
            placeholder="Insira sua senha"
            name="password"
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            value={password}
          />
          <br />
          <br />
          {loadingForm ? (
            <ReactLoading type="spin" color="#FF4E00" height={20} width={20} />
          ) : (
            <ButtonSubmit onClick={handleSubmit}>Acessar</ButtonSubmit>
          )}
          <br />
          <br />
        </DialogContent>
      </Dialog>
    </Container>
  );
}
