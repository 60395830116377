import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import ReactLoading from 'react-loading';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Android from '~/assets/Android.png';
import Apple from '~/assets/Apple.png';

import BackButton from '~/components/BackButton';
import Header from '~/components/Header';
import OrangeBanner from '~/components/OrangeBanner';
import OrangeInput from '~/components/OrangeInput';
import ResumeReservation from '~/components/ResumeReservation';

import { apiLogin } from '~/services/api';
import history from '~/services/history';
import { setToken } from '~/store/modules/auth/actions';
import { updateProfileSuccess } from '~/store/modules/user/actions';

import * as S from './styles';

const SignUpCheck = () => {
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);

  const [code, setCode] = useState('');
  const [tries, setTries] = useState(1);
  const [count, setCount] = useState(100);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.profile);
  const reservation = useSelector((state) => state.reservation);

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const ColorCircularProgress = withStyles({
    root: {
      color: '#ff4b00',
    },
  })(CircularProgress);

  async function wait(stallTime = 3000) {
    await new Promise((resolve) => setTimeout(resolve, stallTime));
  }

  useEffect(() => {
    async function updateCount() {
      if (count > 0) {
        await wait(1000);
        setCount(count === 5 / tries ? 0 : count - 5 / tries);
      }
    }

    updateCount();
  }, [count, tries]);

  function textToCircle() {
    const text = `${((count * tries) / 5).toFixed(0)}s`;

    if (text !== '0s') return text;

    return `${20 * tries}s`;
  }

  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <ColorCircularProgress variant="static" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="textSecondary">
            {textToCircle()}
          </Typography>
        </Box>
      </Box>
    );
  }

  function resendCode() {
    return apiLogin.post('/driver/resendCode', {
      userId: user.id,
    });
  }

  async function handleCode() {
    try {
      setLoading(true);
      const response = await apiLogin.post('/driver/website/code', {
        userId: user.id,
        code,
      });

      dispatch(updateProfileSuccess(response.data.user));
      dispatch(setToken(response.data.token));
      history.push('/ReservaDeVaga/SelecionarCarro');
    } catch (e) {
      setShowError(true);
      setError('Código inválido!');
    } finally {
      setLoading(false);
    }
  }

  let disabled = true;

  if (code !== '' && code.length === 6) {
    disabled = false;
  }

  return (
    <>
      {/* header menu */}
      <Header
        type="forYou"
        active="forYou"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />

      {isSmallScreen ? (
        <>
          <S.DivMobile>
            <h1>
              Para reservar vaga, seja rápida ou agendada, baixe agora o app
              Valeti!
            </h1>
            <a
              href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Android} alt="Android" />
            </a>
            <br />
            <a
              href="https://apps.apple.com/br/app/valeti/id1069081218"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Apple} alt="iOS" />
            </a>
            <br />
          </S.DivMobile>
        </>
      ) : (
        <>
          {/* orange title start */}
          <OrangeBanner title="CONFIRME SEU CADASTRO VIA SMS" />
          {/* orange title end */}

          {/* resume reservation start */}
          <ResumeReservation reservation={reservation} />
          {/* resume reservation end */}

          <S.DivSignUpCheck>
            <BackButton link="/ReservaDeVaga/NovoUsuario" />

            <S.HeaderSignUpCheck>Confirmar Cadastro</S.HeaderSignUpCheck>
            <S.Description>
              Para sua segurança, vamos validar seu celular com o envio de um
              SMS.
              <br /> Por favor, digite o código de confirmação enviado para o
              número {user ? user.phone : ''}
            </S.Description>

            <S.DivGroupInfos>
              <InputMask
                mask="999999"
                maskChar=""
                onChange={(event) => setCode(event.target.value)}
                value={code}
                name="code"
              >
                {() => (
                  <OrangeInput
                    required
                    variant="outlined"
                    label="Código de confirmação"
                    name="code"
                    type="text"
                    fullWidth={true}
                  />
                )}
              </InputMask>
            </S.DivGroupInfos>
            <S.InputFooter>
              <S.InputFooterText>
                {count > 0
                  ? 'Estamos enviando o seu código'
                  : 'Não recebeu o código? '}
                {count === 0 && (
                  <S.InputFooterTextBtn
                    onClick={() => {
                      resendCode();
                      setCount(100);
                      setTries(tries + 1);
                    }}
                  >
                    Reenviar
                  </S.InputFooterTextBtn>
                )}
              </S.InputFooterText>
              {count > 0 && (
                <S.Counter>
                  <CircularProgressWithLabel
                    value={count}
                    thickness={2}
                    size={80}
                  />
                </S.Counter>
              )}
            </S.InputFooter>
            <S.ButtonSignUpCheck disabled={disabled} onClick={handleCode}>
              {loading ? (
                <ReactLoading type="spin" color="#fff" height={40} width={40} />
              ) : (
                <>AVANÇAR</>
              )}
            </S.ButtonSignUpCheck>
          </S.DivSignUpCheck>

          {/* ERRO SE HOUVER */}
          <Dialog
            open={showError}
            onClose={() => setShowError(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Erro ao realizar login!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {error}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowError(false)}
                color="primary"
                autoFocus
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default SignUpCheck;
