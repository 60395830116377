import styled from 'styled-components';

export const DivBeaPartner = styled.div`
  margin-top: 0px;
  text-align: center;
  color: #222;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.21px;
  line-height: 44px;
  height: 580px;
  background-color: #f0f0f0;
  position: relative;
  justify-content: center;

  @media only screen and (max-width: 700px) {
    margin-top: 0px;
    text-align: center;
    color: #222;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.21px;
    line-height: 44px;
    height: 880px;
    background-color: #f0f0f0;
    position: relative;
    justify-content: center;
    width: 100%;
  }

  @media (min-width: 2400px) {
    width: 70%;
    margin-left: 15%;
  }
`;

export const SubTitle = styled.h2`
  color: #222;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2.73px;
  line-height: 32px;

  @media only screen and (max-width: 700px) {
    color: #222;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 2.73px;
    line-height: 32px;
    justify-content: center;
    text-align: center;
  }
`;

export const ImagePosition = styled.img`
  margin-top: 60px;
  margin-left: 30px;

  @media only screen and (max-width: 700px) {
    position: relative;
  }

  @media (min-width: 2400px) {
    margin-left: 10%;
    margin-top: 1%;
  }
`;

export const ImagePositionTwo = styled.img`
  @media only screen and (max-width: 700px) {
    position: relative;
  }

  @media (min-width: 2400px) {
    margin-top: 2%;
    margin-right: 10%;
  }
`;

export const ImagePositionThree = styled.img`
  @media only screen and (max-width: 700px) {
    position: relative;
    top: 40px;
  }

  @media (min-width: 2400px) {
    margin-top: 1%;

    margin-right: 10%;
  }
`;

export const KnowMoreButton = styled.button`
  background-color: rgb(255, 255, 255) !important;
  color: black !important;
  height: 63px;
  width: 266.57px;
  margin-top: 30px !important;

  @media only screen and (max-width: 700px) {
    position: relative;
  }

  @media (min-width: 2400px) {
    margin-top: 1%;
  }

  :hover {
    color: #ff4b00;
  }
`;
