import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactLoading from 'react-loading';
import InputMask from 'react-input-mask';
import validarCpf from 'validar-cpf';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Footer from '~/components/Footer';
import Header from '~/components/Header';
import OrangeBanner from '~/components/OrangeBanner';
import OrangeInput from '~/components/OrangeInput';

import api from '~/services/api';
import history from '~/services/history';
import { signInRequest } from '~/store/modules/auth/actions';

import Android from '~/assets/Android.png';
import Apple from '~/assets/Apple.png';

import {
  DivMobile,
  DivLogin,
  SubHeaderAlreadyExist,
  HeaderAlreadyExist,
  DivCardFirstStep,
  DivCardSecondStep,
  DivCardThirdStep,
  DivLoading,
  ButtonNext,
  MsgInvalidCpf,
  LabelConfirm,
  LabelConfirmBlack,
} from './styles';

const useStyles = makeStyles(() => ({
  textField: {
    marginLeft: '3.5%',
    width: '91%',
  },
  textField2: {
    marginLeft: '3.5%',
    width: '88%',
  },
}));

const TrialPage = () => {
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [step, setStep] = useState(1);

  // PASSO 1
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [invalidCpf, setInvalidCpf] = useState(false);
  const [phone, setPhone] = useState('');

  // PASSO 2
  const [valetName, setValetName] = useState('');
  const [valetAddress, setValetAddress] = useState('');
  const [valetCity, setValetCity] = useState('');
  const [valetUf, setvaletUf] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  /* Validação do CPF */
  const _onBlur = (e) => {
    const cpf = e.target.value.replace(/[^\d]+/g, '');
    const testCpf = validarCpf(cpf);

    if (!testCpf) {
      setInvalidCpf(true);
      setCpf('');
    } else {
      setInvalidCpf(false);
    }
  };
  /* Validação do CPF */

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const stepForward = (stepActual) => {
    switch (stepActual) {
      case 1:
        setStep(2);
        break;
      case 2:
        setStep(3);
        break;

      default:
        break;
    }
  };

  const stepBack = (stepActual) => {
    switch (stepActual) {
      case 2:
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;

      default:
        break;
    }
  };

  async function handleSubmit() {
    setSubmitLoading(true);
    try {
      const response = await api.post('/trial', {
        // PASSO 1
        email,
        name,
        cpf,
        phone,

        // PASSO 2
        valetName,
        valetAddress,
        valetCity,
        valetUf,
      });

      dispatch(signInRequest(response.data.token, response.data.user));
      history.push('/Trial/sucesso');
      setSubmitLoading(false);
    } catch (err) {
      setError(err.response.data.error);
      setShowError(true);
      setSubmitLoading(false);
    }
  }

  let disabledStep1 = true;

  if (
    email !== '' &&
    name !== '' &&
    cpf !== '' &&
    !invalidCpf &&
    phone !== '' &&
    phone.length === 16
  ) {
    disabledStep1 = false;
  }

  let disabledStep2 = true;

  if (
    valetName !== '' &&
    valetAddress !== '' &&
    valetCity !== '' &&
    valetUf !== ''
  ) {
    disabledStep2 = false;
  }

  const classes = useStyles();

  return (
    <>
      {/* header menu */}
      <Header
        type="forYourCompany"
        active="forYourCompany"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />

      {isSmallScreen ? (
        <>
          <DivMobile>
            <h1>
              Para se cadastrar no nosso teste de 30 dias grátis, por favor,
              acesse o link via computador!
            </h1>
            <a
              href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Android} alt="Android" />
            </a>
            <br />
            <a
              href="https://apps.apple.com/br/app/valeti/id1069081218"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Apple} alt="iOS" />
            </a>
            <br />
          </DivMobile>
        </>
      ) : (
        <>
          {/* orange title start */}
          <OrangeBanner title="CADASTRE-SE E TESTE POR 30 DIAS GRÁTIS" />
          {/* orange title end */}

          <DivLogin>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={12}
            >
              <Grid item xs={6}>
                {step === 1 ? (
                  <DivCardFirstStep>
                    <HeaderAlreadyExist>
                      {' '}
                      Insira os dados do Gestor e do Estabelecimento, e
                      aproveite por 30 dias!
                    </HeaderAlreadyExist>

                    <SubHeaderAlreadyExist>
                      {' '}
                      Primeiro, insira seus dados pessoais:{' '}
                    </SubHeaderAlreadyExist>

                    <OrangeInput
                      required
                      value={name}
                      type="text"
                      onChange={(event) => setName(event.target.value)}
                      label="Insira seu nome"
                      variant="outlined"
                      fullWidth={true}
                      className={classes.textField}
                      isSecureTextEntry={true}
                    />
                    <br />
                    <br />

                    <OrangeInput
                      required
                      value={email}
                      type="email"
                      onChange={(event) => setEmail(event.target.value)}
                      label="Insira seu melhor e-mail"
                      variant="outlined"
                      fullWidth={true}
                      className={classes.textField}
                      isSecureTextEntry={true}
                    />
                    <br />
                    <br />

                    <InputMask
                      mask="999.999.999-99"
                      maskChar=""
                      onChange={(event) => setCpf(event.target.value)}
                      value={cpf}
                      name="cpf"
                      required
                      onBlur={_onBlur}
                    >
                      {() => (
                        <OrangeInput
                          required
                          value={cpf}
                          type="cpf"
                          label="Insira seu CPF"
                          variant="outlined"
                          fullWidth={true}
                          className={classes.textField}
                          isSecureTextEntry={true}
                        />
                      )}
                    </InputMask>
                    <br />
                    {invalidCpf && (
                      <>
                        <MsgInvalidCpf> CPF INVÁLIDO</MsgInvalidCpf>
                        <br />
                      </>
                    )}
                    <br />

                    <InputMask
                      mask="(99) 9 9999-9999"
                      maskChar=""
                      onChange={(event) => setPhone(event.target.value)}
                      value={phone}
                      name="phone"
                      required
                    >
                      {() => (
                        <OrangeInput
                          required
                          value={phone}
                          type="phone"
                          label="Insira seu CELULAR para contato"
                          variant="outlined"
                          fullWidth={true}
                          className={classes.textField}
                          isSecureTextEntry={true}
                        />
                      )}
                    </InputMask>
                    <br />
                    <br />

                    {submitLoading ? (
                      <ReactLoading
                        type="spin"
                        color="#ff4b00"
                        height={40}
                        width={40}
                      />
                    ) : (
                      <ButtonNext
                        onClick={() => stepForward(step)}
                        disabled={disabledStep1}
                      >
                        PRÓXIMO
                      </ButtonNext>
                    )}
                  </DivCardFirstStep>
                ) : null}

                {step === 2 ? (
                  <DivCardSecondStep>
                    <HeaderAlreadyExist>
                      {' '}
                      Insira os dados do Gestor e do Estabelecimento, e
                      aproveite por 30 dias!
                    </HeaderAlreadyExist>

                    <SubHeaderAlreadyExist>
                      {' '}
                      Agora, insira os dados do seu estabelecimento:{' '}
                    </SubHeaderAlreadyExist>

                    <OrangeInput
                      required
                      value={valetName}
                      type="text"
                      onChange={(event) => setValetName(event.target.value)}
                      label="Insira o nome do seu Estabelecimento"
                      variant="outlined"
                      fullWidth={true}
                      className={classes.textField}
                      isSecureTextEntry={true}
                    />
                    <br />
                    <br />

                    <OrangeInput
                      required
                      value={valetAddress}
                      type="text"
                      onChange={(event) => setValetAddress(event.target.value)}
                      label="Insira o logradouro, com bairro por favor!"
                      variant="outlined"
                      fullWidth={true}
                      className={classes.textField}
                      isSecureTextEntry={true}
                    />
                    <br />
                    <br />

                    <OrangeInput
                      required
                      value={valetCity}
                      type="text"
                      onChange={(event) => setValetCity(event.target.value)}
                      label="Em qual cidade se situa seu estabelecimento?"
                      variant="outlined"
                      fullWidth={true}
                      className={classes.textField}
                      isSecureTextEntry={true}
                    />
                    <br />
                    <br />

                    <OrangeInput
                      required
                      value={valetUf}
                      type="text"
                      onChange={(event) => setvaletUf(event.target.value)}
                      label="Em qual estado se situa seu estabelecimento?"
                      variant="outlined"
                      fullWidth={true}
                      className={classes.textField}
                      isSecureTextEntry={true}
                    />
                    <br />
                    <br />

                    {submitLoading ? (
                      <ReactLoading
                        type="spin"
                        color="#ff4b00"
                        height={40}
                        width={40}
                      />
                    ) : (
                      <>
                        <ButtonNext
                          onClick={() => stepBack(step)}
                          disabled={false}
                        >
                          ANTERIOR
                        </ButtonNext>
                        <ButtonNext
                          onClick={() => stepForward(step)}
                          disabled={disabledStep2}
                        >
                          PRÓXIMO
                        </ButtonNext>
                      </>
                    )}
                  </DivCardSecondStep>
                ) : null}

                {step === 3 ? (
                  <DivCardThirdStep>
                    <HeaderAlreadyExist>
                      {' '}
                      Confirme os dados para receber seu acesso em no e-mail
                      cadastrado!
                    </HeaderAlreadyExist>
                    <LabelConfirmBlack>Nome</LabelConfirmBlack>
                    <LabelConfirm> {name}</LabelConfirm>

                    <LabelConfirmBlack>E-mail</LabelConfirmBlack>
                    <LabelConfirm> {email}</LabelConfirm>

                    <LabelConfirmBlack>CPF</LabelConfirmBlack>
                    <LabelConfirm> {cpf}</LabelConfirm>

                    <LabelConfirmBlack>Telefone</LabelConfirmBlack>
                    <LabelConfirm> {phone}</LabelConfirm>
                    <br />
                    <br />

                    <LabelConfirmBlack>
                      Nome do Estabelecimento{' '}
                    </LabelConfirmBlack>
                    <LabelConfirm> {valetName}</LabelConfirm>

                    <LabelConfirmBlack>Endereço</LabelConfirmBlack>
                    <LabelConfirm> {valetAddress}</LabelConfirm>

                    <LabelConfirmBlack>Cidade</LabelConfirmBlack>
                    <LabelConfirm> {valetCity}</LabelConfirm>

                    <LabelConfirmBlack>Estado</LabelConfirmBlack>
                    <LabelConfirm> {valetUf}</LabelConfirm>
                    <br />
                    <br />

                    {submitLoading ? (
                      <DivLoading>
                        <ReactLoading
                          type="spin"
                          color="#ff4b00"
                          height={40}
                          width={40}
                        />
                      </DivLoading>
                    ) : (
                      <>
                        <ButtonNext
                          onClick={() => stepBack(step)}
                          disabled={false}
                        >
                          ANTERIOR
                        </ButtonNext>
                        <ButtonNext onClick={handleSubmit} disabled={false}>
                          CONFIRMAR DADOS
                        </ButtonNext>
                      </>
                    )}
                  </DivCardThirdStep>
                ) : null}
              </Grid>
            </Grid>
          </DivLogin>
          <Dialog
            open={showError}
            onClose={() => setShowError(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Erro ao realizar cadastro!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {error}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowError(false)}
                color="primary"
                autoFocus
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={showSuccess}
            onClose={() => setShowError(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Sucesso!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Dê uma olhada em seu e-mail, e siga as intruções!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowSuccess(false)}
                color="primary"
                autoFocus
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          {/* footer start */}
          <Footer />
          {/* footer end */}
        </>
      )}
    </>
  );
};

export default TrialPage;
