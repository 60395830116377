import { put, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

// import api from '../../../services/api';

import { addReservation } from './actions';

function* addToReservation(object) {
  // put in reducer infos of valet
  yield put(addReservation(object));

  // show toast in success case
  toast.success('Reserva efetuada com sucesso!');
}

export default all([
  takeLatest('@reservation/ADD_RESERVATION', addToReservation),
]);
