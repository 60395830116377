import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import ReactLoading from 'react-loading';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import Android from '~/assets/Android.png';
import Apple from '~/assets/Apple.png';

import BackButton from '~/components/BackButton';
import Header from '~/components/Header';
import OrangeBanner from '~/components/OrangeBanner';
import OrangeInput from '~/components/OrangeInput';
import ResumeReservation from '~/components/ResumeReservation';

import { apiLogin } from '~/services/api';
import history from '~/services/history';
import { signUpRequest } from '~/store/modules/auth/actions';

import * as S from './styles';

const SignUp = () => {
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const reservation = useSelector((state) => state.reservation);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const dispatch = useDispatch();

  async function handleSubmit() {
    setLoading(true);
    try {
      const response = await apiLogin.post('/driver/website/signup', {
        name,
        email,
        password,
        phone: phone.replace(/([^\d])+/gim, ''),
      });

      dispatch(signUpRequest(response.data.user));
      setLoading(false);
      history.push('/ReservaDeVaga/ConfirmarCadastro');
    } catch (err) {
      setLoading(false);
      setError(err.response.data.error);
      setShowError(true);
    }
  }

  let disabled = true;

  if (
    name !== '' &&
    email !== '' &&
    phone !== '' &&
    phone.length > 11 &&
    password === confirmPassword &&
    password !== '' &&
    confirmPassword !== ''
  ) {
    disabled = false;
  }

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  return (
    <>
      {/* header menu */}
      <Header
        type="forYou"
        active="forYou"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />

      {isSmallScreen ? (
        <>
          <S.DivMobile>
            <h1>
              Para reservar vaga, seja rápida ou agendada, baixe agora o app
              Valeti!
            </h1>
            <a
              href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Android} alt="Android" />
            </a>
            <br />
            <a
              href="https://apps.apple.com/br/app/valeti/id1069081218"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Apple} alt="iOS" />
            </a>
            <br />
          </S.DivMobile>
        </>
      ) : (
        <>
          {/* orange title start */}
          <OrangeBanner title="CADASTRE-SE E GARANTA JÁ SUA RESERVA!" />
          {/* orange title end */}

          {/* resume reservation start */}
          <ResumeReservation reservation={reservation} />
          {/* resume reservation end */}

          <S.DivSignUp>
            <BackButton link="/login" />

            <S.HeaderSignUp>Cadastro</S.HeaderSignUp>
            <S.SubHeaderSignUp>Dados pessoais</S.SubHeaderSignUp>

            <S.DivGroupInfos>
              <S.LabelInputs>Nome Completo</S.LabelInputs>
              <OrangeInput
                required
                label="Nome completo"
                variant="outlined"
                value={name}
                onChange={(event) => setName(event.target.value)}
                fullWidth={true}
              />

              <S.LabelInputs>E-mail</S.LabelInputs>
              <OrangeInput
                required
                label="E-mail completo"
                variant="outlined"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                fullWidth={true}
              />

              <S.LabelInputs>Celular</S.LabelInputs>
              <InputMask
                mask="(99) 9 9999-9999"
                maskChar=""
                onChange={(event) => setPhone(event.target.value)}
                value={phone}
                name="phone"
              >
                {() => (
                  <OrangeInput
                    required
                    variant="outlined"
                    label="Celular"
                    name="phone"
                    type="text"
                  />
                )}
              </InputMask>

              <S.LabelInputs>Insira sua senha</S.LabelInputs>
              <OrangeInput
                required
                label="Insira sua senha"
                variant="outlined"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="A senha deve ter 8 dígitos entre letras e números"
                fullWidth={true}
              />

              <S.LabelInputs>Confirme sua senha</S.LabelInputs>
              <OrangeInput
                required
                label="Confirme sua senha"
                variant="outlined"
                type="password"
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
                placeholder="Confirme a senha digitada"
                fullWidth={true}
              />
            </S.DivGroupInfos>

            <S.ButtonSignUp disabled={disabled} onClick={handleSubmit}>
              {loading ? (
                <ReactLoading type="spin" color="#fff" height={40} width={40} />
              ) : (
                <>AVANÇAR</>
              )}
            </S.ButtonSignUp>
          </S.DivSignUp>

          {/* ERRO SE HOUVER */}
          <Dialog
            open={showError}
            onClose={() => setShowError(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Erro ao realizar login!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {error}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowError(false)}
                color="primary"
                autoFocus
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default SignUp;
