export function addToCartRequest(id) {
  return {
    type: '@cart/ADD_REQUEST',
    id,
  };
}

export function qtdRequest(value) {
  return {
    type: '@cart/QTD_REQUEST',
    value,
  };
}

export function addToCartSuccess(product) {
  return {
    type: '@cart/ADD_SUCCESS',
    product,
  };
}

export function qtdSuccess(product) {
  return {
    type: '@cart/QTD_SUCCESS',
    product,
  };
}

export function removeAditionalInCartRequest(id) {
  return {
    type: '@cart/REMOVE_ADITIONAL_CART_REQUEST',
    id,
  };
}

export function removeAditionalInCartSuccess(product) {
  return {
    type: '@cart/REMOVE_ADITIONAL_SUCCESS',
    product,
  };
}

export function removeFromCart(id) {
  return {
    type: '@cart/REMOVE',
    id,
  };
}

export function updateAmountRequest(id, amount) {
  return {
    type: '@cart/UPDATE_AMOUNT_REQUEST',
    id,
    amount,
  };
}

export function cleanCart() {
  return {
    type: '@cart/CLEAN_CART',
  };
}

export function updateDiscount(percentage, value) {
  return {
    type: '@cart/UPDATE_DISCOUNT',
    percentage,
    value,
  };
}
