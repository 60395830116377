import styled from 'styled-components';
import device from '~/util/device';

export const DivHirePlanMobile = styled.div`
  @media ${device.mobileL} {
    background-color: #fff;
    width: 100%auto;
    height: 100%auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 6%;

    h1 {
      color: #293f4a;
    }

    img {
      width: 150px;
      height: 70px;
    }

    a {
      color: #293f4a;
      text-decoration: underline;
    }
  }
`;

export const DivPayment = styled.div`
  width: 80%;
  float: left;
`;

export const DivGroupInfos = styled.div`
  width: 80%;
  position: relative;
  margin-left: 5%;
  margin-top: 5%;
`;

export const HeaderPaymentData = styled.h1`
  color: #222;
  font-size: 44px;
  line-height: 60px;
`;

export const CompanyData = styled.p`
  color: #222;
  font-size: 28px;
  line-height: 60px;
  position: relative;
  font-style: normal;
  font-weight: 300;
  margin-top: 20px;
`;

export const LabelInputs = styled.p`
  color: #222;
  font-size: 16px;
  line-height: 20px;
  margin-top: 2%;
`;

export const ErrorDiv = styled.div`
  position: relative;
  margin-right: 33.5%;
  margin-top: 5%;
  float: right;
  height: 72px;
  width: 312px;
  font-size: 20px;
`;

export const CheckboxDiv = styled.div`
  margin-left: 40%;
  margin-top: 5%;

  input {
    margin-left: 12%;
  }

  button {
    margin-right: 69%;
  }

  @media only screen and (max-width: 1480px) {
    button {
      margin-right: 59%;
    }
  }
`;

export const ButtonBuy = styled.button`
  position: relative;
  margin-top: 5%;
  float: right;
  height: 72px;
  width: 312px;
  font-size: 24px;
  background-color: #ff4d00;
  color: white;
  border: none;
  border-radius: 10px;
  margin-bottom: 5%;

  :disabled {
    background-color: #ddd;
    color: #ff4d00;
  }

  @media only screen and (max-width: 1480px) {
    position: relative;
    margin-top: 10%;
    float: right;
    height: 72px;
    width: 312px;
    font-size: 24px;
    background-color: #ff4d00;
    color: white;
  }
`;

export const ButtonCupom = styled.button`
  margin-top: 1%;
  height: 56px;
  width: 232px;
  font-size: 24px;
  background-color: #ff4d00;
  color: white;
  border: none;
  border-radius: 10px;

  :disabled {
    background-color: #ddd;
    color: #ff4d00;
  }
`;

export const CheckBoxStyle = styled.div`
  margin-right: 25.5%;

  @media only screen and (max-width: 1480px) {
    position: relative;
    margin-right: 25.5%;
    margin-top: 10%;
  }
`;

export const InputCupom = styled.input`
  height: 52px;
  width: 232px;
  z-index: 0;
  color: #ff4b00;
  border: 1px solid #ff4b00;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${device.laptop} {
    width: 320px;
    left: 28px;
    position: relative;
    justify-content: center;
    font-size: 18px;
    max-width: 380px;
  }
  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
  }

  :-ms-input-placeholder {
    text-align: center;
  }
`;
