import styled from 'styled-components';
import device from '~/util/device';

export const DivMobile = styled.div`
  @media ${device.mobileL} {
    background-color: #fff;
    width: 100%auto;
    height: 100%auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 6%;

    h1 {
      color: #293f4a;
    }

    img {
      width: 150px;
      height: 70px;
    }

    a {
      color: #293f4a;
      text-decoration: underline;
    }
  }
`;

export const DivLogin = styled.div`
  width: 100%;
  background-color: #fafafa;
  padding-bottom: 5%;
`;

export const DivLoading = styled.div`
  margin-left: 48%;
`;

export const HeaderAlreadyExist = styled.h1`
  color: #222;
  font-size: 32px;
  line-height: 60px;
  position: relative;
  margin-top: 2%;
  margin-left: 3%;
`;

export const SubHeaderAlreadyExist = styled.p`
  position: relative;
  margin-top: 2%;
  margin-left: 3%;
  opacity: 0.6;
`;

export const DivCardFirstStep = styled.div`
  width: 85%;
  height: 625px;
  position: relative;
  margin-top: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-left: 60%;
  background-color: #fff;
  justify-content: center;
  align-items: center;

  @media ${device.laptopL} {
    width: 90%;
    height: 615px;
    position: relative;
    margin-top: 5%;
    margin-left: 55%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
  }
`;

export const DivCardSecondStep = styled.div`
  width: 85%;
  height: 705px;
  position: relative;
  margin-top: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-left: 60%;
  background-color: #fff;
  justify-content: center;
  align-items: center;

  @media ${device.laptopL} {
    width: 90%;
    height: 695px;
    position: relative;
    margin-top: 5%;
    margin-left: 55%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
  }
`;

export const DivCardThirdStep = styled.div`
  width: 85%;
  height: 970px;
  position: relative;
  margin-top: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  margin-left: 60%;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media ${device.laptopL} {
    width: 90%;
    height: 960px;
    position: relative;
    margin-top: 5%;
    margin-left: 55%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px;
  }
`;

export const ButtonNext = styled.button`
  width: 91%;
  height: 60px;
  position: relative;
  margin-left: 3.5%;
  margin-top: 3%;
  font-size: 22px;
  color: white;
  background-color: #ff4b00;
  border: none;
  border-radius: 10px;

  :hover {
    color: #ff4b00;
    background-color: white;
    border: 1px solid #ff4b00;
  }

  :disabled {
    color: #ff4b00;
    background-color: #ccc;
  }
`;

export const MsgInvalidCpf = styled.span`
  color: red;
  font-weight: bold;
  margin-left: 3.5%;
`;

export const LabelConfirmBlack = styled.h3`
  color: #000;
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  justify-content: center;
`;

export const LabelConfirm = styled.h3`
  color: #ff4b00;
  font-size: 18px;
  align-items: center;
  justify-content: center;
`;
