import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Android from '~/assets/Android.png';
import Apple from '~/assets/Apple.png';
import MockupValeti from '~/assets/MockupValeti.png';

import * as S from './styles';
import './styles.css';

const KnowOrangeApp = ({ isSmallScreen, platform }) => {
  return (
    <>
      {!isSmallScreen ? (
        <S.DivKnowOrangeApp>
          <Grid className="gridKnow" container>
            <Grid item xs={3}>
              <S.TitleDesktop> CONHEÇA NOSSO APP</S.TitleDesktop>
              <S.SubtitleDesktop>
                {' '}
                Um app pensado para facilitar sua procura por vagas e facilitar
                quando você mais precisa.
              </S.SubtitleDesktop>
              <Button className="buttonKnow" variant="contained">
                SAIBA MAIS
              </Button>
            </Grid>

            <Grid item xs={3}>
              <S.ImageMockupDesktop
                width={230}
                src={MockupValeti}
                alt="celular"
              />
            </Grid>

            <Grid item xs={3}>
              <S.DescriptionTitle>
                {' '}
                Baixe agora e descubra uma nova forma de estacionar!{' '}
              </S.DescriptionTitle>
              <S.DivDownloadsBadges>
                <a
                  href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={Android} alt="Android" />
                </a>
                <a
                  href="https://apps.apple.com/br/app/valeti/id1069081218"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="applePosition" src={Apple} alt="iOS" />
                </a>
              </S.DivDownloadsBadges>
            </Grid>
          </Grid>
        </S.DivKnowOrangeApp>
      ) : (
        <S.DivknowBodyMobile>
          <S.Title> CONHEÇA NOSSO APP</S.Title>
          <S.Subtitle>
            {' '}
            Um app pensado para facilitar sua procura por vagas e facilitar
            quando você mais precisa.
          </S.Subtitle>
          <S.ImageMockup src={MockupValeti} alt="celular" />
          <S.Description>
            {' '}
            Baixe agora e descubra uma nova forma de estacionar!{' '}
          </S.Description>
          {platform !== 'Android' ? (
            <a
              href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.ImageDownloadApp src={Android} alt="Android" />
            </a>
          ) : (
            <a
              href="https://apps.apple.com/br/app/valeti/id1069081218"
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.ImageDownloadApp src={Apple} alt="iOS" />
            </a>
          )}
        </S.DivknowBodyMobile>
      )}
    </>
  );
};

export default KnowOrangeApp;
