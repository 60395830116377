import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Card, Grid } from '@material-ui/core';

import { formatPrice } from '~/util/format';

import * as S from './styles';
import './styles.css';

export default function Planos({ page }) {
const cart = useSelector((state) => state.cart);
  let total = cart.totalCartValue;
  let disabled = true;
  let descontoTotal = cart.totalDiscountValue;

  if (total > 0 && cart.totalValets > 0) {
    disabled = false;
  }

  return (
    <S.DivCartPlan>
      <S.Header>Seu carrinho</S.Header>

      <Card className="selectedPlanos">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={10}
        >
          <Grid item xs>
            {cart.step1.description === '' ? (
              <S.SelectedItem>SELECIONE UMA OPÇÃO</S.SelectedItem>
            ) : (
              <S.SelectedItem>{cart.step1.description}</S.SelectedItem>
            )}
          </Grid>
        </Grid>
      </Card>

      <Card className="selectedPlanos">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={10}
        >
          <Grid item xs>
            <S.SelectedItem>
              ESTACIONAMENTOS
              <br />
              Quantidade:
              <S.QtdValet>{cart.totalValets > 0 ? cart.totalValets : ' '}</S.QtdValet>
            </S.SelectedItem>
          </Grid>
        </Grid>
      </Card>

      <Card className="selectedPlanos">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={10}
        >
          <Grid item xs>
            {cart.step3.description === '' ? (
              <S.SelectedItem>
                SELECIONE UM PLANO <br />
                PROMOCIONAL
              </S.SelectedItem>
            ) : (
              <S.SelectedItem>{cart.step3.description}</S.SelectedItem>
            )}
          </Grid>
        </Grid>
      </Card>
{/*
      {cart[3].description !== null ? (
        <Card className="selectedPlanosAditional">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={10}
          >
            <Grid item xs>
              {' '}
              <S.SelectedItemAditional>
                Serviço Adicional <br />
                <strong>{cart[3].description}</strong>
              </S.SelectedItemAditional>
            </Grid>
          </Grid>
        </Card>
      ) : null} */}

      {/* {cart[4].description !== null ? (
        <Card className="selectedPlanosAditional">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={10}
          >
            <Grid item xs>
              {' '}
              <S.SelectedItemAditional>
                Serviço Adicional <br />
                <strong>{cart[4].description}</strong>
              </S.SelectedItemAditional>
            </Grid>
          </Grid>
        </Card>
      ) : null} */}

      <Card className="paymentcardClass">
        <S.DiscountValue>Desconto:</S.DiscountValue>{' '}
        <S.DiscountCheck>{formatPrice(descontoTotal)}</S.DiscountCheck>
        <S.TotalValue>Total:</S.TotalValue>{' '}
        <S.TotalCheck>{formatPrice(total)}</S.TotalCheck>
      </Card>

      {page === 1 && (
        <Link to="/Pagamento">
          <S.PaymentButton disabled={disabled} className="paymentbuttonClass">
            CADASTRO E PAGAMENTO
          </S.PaymentButton>
        </Link>
      )}
    </S.DivCartPlan>
  );
}
