import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from '~/components/Header';
import OrangeBanner from '~/components/OrangeBanner';

import {
  DivHirePlanMobile,
  DivPaymentSuccess,
  TextPaymentSuccess,
  HeaderPaymentSuccess,
  ButtonBackToHome,
} from './styles';

const PaymentPlanSuccess = () => {
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  return (
    <>
      {/* header menu */}
      <Header
        type="forYourCompany"
        active="forYourCompany"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />

      {isSmallScreen ? (
        <>
          <DivHirePlanMobile>
            <h1>
              Para contratar um plano acesse esse link em um notebook ou
              Computador! <br />
              <br />
            </h1>
            <br />
            <br />
          </DivHirePlanMobile>
        </>
      ) : (
        <>
          {/* orange title start */}
          <OrangeBanner title="PAGAMENTO E CADASTRO REALIZADO COM SUCESSO" />
          {/* orange title end */}

          <DivPaymentSuccess>
            <HeaderPaymentSuccess>
              Seu pagamento foi realizado!
            </HeaderPaymentSuccess>

            <TextPaymentSuccess>
              Você irá receber um e-mail com as informações da sua compra <br />{' '}
              e dados de acesso a nossa plataforma em instantes.
            </TextPaymentSuccess>
            <TextPaymentSuccess>
              Caso precise de ajuda, contate nosso suporte através do e-mail:
            </TextPaymentSuccess>
            <TextPaymentSuccess>
              <strong>suporte@valeti.com.br</strong>
            </TextPaymentSuccess>

            <Link to="/">
              <ButtonBackToHome>VOLTAR PARA A HOME</ButtonBackToHome>
            </Link>
          </DivPaymentSuccess>
        </>
      )}
    </>
  );
};

export default PaymentPlanSuccess;
