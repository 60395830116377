import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core/';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';

import Android from '~/assets/Android.png';
import Apple from '~/assets/Apple.png';

import BackButton from '~/components/BackButton';
import Header from '~/components/Header';
import OrangeBanner from '~/components/OrangeBanner';
import OrangeInput from '~/components/OrangeInput';
import ResumeReservation from '~/components/ResumeReservation';

import {
  addVehicleRequest,
  bookParkingSpace,
  getVehiclesRequest,
} from '~/store/modules/user/actions';
import history from '~/services/history';

import * as S from './styles';

const ChooseReservationCar = () => {
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const user = useSelector((state) => state.user);
  const reservation = useSelector((state) => state.reservation);
  const dispatch = useDispatch();
  const [showDialogAddVehicle, setShowDialogAddVehicle] = useState(false);
  const [plate, setPlate] = useState('');
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [conectCarEnabled, setConectCarEnabled] = useState(false);
  const [showDocumentConectCar, setShowDocumentConectCar] = useState(false);
  const [conectCarDocument, setConectCarDocument] = useState('');
  const [vehicleId, setVehicleId] = useState(null);
  let disabled = true;
  let disabledDialog = true;

  useEffect(() => {
    dispatch(getVehiclesRequest());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const handleSubmitAddVehicle = () => {
    const form = {
      id: null,
      plate,
      brand,
      model,
      conectCarEnabled,
      conectCarDocument,
    };

    dispatch(addVehicleRequest(form));
    setShowDialogAddVehicle(false);
  };

  const handleAddVehicle = () => {
    showDialogAddVehicle === false
      ? setShowDialogAddVehicle(true)
      : setShowDialogAddVehicle(false);
  };

  const handleChangeChecked = () => {
    setConectCarEnabled(!conectCarEnabled);
    setShowDocumentConectCar(!showDocumentConectCar);
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setVehicleId(Number(value));
  };

  const handleReservation = () => {
    if (user.vehicles.length === 1) {
      dispatch(
        bookParkingSpace(
          reservation.id,
          user.vehicles[0].userVehicleId,
          onReservation
        )
      );
    } else {
      dispatch(bookParkingSpace(reservation.id, vehicleId, onReservation));
    }
  };

  function onReservation(success) {
    if (success) {
      history.push('/ReservaDeVaga/Sucesso');
    }
  }

  if (plate !== '' && plate.length === 8 && brand !== '' && model !== '') {
    disabledDialog = false;
  }

  if (vehicleId !== null) {
    disabled = false;
  }

  return (
    <>
      {/* header menu */}
      <Header
        type="forYou"
        active="reservation"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />
      {isSmallScreen ? (
        <>
          {/* div mobile start */}
          <S.DivReservationMobile>
            <h1>
              Para reservar vaga, seja rápida ou agendada, baixe agora o app
              Valeti!
            </h1>
            <a
              href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Android} alt="Android" />
            </a>
            <br />
            <a
              href="https://apps.apple.com/br/app/valeti/id1069081218"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Apple} alt="iOS" />
            </a>
            <br />
          </S.DivReservationMobile>
          {/* div mobile end */}
        </>
      ) : (
        <>
          {/* orange title start */}
          <OrangeBanner title="SELECIONE O CARRO DE SUA RESERVA" />
          {/* orange title end */}

          <ResumeReservation reservation={reservation} />
          <S.DivChooseCar>
            {user.profile && <BackButton link="/ReservaDeVaga" />}

            <S.DivInfos>
              {user.profile ? (
                <>
                  <S.HeaderName>
                    {' '}
                    {user.profile ? `Olá, ${user.profile.name}` : 'F'}
                  </S.HeaderName>

                  {user.vehicles.length === 0 ? (
                    <>
                      <S.TextTwo>
                        Você não tem carros cadastrados. Adicione um carro
                        abaixo:{' '}
                      </S.TextTwo>
                      <S.TextThree onClick={handleAddVehicle}>
                        {' '}
                        <AddCircleOutlineIcon /> Clique aqui e adicione um
                        carro!
                      </S.TextThree>
                    </>
                  ) : user.vehicles.length === 1 ? (
                    <>
                      <S.TextTwo>
                        O carro para essa reserva será o{' '}
                        <strong>{user.vehicles[0].plate}</strong>
                      </S.TextTwo>
                      <S.TextThree onClick={handleAddVehicle}>
                        {' '}
                        <AddCircleOutlineIcon /> Clique aqui e cadastre outro
                        carro!
                      </S.TextThree>
                    </>
                  ) : (
                    <>
                      <S.TextTwo>
                        Selecione o carro que irá fazer parte dessa reserva!
                      </S.TextTwo>
                      {user.vehicles.map((vehicle) => (
                        <>
                          <RadioGroup
                            aria-label="vehicleId"
                            name="vehicleId"
                            value={vehicleId}
                            onChange={handleChange}
                          >
                            <S.TextRadioButton>
                              <FormControlLabel
                                value={vehicle.userVehicleId}
                                control={<Radio />}
                                label=""
                              />
                              <DirectionsCarIcon /> {vehicle.plate}
                            </S.TextRadioButton>
                          </RadioGroup>
                        </>
                      ))}
                      <S.TextThree onClick={handleAddVehicle}>
                        {' '}
                        <AddCircleOutlineIcon /> Clique aqui e adicione um
                        carro!
                      </S.TextThree>
                    </>
                  )}

                  {user.vehicles.length === 1 ? (
                    <S.ButtonConfirm onClick={handleReservation}>
                      CONFIRMAR RESERVA
                    </S.ButtonConfirm>
                  ) : (
                    <S.ButtonConfirm
                      onClick={handleReservation}
                      disabled={disabled}
                    >
                      CONFIRMAR RESERVA
                    </S.ButtonConfirm>
                  )}
                </>
              ) : (
                <Link to="/ReservaDeVaga">
                  <S.ButtonConfirm>RESERVE SUA VAGA!</S.ButtonConfirm>
                </Link>
              )}
            </S.DivInfos>
          </S.DivChooseCar>
          <Dialog
            open={showDialogAddVehicle}
            onClose={() => setShowDialogAddVehicle(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Cadastro de veículo
            </DialogTitle>
            <DialogContent>
              <InputMask
                style={{ width: 320, marginBottom: 20 }}
                mask="aaa-9*999"
                maskChar=""
                onChange={(event) => setPlate(event.target.value.toUpperCase())}
                value={plate}
                name="plate"
              >
                {() => (
                  <OrangeInput
                    required
                    id="outlined-basic"
                    variant="outlined"
                    label="ABC-1234"
                    name="plate"
                    type="text"
                    fullWidth={true}
                  />
                )}
              </InputMask>
              <br />
              <br />
              <OrangeInput
                required
                value={brand}
                type="text"
                onChange={(event) => setBrand(event.target.value)}
                label="Marca"
                variant="outlined"
                fullWidth={true}
              />
              <br />
              <br />
              <OrangeInput
                required
                value={model}
                type="text"
                onChange={(event) => setModel(event.target.value)}
                label="Modelo"
                variant="outlined"
                fullWidth={true}
              />
              <br />
              <br />
              <DialogContentText>Possui ConectCar</DialogContentText>
              <Checkbox
                checked={conectCarEnabled}
                onChange={handleChangeChecked}
                value={1}
              />
              <br />
              {conectCarEnabled && (
                <OrangeInput
                  required
                  value={conectCarDocument}
                  type="text"
                  onChange={(event) => setConectCarDocument(event.target.value)}
                  label="Documento cadastrado na ConectCar"
                  variant="outlined"
                  className="textfieldDialog"
                />
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowDialogAddVehicle(false)}>
                Fechar
              </Button>
              <Button
                disabled={disabledDialog}
                onClick={handleSubmitAddVehicle}
                autoFocus
              >
                Adicionar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default ChooseReservationCar;
