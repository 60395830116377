import produce from 'immer';

const INITIAL_STATE = {
  profile: null,
  vehicles: [],
  cards: [],
  loading: false,
  ticket: null,
  reservations: [],
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/SIGN_IN_SUCCESS': {
        draft.profile = action.payload.user;
        break;
      }
      case '@user/ADD_CARD_SUCCESS': {
        const { card } = action.payload;

        // Remove any card from being favorite if the saved one is the new favorite
        if (card.favorite) {
          draft.cards.forEach((savedCard) => {
            savedCard.favorite = false;
          });
        }

        draft.cards.push(action.payload.card);
        break;
      }
      case '@user/UPDATE_CARD_SUCCESS': {
        const { card } = action.payload;

        // Remove any card from being favorite if the saved one is the new favorite
        if (card.favorite) {
          draft.cards.forEach((savedCard) => {
            savedCard.favorite = false;
          });
        }

        draft.cards = draft.cards.map((c) => (c.id === card.id ? card : c));
        break;
      }
      case '@user/REMOVE_CARD_SUCCESS': {
        const { cardId } = action.payload;
        draft.cards = draft.cards.filter((c) => c.id !== cardId);
        break;
      }
      case '@user/ADD_VEHICLE_SUCCESS': {
        draft.vehicles.push(action.payload.vehicle);
        break;
      }
      case '@user/UPDATE_VEHICLE_SUCCESS': {
        const { vehicle } = action.payload;

        const vehicles = draft.vehicles.filter((v) => v.id !== vehicle.id);
        vehicles.push(vehicle);
        draft.vehicles = vehicles;
        break;
      }
      case '@user/REMOVE_VEHICLE_SUCCESS': {
        const { id } = action.payload;
        draft.vehicles = draft.vehicles.filter((v) => v.id !== id);
        break;
      }
      case '@user/GET_CARDS_SUCCESS': {
        draft.cards = action.payload.cards;
        break;
      }
      case '@user/GET_VEHICLES_SUCCESS': {
        draft.vehicles = action.payload.vehicles;
        break;
      }
      case '@user/UPDATE_PROFILE_SUCCESS': {
        draft.profile = action.payload.profile;
        break;
      }
      case '@user/STOP_LOADING': {
        draft.loading = false;
        break;
      }
      case '@user/UPDATE_PROFILE_REQUEST':
      case '@user/GET_ACTIVE_TICKET_SUCCESS': {
        draft.ticket = action.payload.ticket;
        break;
      }
      case '@user/GET_RESERVATIONS_SUCCESS': {
        draft.reservations = action.payload.reservations;
        break;
      }
      case '@user/CANCEL_RESERVATION_SUCCESS': {
        draft.reservations = draft.reservations.filter(
          (reserv) => reserv.id !== action.payload.reservationId
        );
        break;
      }
      case '@user/ADD_VEHICLE_REQUEST':
      case '@user/UPDATE_VEHICLE_REQUEST':
      case '@user/REMOVE_VEHICLE_REQUEST':
      case '@user/ADD_CARD_REQUEST':
      case '@user/UPDATE_CARD_REQUEST':
      case '@user/REMOVE_CARD_REQUEST':
      case '@user/BOOK_TICKET_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.profile = null;
        draft.vehicles = [];
        draft.cards = [];
        draft.ticket = null;
        break;
      }
      default:
    }
  });
}
