/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import device from '~/util/device';

export const Image = styled.img`
  margin-top: 35px;
  margin-left: 60px;

  @media ${device.mobileS} {
    width: 360px;
    margin-left: 1px;
    margin-top: 25px;
  }

  @media ${device.mobileM} {
    width: 360px;
    margin-left: 1px;
    margin-top: 25px;
  }

  @media ${device.mobileL} {
    width: 360px;
    margin-left: 1px;
    margin-top: 25px;
  }
`;

export const DivCarousel = styled.div`
  background-color: #fff;

  @media (min-width: 2400px) {
    width: 70%;
    margin-left: 15%;
  }
`;
