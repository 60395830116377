import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import partnerOne from '~/assets/partnerOne.png';
import partnerThree from '~/assets/partnerThree.png';
import partnerTwo from '~/assets/partnerTwo.png';

import {
  DivBeaPartner,
  SubTitle,
  ImagePosition,
  ImagePositionTwo,
  ImagePositionThree,
} from './styles';
import './styles.css';

const BeaPartner = () => (
  <DivBeaPartner>
    <br />
    SEJA UM PARCEIRO VALETI
    <br />
    <br />
    <SubTitle>
      SE VOCÊ TEM UM ESTACIONAMENTO OU VALET, TEMOS A SOLUÇÃO IDEAL PARA VOCÊ:
    </SubTitle>
    <Grid className="gridPartner" container>
      <Grid className="textPartner" item sm={3} md={3} lg={3}>
        Valeti é uma plataforma que conecta motoristas, estacionamentos e
        estabelecimentos, oferecendo uma forma segura e cômoda de estacionar,
        pagar e pedir seu veículo em Valets e Estacionamentos que contam com uma
        plataforma segura e ágil para sua operação.
      </Grid>

      <Grid item sm={3} md={3} lg={3}>
        <ImagePositionTwo src={partnerTwo} alt="images" />
        <ImagePosition src={partnerOne} alt="images" />
      </Grid>

      <Grid item sm={3} md={3} lg={3}>
        <ImagePositionThree src={partnerThree} alt="images" />
        <Link to="/ParaSuaEmpresa">
          <Button variant="contained" className="knowMoreButton">
            SAIBA MAIS
          </Button>
        </Link>
      </Grid>
    </Grid>
  </DivBeaPartner>
);

export default BeaPartner;
