import styled from 'styled-components';
import device from '~/util/device';

export const DivReservationMobile = styled.div`
  @media ${device.mobileL} {
    background-color: #fff;
    width: 100%auto;
    height: 100%auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 6%;

    h1 {
      color: #293f4a;
    }

    img {
      width: 150px;
      height: 70px;
    }
  }
`;

export const DivReservation = styled.div`
  position: static;
  margin-top: 6.3%;
  height: 100%;

  @media only screen and (max-width: 1520px) {
    position: static;
    margin-top: 8%;
  }

  @media only screen and (min-width: 2400px) {
    position: static;
    margin-top: 2%;
  }
`;

export const LeftDivBlack = styled.div`
  width: 30%;
  max-height: fit-content;
  background-color: #222;
  float: left;
  overflow-y: scroll;
  height: 100%;
`;

export const DivMap = styled.div`
  width: 70%;
  background-color: rgb(189, 189, 189);
  float: right;
  height: 100%;
`;

export const InputSearchAddress = styled.input`
  width: 80%;
  height: 60px;
  position: relative;
  margin-left: 9%;
  margin-top: 6%;
  background-color: #fff;
  border: solid 1px #fff;
  border-radius: 7px;
  z-index: 0;
`;

export const ButtonSearchAddress = styled.button`
  width: 80%;
  height: 60px;
  position: relative;
  margin-bottom: 5%;
  margin-top: 2%;
  margin-left: 9%;
  color: #fff;
  background-color: #ff4b00;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.17px;
  line-height: 20px;
  text-align: center;
  border: none;
  border-radius: 7px;

  :hover {
    color: #ff4b00;
    background-color: #fff;
  }

  :disabled {
    color: #ff4b00;
    background-color: #ccc;
  }
`;

export const NameValet = styled.h1`
  position: relative;
  margin-top: 10%;
  margin-left: 9%;
  font-size: 26px;
  font-weight: 700;
  color: #fff;
  letter-spacing: -0.17px;
  line-height: 35px;
`;

export const ButtonReserveNow = styled.button`
  position: relative;
  margin-bottom: 10%;
  margin-top: 2%;
  margin-left: 8.5%;
  height: 51px;
  width: 192.96px;
  color: #fff;
  background-color: #ff4b00;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.17px;
  line-height: 20px;
  text-align: center;
  border: none;
  border-radius: 10px;

  :hover {
    color: #ff4b00;
    background-color: #fff;
  }
`;

export const DetailReservation = styled.p`
  font-size: 14px;
  color: #fff;
`;

export const DivDetailReservation = styled.div`
  height: 100px;
  width: 100%;
  border-top: 2px solid #ff4b00;
`;
