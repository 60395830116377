import React from 'react';

import {
  DivResumeReservation,
  Header,
  Description,
  BiggerDescription,
} from './styles';

const ResumeReservation = ({ reservation }) => {
  return (
    <DivResumeReservation>
      {reservation.id ? (
        <>
          <Header>Resumo da reserva:</Header>
          <Description>{reservation.valet}</Description>
          <Description>{reservation.address}</Description>
          {reservation.fixedPrice > 0 && (
            <Description>Preço fixo: R$ {reservation.fixedPrice}</Description>
          )}
          <BiggerDescription>
            Complete o cadastro para finalizar a sua reserva
          </BiggerDescription>
        </>
      ) : (
        <Header>Faça sua reserva!</Header>
      )}
    </DivResumeReservation>
  );
};

export default ResumeReservation;
