import React, { useEffect, useRef, useState } from 'react';

import { apiGoogle } from '~/services/api';
import history from '~/services/history';

import { Input, Button } from './styles';

export const InputSearchAddressToReservar = () => {
  const [query, setQuery] = useState('');
  const autoCompleteRef = useRef(null);
  let autoComplete;

  useEffect(() => {
    handleScriptLoad(setQuery, autoCompleteRef);
    // eslint-disable-next-line
  }, []);

  function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { types: ['address'], componentRestrictions: { country: 'br' } }
    );
    autoComplete.setFields(['formatted_address', 'geometry']);
    autoComplete.addListener('place_changed', () =>
      handlePlaceSelect(updateQuery)
    );
  }

  async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
  }

  // searches for new locations
  const updateCoordinates = async (e) => {
    e.preventDefault();

    const encodedAddress = encodeURI(query);
    try {
      const response = await apiGoogle.post(
        `geocode/json?address=${encodedAddress}&key=AIzaSyCkxUmg8jY83rCujpf2giYlqgn3Mok7NlI`
      );

      const lat = response.data.results[0].geometry.location.lat;
      const lng = response.data.results[0].geometry.location.lng;

      history.push(`/ReservaDeVaga?lat=${lat}&lng=${lng}`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Input
        ref={autoCompleteRef}
        onChange={(event) => setQuery(event.target.value)}
        placeholder="Digite um endereço"
        value={query}
      />
      <Button
        onClick={updateCoordinates}
        className="ButtonCarrosselVoce"
        variant="contained"
      >
        Reservar
      </Button>
    </>
  );
};

export default InputSearchAddressToReservar;
