import styled from 'styled-components';
import device from '~/util/device';

export const DivCartPlan = styled.div`
  background-color: #222;
  width: 20%;
  max-width: 20%;
  padding-bottom: 15px;
  position: fixed;
  right: 0;
  border-radius: 5px;
  height: 100%;
`;

export const Header = styled.h2`
  color: #fff;
  margin-left: 2%;
  font-size: 18px;
`;

export const SelectedItem = styled.p`
  color: #222;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  position: relative;
  bottom: 20px;
  left: 10px;
  margin-top: 5%;
`;

export const SelectedItemAditional = styled.p`
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  position: relative;
  bottom: 20px;
  left: 10px;
  margin-top: 5%;
`;

export const QtdValet = styled.p`
  background-color: #222;
  color: #fff;
  width: 15%;
  text-align: center;
  position: relative;
  float: right;
  right: 20px;
`;

export const TotalValue = styled.p`
  position: relative;
  bottom: 36px;
`;

export const DiscountValue = styled.p`
  position: relative;
  top: 10px;
`;

export const TotalCheck = styled.p`
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  text-align: right;
  position: relative;
  bottom: 80px;
`;

export const DiscountCheck = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  text-align: right;
  position: relative;
  bottom: 37px;
`;

export const PaymentButton = styled.button`
  height: 65px;
  width: 310px;
  background-color: #ff4b00;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.33px;
  line-height: 20px;
  text-align: center;
  position: relative;
  margin-top: 50px;
  margin-left: 32px;
  border: none;
  border-radius: 10px;

  :disabled {
    background-color: #dddddd;
    color: #ff4b00;
  }

  :hover {
    background-color: #fff;
    color: #ff4b00;
  }

  @media ${device.laptopL} {
    width: 210px;
  }
`;
