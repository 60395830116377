import styled from 'styled-components';
import device from '~/util/device';

export const DivMobile = styled.div`
  @media ${device.mobileL} {
    background-color: #fff;
    width: 100%auto;
    height: 100%auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 6%;

    h1 {
      color: #293f4a;
    }

    img {
      width: 150px;
      height: 70px;
    }

    a {
      color: #293f4a;
      text-decoration: underline;
    }
  }
`;

export const DivReservationSuccess = styled.div`
  width: 80%;
  background-color: white;
  float: left;
`;

export const DivInfos = styled.div`
  position: relative;
  margin-top: 6%;
  margin-left: 6%;
`;

export const HeaderText = styled.h1`
  color: #222;
  font-size: 44px;
  line-height: 60px;
`;

export const SubHeader = styled.h2`
  color: #222;
  font-size: 24px;
  line-height: 33px;
`;

export const ButtonConfirm = styled.button`
  height: 72px;
  width: 362px;
  border-radius: 5px;
  background-color: #ff4d00;
  color: white;
  font-size: 20px;
  position: relative;
  margin-top: 3%;
  border: none;
  border-radius: 10px;

  :hover {
    background-color: white;
    color: #ff4d00;
    border: 1px solid #ff4d00;
  }

  :disabled {
    background-color: #bbb;
    color: #ff4d00;
  }
`;

export const DivInfosReservation = styled.div`
  width: 20%;
  background-color: #222;
  float: right;
`;

export const DivContentReservation = styled.div`
  position: relative;
  margin-left: 10%;
  margin-top: 28%;
`;

export const InfoTextOne = styled.p`
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
`;

export const InfoTextTwo = styled.p`
  color: #fff;
  font-size: 24px;
  line-height: 30px;
`;
