import React, { useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core/';
import InputMask from 'react-input-mask';

import Header from '~/components/Header';
import CarrosselDesktop from '~/components/Carrossel/CarrosselCompany';
import CarrosselMobile from '~/components/Carrossel/CarrosselMobileCompany';
import InfoConnectClients from '~/components/InfosConnectClient';
import Footer from '~/components/FooterCompany';
import api from '~/services/api';

import Automacao from '~/assets/automação.png';
import BeneficiosDois from '~/assets/beneficiosdois.png';
import BeneficiosQuatro from '~/assets/beneficiosquatro.png';
import BeneficiosTres from '~/assets/beneficiostres.png';
import BeneficiosUm from '~/assets/beneficiosum.png';
import Gestao from '~/assets/gestão.png';
import Operacoes from '~/assets/operações.png';
import Pagamento from '~/assets/pagamento.png';
import Parkinglot from '~/assets/parkingloticon.png';
import Plataforma from '~/assets/plataforma.png';
import PrintScreenManobra from '~/assets/manobristaframe.png';
import Ticket from '~/assets/ticket.png';

import * as S from './styles';

const ForYourCompany = () => {
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [companyName, setCompanyName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [cpf, setCpf] = useState('');
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [msgDialog, setMsgDialog] = useState('');
  const [titleDialog, setTitleDialog] = useState('');
  const [radioValue, setRadioValue] = useState(1);

  let disabled = true;

  if (
    companyName !== '' &&
    name !== '' &&
    email !== '' &&
    phone !== '' &&
    cpf !== '' &&
    address !== ''
  ) {
    disabled = false;
  }

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  const onChangeValue = (event) => {
    setRadioValue(Number(event.target.value));
  };

  async function handleSubmit() {
    setLoading(true);
    setShowDialog(false);
    setMsgDialog(null);

    try {
      const response = await api.post('/sendContactMail', {
        companyName,
        name,
        email,
        phone,
        cpf,
        address,
        radioValue,
      });

      if (response.data.message === 'success') {
        toast.success('Mensagem enviada com sucesso!');
        setCompanyName('');
        setName('');
        setEmail('');
        setPhone('');
        setCpf('');
        setAddress('');

        setLoading(false);
        setTitleDialog('Obrigado pelo contato!');
        setMsgDialog(
          'Fique de olho em seu e-mail ou telefone, nosso representante irá te contactar!'
        );
        setShowDialog(true);
      }
    } catch (err) {
      console.log(err.response);
      setLoading(false);
      setTitleDialog('Erro ao enviar contato');
      setMsgDialog(err.response.data.error);
      setShowDialog(true);
    }
  }

  return (
    <>
      {/* header menu */}
      <Header
        type="forYourCompany"
        active="forYourCompany"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />

      {isSmallScreen ? (
        <>
          {/* carrossel start */}
          <CarrosselMobile />
          <S.TitleCarrosselMobile>
            Plataforma Mobile Líder para Gestão de Estacionamentos e Valets
          </S.TitleCarrosselMobile>
          {/* carrossel end */}

          {/* infos conect clients start */}
          <InfoConnectClients />
          {/* infos conect clients end */}

          {/* benefits start */}
          <S.DivBenefits>
            <S.Header>BENEFÍCIOS</S.Header>
            <S.SubHeader>
              AUMENTE SEU FATURAMENTO E MELHORE A GESTÃO DO SEU NEGÓCIO
            </S.SubHeader>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
              spacing={12}
              sm={12}
              md={12}
              lg={12}
              xs={12}
            >
              <Grid item>
                <p>
                  Todos os meios
                  <br /> de pagamento
                </p>
                <img width={125} src={BeneficiosDois} alt="Benefícios Cliente Valeti" />
                <S.IconDescriptionTwo>
                  Valeti Pag, Conectcar, SemParar, Veloe.
                </S.IconDescriptionTwo>
              </Grid>
              <br />

              <Grid item>
                <p>
                  Mensalista, Credenciado
                  <br /> e Selo Eletrônico
                </p>
                <img width={125} src={BeneficiosTres} alt="Benefícios Cliente Valeti" />
                <S.IconDescriptionThree>
                  Mensalista no app recorrente, venda mais vagas mensais.
                </S.IconDescriptionThree>
              </Grid>
              <br />

              <Grid item>
                <p>Dashboard avançado</p>
                <img width={125} src={BeneficiosQuatro} alt="Benefícios Cliente Valeti" />
                <S.IconDescriptionFour>
                  Aumente sua receita, reduza fraude e custos.
                </S.IconDescriptionFour>
              </Grid>
            </Grid>

            <S.DivContrateBeneficios>
              <Link to="/ContrateUmPlano">
                <S.ButtonContrate>CONTRATE AGORA</S.ButtonContrate>
              </Link>
            </S.DivContrateBeneficios>
          </S.DivBenefits>
          {/* benefits end */}

          {/* printscreen start */}
          <S.ImgPrintScreen
            src={PrintScreenManobra}
            alt="Print Screen App Manobrista"
          />
          {/* printscreen end */}

          {/* about start */}
          <S.DivAbout>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={12}
              sm={1}
              md={12}
              lg={12}
              xs={12}
            >
              <Grid item sm md lg xs>
                <S.HeaderAbout>Sobre nosso App</S.HeaderAbout>
                <S.SubheaderAbout>
                  Valeti é uma plataforma que conecta motoristas,
                  estacionamentos e estabelecimentos, oferecendo uma forma
                  segura e cômoda de estacionar, pagar e pedir seu veículo em
                  Valets e Estacionamentos que contam com uma plataforma segura
                  e ágil para sua operação.{' '}
                </S.SubheaderAbout>
                <S.ImgParking src={Parkinglot} alt="Estacione" />
              </Grid>

              <Grid item sm md lg xs>
                <img src={Pagamento} alt="about" />
                <br /> <br />
                <S.DescriptionAbout>
                  Você vai facilitar o gerenciamento do seu Valet ou
                  Estacionamento e agilizar a vida do seu cliente. Ela poderá
                  realizar o <br /> pagamento via cartão de crédito, débito ou
                  ConectCar, SemParar e Veloe, é pratico para o cliente e cômodo
                  para o Estacionamento.
                </S.DescriptionAbout>
                <br /> <br />
                <img src={Automacao} alt="about" />
                <br /> <br />
                <S.DescriptionAbout>
                  Sistema portátil com entrega do ticket ao cliente em no
                  momento e local do desembarque. Sistema com controle de pátio,
                  cadastro <br />
                  completo de mensalistas, credenciados, selos e convênios,
                  evitando fraude ou falta de pagamento. Pagamento integrado no
                  App ou no POS da Getnet, Rede e <br />
                  Cielo. Sistema emite RPS / NFS-E com porsterior geração
                  automática de arquivo e envio para Prefeitura de seu
                  município.
                </S.DescriptionAbout>
                <br /> <br />
                <img src={Gestao} alt="about" />
                <br /> <br />
                <S.DescriptionAbout>
                  Gerencie seu negócio usando um Celular Android, Tablet ou
                  Computador. Mantenha todos registros de placas e clientes na
                  sua base de dados, quando eles voltarem, todos os dados são
                  automaticamente preenchidos. Consulte pela placa no Painel de
                  Controle do Clientes todas entradas e estadias de seus
                  cliente.
                </S.DescriptionAbout>
              </Grid>

              <Grid item sm md lg xs>
                <br /> <br />
                <img src={Plataforma} alt="about" />
                <br /> <br />
                <S.DescriptionAbout>
                  No portal do cliente, você customiza e configura todas as
                  opções do seu estacionamento ou Valet, além claro de contar
                  com Dashboards, Relatórios e todos cadastros e configurações a
                  sua mão.
                </S.DescriptionAbout>
                <br /> <br />
                <img src={Ticket} alt="about" />
                <br />
                <br />
                <S.DescriptionAbout>
                  Reduza as fraudes, cada veículo um novo ticket, com o Ticket
                  Eletrônica o cliente recebe o Ticket no Valeti App, via SMS ou
                  Email. Clientes que utilizam ticket eletrônico impossibilitam
                  qualquer fraude. Pagamento via App ou no POS DIGITAL
                  automatiza a baixa do Ticket, sem chance de erros. Além disso
                  reduz o risco de fraude ou perdas com danos ou furto do
                  veículo.
                </S.DescriptionAbout>
                <br /> <br />
                <img src={Operacoes} alt="about" />
                <br /> <br /> <br />
                <S.DescriptionAbout>
                  Para quem possui varios pontos, acompanhe todos seus
                  Estacionamentos e Valets online em tempo real do seu
                  smartophone ou PC via web no portal do cliente.
                </S.DescriptionAbout>
                <br />
                <Link to="/ContrateUmPlano">
                  <S.ButtonAbout> CONTRATAR</S.ButtonAbout>
                </Link>
              </Grid>
            </Grid>
          </S.DivAbout>
          {/* about end */}

          {/* form contact start */}
          <S.DivFormContact>
            <S.FormContact>
              <S.HeaderForm>Alguma dúvida? Entre em contato</S.HeaderForm>
              <S.SubTitleForm1>
                Um dos nossos representantes já entrará em contato com você!
              </S.SubTitleForm1>
              <br />

              <S.SubTitleForm1>
                Selecione como deseja utilizar o sistema
              </S.SubTitleForm1>
              <div onChange={onChangeValue}>
                <input
                  type="radio"
                  id="marca"
                  name="marca"
                  value={1}
                  checked={radioValue === 1}
                />
                <label for="getnet">&nbsp;POS Getnet&nbsp;&nbsp;&nbsp;</label>

                <input
                  type="radio"
                  id="marca"
                  name="marca"
                  value={2}
                  checked={radioValue === 2}
                />
                <label for="rede">&nbsp;POS Rede&nbsp;&nbsp;&nbsp;</label>

                <input
                  type="radio"
                  id="marca"
                  name="marca"
                  value={3}
                  checked={radioValue === 3}
                />
                <label for="cielo">&nbsp;POS Cielo&nbsp;&nbsp;&nbsp;</label>

                <input
                  type="radio"
                  id="marca"
                  name="marca"
                  value={4}
                  checked={radioValue === 4}
                />
                <label for="smartphone">&nbsp;Smartphone Próprio</label>
              </div>
              <br />
              <br />

              <S.Input
                required
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
                placeholder="Digite o Nome do Estabelecimento"
              />
              <br />
              <br />

              <S.Input
                required
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder="Digite seu Nome"
              />
              <br />
              <br />

              <S.Input
                required
                value={email}
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Digite seu e-mail (profissional)"
              />
              <br />
              <br />

              <InputMask
                mask="(99) 9 9999-9999"
                maskChar=""
                onChange={(event) => setPhone(event.target.value)}
                value={phone}
                name="phone"
                required
              >
                {() => (
                  <S.Input
                    required
                    placeholder="Digite seu telefone (profissional)"
                    name="phone"
                  />
                )}
              </InputMask>
              <br />
              <br />

              <InputMask
                mask="999.999.999-99"
                maskChar=""
                onChange={(event) => setCpf(event.target.value)}
                value={cpf}
                name="cpf"
                required
              >
                {() => (
                  <S.Input required placeholder="Digite seu CPF" name="cpf" />
                )}
              </InputMask>
              <br />
              <br />

              <S.Input
                required
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                placeholder="Digite o Endereço"
              />
              <br />
              <br />
              {loading ? (
                <ReactLoading
                  type="spin"
                  color="#FF4D00"
                  height={40}
                  width={40}
                />
              ) : (
                <S.ButtonSubmit onClick={handleSubmit} disabled={disabled}>
                  ENVIAR
                </S.ButtonSubmit>
              )}
            </S.FormContact>
          </S.DivFormContact>
          {/* form contact end */}
        </>
      ) : (
        <>
          {/* carrossel start */}
          <CarrosselDesktop />
          <S.TitleCarrossel>Bem-vindo à Valeti</S.TitleCarrossel>
          <S.SubtitleCarrossel>
            Plataforma Mobile Líder para Gestão de Estacionamentos e Valets
          </S.SubtitleCarrossel>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://valeti.com/trial"
          >
            <S.ButtonCarrosselOne variant="contained">
              TESTE 30 DIAS GRÁTIS
            </S.ButtonCarrosselOne>
          </a>
          <Link to="/ContrateUmPlano">
            <S.ButtonCarrosselTwo variant="contained">
              CONTRATE AGORA
            </S.ButtonCarrosselTwo>
          </Link>
          {/* carrossel end */}

          {/* infos conect clients start */}
          <InfoConnectClients />
          {/* infos conect clients end */}

          {/* benefits start */}
          <S.DivBenefits>
            <S.Header>BENEFÍCIOS</S.Header>
            <S.SubHeader>
              AUMENTE SEU FATURAMENTO E MELHORE A GESTÃO DO SEU NEGÓCIO
            </S.SubHeader>

            <Grid container direction="row">
              <Grid item xs sm md>
                <S.IconDescriptionOne>
                  <h2>Gestão Mobile Online</h2>
                  <br />
                  <S.BenefitsIcon2
                    src={BeneficiosUm}
                    alt="Benefícios Cliente Valeti"
                  />
                  <br />
                  <h3>Smartphone Android e POS Rede, Getnet ou Cielo.</h3>
                </S.IconDescriptionOne>
              </Grid>

              <Grid item>
                <S.IconDescriptionOne>
                  <h2>Todos os meios de pagamento</h2>
                  <br />
                  <S.BenefitsIcon
                    src={BeneficiosDois}
                    alt="Benefícios Cliente Valeti"
                  />
                  <br />
                  <h4>Valeti Pag, Conectcar, SemParar, Veloe.</h4>
                </S.IconDescriptionOne>
              </Grid>

              <Grid item xs sm md>
                <S.IconDescriptionOne>
                  <h2>Mensalista, Credenciado e Selo Eletrônico</h2>
                  <br />
                  <S.BenefitsIcon2
                    src={BeneficiosTres}
                    alt="Benefícios Cliente Valeti"
                  />
                  <br />
                  <h5>
                    Mensalista no app recorrente, venda mais vagas mensais.
                  </h5>
                </S.IconDescriptionOne>
              </Grid>

              <Grid item xs sm md>
                <S.IconDescriptionOne>
                  <h2>Dashboard avançado</h2>
                  <br />
                  <S.BenefitsIcon2
                    src={BeneficiosQuatro}
                    alt="Benefícios Cliente Valeti"
                  />
                  <br />
                  <h6>Aumente sua receita, reduza fraude e custos.</h6>
                </S.IconDescriptionOne>
              </Grid>
            </Grid>

            <S.DivContrateBeneficios>
              <Link to="/ContrateUmPlano">
                <S.ButtonContrate>CONTRATE AGORA</S.ButtonContrate>
              </Link>
            </S.DivContrateBeneficios>
          </S.DivBenefits>
          {/* benefits end */}

          {/* printscreen start */}
          <S.ImgPrintScreen
            src={PrintScreenManobra}
            alt="Print Screen App Manobrista"
          />
          {/* printscreen end */}

          {/* about start */}
          <S.DivAbout>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={10}
            >
              <Grid item xs>
                <S.HeaderAbout>Sobre nosso App</S.HeaderAbout>
                <S.SubheaderAbout>
                  Valeti é uma plataforma que conecta motoristas,
                  estacionamentos e estabelecimentos, oferecendo uma forma
                  segura e cômoda de estacionar, pagar e pedir seu veículo em
                  Valets e Estacionamentos que contam com uma plataforma segura
                  e ágil para sua operação.{' '}
                </S.SubheaderAbout>
                <S.ImgParking src={Parkinglot} alt="Estacione" />
              </Grid>

              <Grid item xs>
                <br /> <br />
                <img src={Pagamento} alt="about" />
                <br /> <br />
                <S.DescriptionAbout>
                  Você vai facilitar o gerenciamento do seu Valet ou
                  Estacionamento e agilizar a vida do seu cliente. Ele poderá
                  realizar o <br /> pagamento via cartão de crédito, débito ou
                  ConectCar, SemParar e Veloe, é pratico para o cliente e cômodo
                  para o Estacionamento.
                </S.DescriptionAbout>
                <br /> <br />
                <img src={Automacao} alt="about" />
                <br /> <br />
                <S.DescriptionAbout>
                  Sistema portátil com entrega do ticket ao cliente no momento e
                  local do desembarque. Sistema com controle de pátio, cadastro{' '}
                  <br />
                  completo de mensalistas, credenciados, selos e convênios,
                  evitando fraude ou falta de pagamento. Pagamento integrado no
                  App ou no POS da Getnet, Rede e <br />
                  Cielo. Sistema emite RPS / NFS-E com posterior geração
                  automática de arquivo e envio para Prefeitura de seu
                  município.
                </S.DescriptionAbout>
                <br /> <br />
                <img src={Gestao} alt="about" />
                <br /> <br />
                <S.DescriptionAbout>
                  Gerencie seu negócio usando um Celular Android, Tablet ou
                  Computador. Mantenha todos registros de placas e clientes na
                  sua base de dados, quando eles voltarem, todos os dados são
                  automaticamente preenchidos. Consulte pela placa no Painel de
                  Controle do Clientes todas entradas e estadias de seus
                  cliente.
                </S.DescriptionAbout>
              </Grid>

              <Grid item xs>
                <br /> <br />
                <br /> <br />
                <br /> <br />
                <S.ImgPlatform src={Plataforma} alt="Plataforma online" />
                <br /> <br />
                <S.DescriptionAboutTwo>
                  No portal do cliente, você customiza e configura todas as
                  opções do seu estacionamento ou Valet, além claro de contar
                  com Dashboards, Relatórios e todos cadastros e configurações a
                  sua mão.
                </S.DescriptionAboutTwo>
                <br /> <br />
                <S.ImgTicket src={Ticket} alt="Ticket eletrônico" />
                <br />
                <br />
                <S.DescriptionEticket>
                  Reduza as fraudes, cada veículo um novo ticket, com o Ticket
                  Eletrônico o cliente recebe o Ticket no Valeti App, via SMS ou
                  Email. Clientes que utilizam ticket eletrônico impossibilitam
                  qualquer fraude. Pagamento via App ou no POS DIGITAL
                  automatiza a baixa do Ticket, sem chance de erros. Além disso
                  reduz o risco de fraude ou perdas com danos ou furto do
                  veículo.
                </S.DescriptionEticket>
                <br /> <br />
                <S.ImgTicketTwo src={Operacoes} alt="about" />
                <br /> <br /> <br />
                <S.DescriptionTwoTimes>
                  Para quem possui vários pontos, acompanhe todos seus
                  Estacionamentos e Valets online em tempo real do seu
                  smartphone ou PC via web no portal do cliente.
                </S.DescriptionTwoTimes>
                <br />
                <Link to="/ContrateUmPlano">
                  <S.ButtonAbout> CONTRATAR</S.ButtonAbout>
                </Link>
              </Grid>
            </Grid>
          </S.DivAbout>
          {/* about end */}

          {/* form contact start */}
          <S.DivFormContact>
            <S.FormContact>
              <S.HeaderForm>Alguma dúvida? Entre em contato</S.HeaderForm>
              <S.SubTitleForm1>
                Um dos nossos representantes já entrará em contato com você!
              </S.SubTitleForm1>
              <br />

              <S.SubTitleForm1>
                Selecione como deseja utilizar o sistema
              </S.SubTitleForm1>
              <div onChange={onChangeValue}>
                <input
                  type="radio"
                  id="marca"
                  name="marca"
                  value={1}
                  checked={radioValue === 1}
                />
                <label for="getnet">&nbsp;POS Getnet&nbsp;&nbsp;&nbsp;</label>

                <input
                  type="radio"
                  id="marca"
                  name="marca"
                  value={2}
                  checked={radioValue === 2}
                />
                <label for="rede">&nbsp;POS Rede&nbsp;&nbsp;&nbsp;</label>

                <input
                  type="radio"
                  id="marca"
                  name="marca"
                  value={3}
                  checked={radioValue === 3}
                />
                <label for="cielo">&nbsp;POS Cielo&nbsp;&nbsp;&nbsp;</label>

                <input
                  type="radio"
                  id="marca"
                  name="marca"
                  value={4}
                  checked={radioValue === 4}
                />
                <label for="smartphone">&nbsp;Smartphone Próprio</label>
              </div>
              <br />
              <br />

              <S.Input
                required
                value={companyName}
                onChange={(event) => setCompanyName(event.target.value)}
                placeholder="Digite o Nome do Estabelecimento"
              />
              <br />
              <br />

              <S.Input
                required
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder="Digite seu Nome"
              />
              <br />
              <br />

              <S.Input
                required
                value={email}
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Digite seu e-mail (profissional)"
              />
              <br />
              <br />

              <InputMask
                mask="(99) 9 9999-9999"
                maskChar=""
                onChange={(event) => setPhone(event.target.value)}
                value={phone}
                name="phone"
                required
              >
                {() => (
                  <S.Input
                    required
                    placeholder="Digite seu telefone (profissional)"
                    name="phone"
                  />
                )}
              </InputMask>
              <br />
              <br />

              <InputMask
                mask="999.999.999-99"
                maskChar=""
                onChange={(event) => setCpf(event.target.value)}
                value={cpf}
                name="cpf"
                required
              >
                {() => (
                  <S.Input required placeholder="Digite seu CPF" name="cpf" />
                )}
              </InputMask>
              <br />
              <br />

              <S.Input
                required
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                placeholder="Digite o Endereço"
              />
              <br />
              <br />
              {loading ? (
                <ReactLoading
                  type="spin"
                  color="#FF4D00"
                  height={40}
                  width={40}
                />
              ) : (
                <S.ButtonSubmit onClick={handleSubmit} disabled={disabled}>
                  ENVIAR
                </S.ButtonSubmit>
              )}
            </S.FormContact>
          </S.DivFormContact>
          {/* form contact end */}

          {/* footer start */}
          <Footer isSmallScreen={isSmallScreen} />
          {/* footer end */}

          {/* dialog to show message after form */}
          <Dialog
            open={showDialog}
            onClose={() => setShowDialog(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{titleDialog}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {msgDialog}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowDialog(false)}
                color="primary"
                autoFocus
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default ForYourCompany;
