import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  error: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@auth/SIGN_IN_REQUEST':
      case '@auth/SIGN_IN_FACEBOOK_REQUEST':
      case '@auth/SIGN_UP_REQUEST':
      case '@auth/UPDATE_USER_PHONE_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/SET_TOKEN_SUCCESS': {
        draft.token = action.payload.token;
        break;
      }
      case '@auth/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_IN_FAILURE': {
        draft.error = action.payload.error || 'Erro inesperado';
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_IN_COMPLETE': {
        draft.signed = true;
        break;
      }
      case '@auth/SIGN_OUT': {
        draft.token = null;
        draft.signed = false;
        break;
      }
      case '@auth/CLEAN_ERRORS': {
        draft.error = null;
        break;
      }
      default:
    }
  });
}
