import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import FacebookLogin from 'react-facebook-login';
import { Link } from 'react-router-dom';
import ReactLoading from 'react-loading';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import BackButton from '~/components/BackButton';
import Footer from '~/components/Footer';
import Header from '~/components/Header';
import OrangeBanner from '~/components/OrangeBanner';
import OrangeInput from '~/components/OrangeInput';

import { apiLogin } from '~/services/api';
import history from '~/services/history';
import { signInRequest } from '~/store/modules/auth/actions';

import Android from '~/assets/Android.png';
import Apple from '~/assets/Apple.png';
import ValetiApp from '~/assets/icon.png';

import {
  DivMobile,
  DivLogin,
  SubHeaderAlreadyExist,
  HeaderAlreadyExist,
  DivCard,
  DivCardTwo,
  ButtonForgotPwd,
  TextButtonForgotPwd,
  ButtonLogin,
  ButtonSignUp,
} from './styles';
import './styles.css';

const useStyles = makeStyles(() => ({
  textField: {
    marginLeft: '3.5%',
    width: '91%',
  },
  textField2: {
    marginLeft: '3.5%',
    width: '88%',
  },
}));

const LoginPage = () => {
  const [platform, setPlatform] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const iconRevealPwd = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    setPlatform(navigator.platform);
    const mediaQuery = window.matchMedia('(max-width: 750px)');
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  async function handleSubmit() {
    setSubmitLoading(true);
    try {
      const response = await apiLogin.post('/driver/website/login', {
        email,
        password,
      });

      dispatch(signInRequest(response.data.token, response.data.user));
      history.push('/ReservaDeVaga/SelecionarCarro');
      setSubmitLoading(false);
    } catch (err) {
      setError(err.response.data.error);
      setShowError(true);
      setSubmitLoading(false);
    }
  }

  /**
   * Request a password reset.
   */
  async function resetPassword() {
    if (email !== '') {
      try {
        await apiLogin.post('/driver/resetPasswordRequest', {
          email,
        });
        setShowSuccess(true);
        setSubmitLoading(false);
      } catch (err) {
        setError(err.response.data.error);
        setShowError(true);
        setSubmitLoading(false);
      }
    } else {
      setError('Preencha o e-mail cadastrado');
      setShowError(true);
    }
  }

  const handleSubmitFacebook = async (response) => {
    try {
      await apiLogin.post('/driver/website/loginFacebook', {
        token: response.accessToken.toString(),
        name: response.name,
        email: response.email,
      });

      history.push('/ReservaDeVaga/SelecionarCarro');
      setSubmitLoading(false);
    } catch (err) {
      setError(
        'Erro ao logar via Facebook. Tente novamente dentro de instantes!'
      );
      setShowError(true);
      setSubmitLoading(false);
    }
  };

  let disabled = true;

  if (email !== '' && password !== '') {
    disabled = false;
  }

  const classes = useStyles();

  return (
    <>
      {/* header menu */}
      <Header
        type="forYou"
        active="forYou"
        isSmallScreen={isSmallScreen}
        platform={platform}
      />

      {isSmallScreen ? (
        <>
          <DivMobile>
            <h1>
              Para reservar vaga, seja rápida ou agendada, baixe agora o app
              Valeti!
            </h1>
            <a
              href="https://play.google.com/store/apps/details?id=com.valetiapp.driver&hl=pt_BR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Android} alt="Android" />
            </a>
            <br />
            <a
              href="https://apps.apple.com/br/app/valeti/id1069081218"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Apple} alt="iOS" />
            </a>
            <br />
          </DivMobile>
        </>
      ) : (
        <>
          {/* orange title start */}
          <OrangeBanner title="FAÇA SEU LOGIN OU CADASTRE-SE" />
          {/* orange title end */}

          <DivLogin>
            <BackButton link="/ReservaDeVaga" />

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={12}
            >
              <Grid item xs={6}>
                <DivCard>
                  <HeaderAlreadyExist>
                    {' '}
                    Já tenho cadastro
                    <img
                      src={ValetiApp}
                      alt="Valeti App"
                      width={45}
                      height={45}
                      style={{ marginLeft: 5 }}
                    />{' '}
                  </HeaderAlreadyExist>

                  <SubHeaderAlreadyExist>
                    {' '}
                    Já se cadastrou no App Valeti ou pelo portal web? Insira
                    seus dados de acesso abaixo:{' '}
                  </SubHeaderAlreadyExist>

                  <OrangeInput
                    required
                    value={email}
                    type="email"
                    onChange={(event) => setEmail(event.target.value)}
                    label="Insira seu e-mail"
                    variant="outlined"
                    fullWidth={true}
                    className={classes.textField}
                    isSecureTextEntry={true}
                  />
                  <br />
                  <br />
                  <OrangeInput
                    required
                    value={password}
                    type={isRevealPwd ? 'text' : 'password'}
                    onChange={(event) => setPassword(event.target.value)}
                    label="Digite sua senha"
                    variant="outlined"
                    className={classes.textField2}
                  />
                  <span
                    onClick={() => setIsRevealPwd(!isRevealPwd)}
                    ref={iconRevealPwd}
                  >
                    {isRevealPwd ? (
                      <VisibilityIcon className="customIcon" />
                    ) : (
                      <VisibilityOffIcon className="customIcon" />
                    )}
                  </span>
                  <ButtonForgotPwd onClick={resetPassword}>
                    <TextButtonForgotPwd>
                      Esqueci minha senha
                    </TextButtonForgotPwd>
                  </ButtonForgotPwd>

                  {submitLoading ? (
                    <ReactLoading
                      type="spin"
                      color="#ff4b00"
                      height={40}
                      width={40}
                    />
                  ) : (
                    <ButtonLogin onClick={handleSubmit} disabled={disabled}>
                      FAZER LOGIN
                    </ButtonLogin>
                  )}
                  <FacebookLogin
                    //appId="168363300458563"
                    appId="2912511775512847"
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="public_profile,email"
                    callback={handleSubmitFacebook}
                    className="reservaconfirmButtonLoginFacebook"
                    cssClass="reservaconfirmButtonLoginFacebook"
                    textButton="LOGIN FACEBOOK"
                  />
                </DivCard>
              </Grid>

              <Grid item xs={6}>
                <DivCardTwo>
                  <HeaderAlreadyExist>
                    Ainda não tenho cadastro
                  </HeaderAlreadyExist>
                  <SubHeaderAlreadyExist>
                    {' '}
                    1. Realize suas reservas de forma rápida e simples.
                    <br />
                    <br />
                    2. Ao se cadastrar você receberá dicas de eventos e alerta
                    de horário de vagas.
                    <br />
                    <br />
                    3. Tenha acesso ao app Valeti e reserve suas vagas na palma
                    da sua mão.
                    <br />
                    <br />
                    4. Salve seus dados e tenha acesso as suas reservas e
                    mensalidades de uma forma muito mais prática.
                    <br />
                    <br />{' '}
                  </SubHeaderAlreadyExist>
                  <Link to="/ReservaDeVaga/NovoUsuario">
                    <ButtonSignUp>QUERO ME CADASTRAR</ButtonSignUp>
                  </Link>
                </DivCardTwo>
              </Grid>
            </Grid>
          </DivLogin>
          <Dialog
            open={showError}
            onClose={() => setShowError(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Erro ao realizar login!
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {error}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowError(false)}
                color="primary"
                autoFocus
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={showSuccess}
            onClose={() => setShowError(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Sucesso!</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Dê uma olhada em seu e-mail, e siga as intruções!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setShowSuccess(false)}
                color="primary"
                autoFocus
              >
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          {/* footer start */}
          <Footer />
          {/* footer end */}
        </>
      )}
    </>
  );
};

export default LoginPage;
