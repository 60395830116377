/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Input = styled.input`
  height: 56px;
  position: relative;
  left: 50%;
  width: 450px;
  background-color: #fff;
  border-radius: 5px;
  border-color: #ff4b00;
  border: 1px;
  z-index: 0;
  color: #ff4b00;
  text-align: center;

  ::-webkit-input-placeholder {
    text-align: center;
  }

  :-moz-placeholder {
    /* Firefox 18- */
    text-align: center;
  }

  ::-moz-placeholder {
    /* Firefox 19+ */
    text-align: center;
  }

  :-ms-input-placeholder {
    text-align: center;
  }
`;

export const Button = styled.button`
  position: relative;
  left: 51%;
  width: 8%;
  height: 56px;
  background-color: #ff4b00;
  color: #ffffff;
  border-radius: 10px;
  border: none;

  :hover {
    color: #ff4b00;
    background-color: #ffffff;
  }
`;
