import styled from 'styled-components';

export const DivResumeReservation = styled.div`
  width: 20%;
  background-color: #222222;
  float: right;
  height: 100%;

  @media only screen and (max-width: 1520px) {
    width: 30%;
    background-color: #222222;
    float: right;
    height: 100%;
  }
`;

export const Header = styled.h2`
  position: relative;
  margin-top: 10%;
  margin-left: 6%;
  font-weight: 600;
  color: #fff;
  font-size: 28px;
`;

export const Description = styled.p`
  margin-top: 3%;
  margin-left: 6%;
  font-size: 18px;
  color: #fff;
`;

export const BiggerDescription = styled.p`
  margin-left: 6%;
  font-size: 12px;
  color: #fff;
`;
